/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { put, call, all, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit';
import { snakeCase } from 'lodash';
import * as api from './api';
import CompanyRelationship, {
  COMPANY_RELATIONSHIP_TYPES,
} from '~models/CompanyRelationship';
import { RootState } from '~store/rootReducer';

const subsidiariesAdapter = createEntityAdapter<CompanyRelationship>();

const initialState = subsidiariesAdapter.getInitialState({
  search: {
    data: [],
    meta: {},
  },
  loading: {},
  error: {},
});

const slice = createSlice({
  name: 'subsidiaries',
  initialState,
  reducers: {
    searchSubsidiaries(
      state,
      action: PayloadAction<{
        companyId: string;
        params?: { page: string | number };
      }>
    ) {},

    receiveSearchSubsidiaries(state, action: PayloadAction<any>) {
      state.search = {
        data: action.payload.data.map(i => new CompanyRelationship(i)),
        meta: action.payload.meta,
      };
      subsidiariesAdapter.setAll(
        state,
        action.payload.data
          .map(i => new CompanyRelationship(i))
          .filter(s => s.daughterCompany.id !== action.payload.companyId)
      );
    },

    create(
      state,
      action: PayloadAction<{
        companyId: string;
        subsidiaryRegistrationNumber?: string;
        subsidiaryId?: string;
        relationshipType: number;
      }>
    ) {},

    receiveCreate(state, action: PayloadAction<any>) {
      subsidiariesAdapter.addOne(
        state,
        new CompanyRelationship(action.payload)
      );
    },

    destroy(
      state,
      action: PayloadAction<{ companyId: string; relationshipId: string }>
    ) {},

    receiveDestroy(state, action) {
      subsidiariesAdapter.removeOne(state, action.payload.id);
    },

    loading(state, action) {
      const type = action.payload;
      state.loading[type] = true;
      state.error[type] = null;
    },

    success(state, action) {
      const type = action.payload;
      state.loading[type] = false;
      state.error[type] = null;
    },

    error(state, action) {
      const { type, error } = action.payload;
      state.loading[type] = false;
      state.error[type] = error;
    },
  },
});

export const { actions } = slice;
export default slice.reducer;

const subsidiariesSelectors = subsidiariesAdapter.getSelectors(
  (state: RootState) => state.subsidiaries
);

export const selectors = {
  subsidiaries: (state, companyId) => subsidiariesSelectors.selectAll(state),
  subsidiary: (state, subsidiaryId) =>
    subsidiariesSelectors.selectById(state, subsidiaryId),
  search: state => state.subsidiaries.search,
  loading: (state: RootState, key?) =>
    key
      ? !!state.subsidiaries.loading[key]
      : Object.values(state.subsidiaries.loading).some(v => Boolean(v)),
  error: (state, key?) =>
    key ? state.subsidiaries.error[key] || null : state.subsidiaries.error,
};

export function* searchSubsidiaries(
  action: ReturnType<typeof actions.searchSubsidiaries>
) {
  const { type, payload } = action;
  const { companyId, params } = payload;

  try {
    yield put(actions.loading(type));

    const response = yield call(api.relationshipsSearch, companyId, params);
    const newResponse = { ...response, companyId: companyId };
    yield put(actions.receiveSearchSubsidiaries(newResponse));

    yield put(actions.success(type));
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* create(action: ReturnType<typeof actions.create>) {
  const { type, payload } = action;

  const {
    companyId,
    subsidiaryRegistrationNumber,
    subsidiaryId,
    relationshipType,
  } = payload;

  const data = {
    company_relationship_type_id: relationshipType,
    daughter_company_registration_number: subsidiaryRegistrationNumber,
    ...(subsidiaryId !== undefined && {
      daughter_company_id: subsidiaryId,
    }),
  };

  try {
    yield put(actions.loading(type));

    const response = yield call(api.createRelationship, { companyId, data });
    yield put(actions.receiveCreate(response.data));

    yield put(actions.success(type));
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* destroy(action: ReturnType<typeof actions.destroy>) {
  const { type, payload } = action;

  try {
    yield put(actions.loading(type));

    const response = yield call(api.destroyRelationship, payload);
    yield put(actions.destroy(response.data));
    yield put(actions.success(type));
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* subsidiariesSaga() {
  yield all([
    yield takeLatest(actions.searchSubsidiaries.type, searchSubsidiaries),
    yield takeEvery(actions.create.type, create),
    yield takeEvery(actions.destroy.type, destroy),
  ]);
}
