/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defineMessages } from 'react-intl';
import qs from 'query-string';

import * as api from './api';
import { COMMON } from '../common';

import { actions as toastActions, Toast, ToastType } from '../toaster';
import { RootState } from '../index';
import { APIError } from '../api';
import Invite, { InviteToAccept } from '~models/Invite';

const messages = defineMessages({
  inviteSuccess: {
    id: 'invite-success-toast',
    defaultMessage: 'Kutsu lähetetty!',
  },
  inviteFailed: {
    id: 'invite-accepted-toast',
    defaultMessage: 'Kutsua ei voitu hyväksyä!',
  },
  inviteRemoved: {
    id: 'invite-remove-toast',
    defaultMessage: 'Kutsu peruutettu.',
  },
});

interface State {
  invites: { [id: string]: Invite };
  inviteToAccept?: InviteToAccept;
  search: { data: Invite[]; meta: any };
  loading: { [type: string]: boolean };
  error: { [type: string]: APIError | null };
}

const actionCompleted = (
  actions,
  action: PayloadAction<any>,
  actionType: string
) =>
  (action.type === actions.success.type && action.payload === actionType) ||
  (action.type === actions.error.type && action.payload?.type === actionType);

const slice = createSlice({
  name: 'invites',
  initialState: {
    invites: {},
    inviteToAccept: undefined,
    search: {
      data: [],
      meta: {},
    },
    loading: {},
    error: {},
  } as State,
  reducers: {
    receive(state, action: PayloadAction<Invite[]>) {
      action.payload.forEach(i => {
        state.invites[i.id] = new Invite(i);
      });
    },

    search(
      state,
      action: PayloadAction<{ companyId: string; params?: any }>
    ) {},

    receiveSearch(state, action: PayloadAction<any>) {
      state.search = {
        data: action.payload.data.map(i => new Invite(i)),
        meta: action.payload.meta,
      };
    },

    storeInvite(state, action: PayloadAction<InviteToAccept | undefined>) {
      state.inviteToAccept = action.payload;
    },

    removeStoredInvite(state) {
      state.inviteToAccept = undefined;
    },

    setStoredInviteValidity(
      state,
      action: PayloadAction<{
        success: boolean;
        companyName?: string;
        newUser?: boolean;
        email?: string;
      }>
    ) {
      const invite: InviteToAccept | undefined = state.inviteToAccept;
      if (invite) {
        invite.validated = true;
        invite.is_valid = action.payload.success;
        invite.company_name = action.payload.companyName;
        invite.new_user = action.payload.newUser;
        invite.email = action.payload.email;
      }
    },

    markStoredInviteAsAccepted(state) {
      const invite: InviteToAccept | undefined = state.inviteToAccept;
      if (invite) {
        invite.accepted = true;
        invite.is_valid = false;
      }
    },

    loadInvite(state, action: PayloadAction<string>) {},

    cancel(state, action: PayloadAction<Invite>) {},

    accept(
      state,
      action: PayloadAction<{
        invite: InviteToAccept;
      }>
    ) {},

    routeInvite(
      state,
      action: PayloadAction<{
        invite: InviteToAccept;
        push: any;
      }>
    ) {},

    validateStoredInvite(state, action: PayloadAction) {},

    remove(
      state,
      action: PayloadAction<{
        company_id: string;
        token: string;
      }>
    ) {},

    removeInvite(state, action: PayloadAction<string>) {
      const invites = [...state.search.data];
      invites.splice(
        invites.findIndex(i => i.token === action.payload),
        1
      );
      state.search.data = invites;
    },

    loading(state, action: PayloadAction<string>) {
      const type = action.payload;
      state.loading[type] = true;
      state.error[type] = null;
    },

    success(state, action: PayloadAction<string>) {
      const type = action.payload;
      state.loading[type] = false;
      state.error[type] = null;
    },

    error(state, action: PayloadAction<{ type: string; error: APIError }>) {
      const { type, error } = action.payload;
      state.loading[type] = false;
      state.error[type] = error;
    },

    clearError(state, action: PayloadAction<string>) {
      const type = action.payload;
      state.error[type] = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(COMMON.NAVIGATE, state => {
      Object.keys(state.error).forEach(k => {
        state.error[k] = null;
      });
    })
  },
});

export const { actions } = slice;
export default slice.reducer;

export const selectors = {
  invites: (state: RootState) => Object.values(state.invites.invites),
  inviteToAccept: (state: RootState) => state.invites.inviteToAccept,
  search: (state: RootState) => state.invites.search,
  loading: (state: RootState, key?) =>
    key ? !!state.invites.loading[key] : state.invites.loading,
  error: (state: RootState, key?) =>
    key ? state.invites.error[key] || null : state.invites.error,
};

export function* loadInvite(action: ReturnType<typeof actions.loadInvite>) {
  try {
    const query = qs.parse(action.payload);
    const invite: InviteToAccept = query as unknown as InviteToAccept;

    if (invite.company_id && invite.token) {
      yield put(actions.storeInvite(invite));
    }

    yield put(actions.validateStoredInvite());
    yield take(action =>
      actionCompleted(actions, action, actions.validateStoredInvite.type)
    );

    yield put(actions.success(action.type));
  } catch (error) {
    yield put(actions.error({ type: action.type, error }));
  }
}

export function* search(action: ReturnType<typeof actions.search>) {
  const { type, payload } = action;
  const { companyId, params } = payload;

  try {
    yield put(actions.loading(type));

    const response = yield call(api.search, companyId, params);
    yield put(actions.receiveSearch(response));

    yield put(actions.success(type));
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* routeInvite(action: ReturnType<typeof actions.routeInvite>) {
  const { type, payload } = action;
  const { push } = payload;

  try {
    yield put(actions.loading(type));
    if (payload.invite.validated && !payload.invite.is_valid) {
      throw new Error('invite not valid to accept');
    }

    const invite: InviteToAccept | undefined = yield select(state =>
      selectors.inviteToAccept(state)
    );

    const nextUrl = invite?.new_user ? '/auth/register' : '/auth/login';

    yield put(
      push(`${nextUrl}?company_id=${invite?.company_id}&token=${invite?.token}`)
    );
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* accept(action: ReturnType<typeof actions.accept>) {
  const { type, payload } = action;

  try {
    yield put(actions.loading(type));
    if (payload.invite.validated && !payload.invite.is_valid) {
      throw new Error('invite not valid to accept');
    }

    yield call(api.accept, payload.invite.company_id, payload.invite.token);
    yield put(actions.markStoredInviteAsAccepted());
    yield put(actions.success(type));

    const invite: InviteToAccept | undefined = yield select(state =>
      selectors.inviteToAccept(state)
    );
  } catch (error) {
    yield put(actions.error({ type, error }));

    yield put(
      toastActions.toast(
        new Toast({
          message: messages.inviteFailed,
          type: ToastType.Warning,
        })
      )
    );
  }
}

export function* remove(action: ReturnType<typeof actions.remove>) {
  const { type } = action;

  try {
    yield put(actions.loading(type));
    const { company_id, token } = action.payload;
    yield call(api.remove, company_id, token);

    yield put(actions.removeInvite(token));
    yield put(actions.success(type));
    yield put(
      toastActions.toast(new Toast({ message: messages.inviteRemoved }))
    );
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* validateStoredInvite(
  action: ReturnType<typeof actions.validateStoredInvite>
) {
  const { type, payload } = action;

  yield put(actions.loading(type));

  const invite: InviteToAccept | undefined = yield select(state =>
    selectors.inviteToAccept(state)
  );

  if (invite) {
    try {
      const response = yield call(
        api.validate,
        invite.company_id,
        invite.token
      );
      yield put(
        actions.setStoredInviteValidity({
          success: response.data.is_valid,
          companyName: response.data.company_name,
          newUser: response.data.new_user,
          email: response.data.email,
        })
      );
      yield put(actions.success(type));
    } catch (error) {
      yield put(actions.setStoredInviteValidity({ success: false }));
      yield put(actions.error({ type, error }));
    }
  } else {
    yield put(actions.success(type));
  }
}

export function* inviteSaga() {
  yield all([
    takeLatest(actions.search, search),
    takeLatest(actions.routeInvite, routeInvite),
    takeLatest(actions.accept, accept),
    takeLatest(actions.remove, remove),
    takeLatest(actions.loadInvite, loadInvite),
    takeLatest(actions.validateStoredInvite, validateStoredInvite),
  ]);
}
