/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit';
import { defineMessages } from 'react-intl';

import { RootState } from '../rootReducer';
import Project from '~models/Project';
import { removeNulls } from '~common/helpers';
import { LOCATION_CHECK_RESULT } from '~common/enums';

type FormState = 'listing' | 'showing' | 'creating' | 'editing';
type ValidationErrors = Partial<{ [key in keyof Project]: string[] }>;

interface State {
  formState: FormState;
  showingId: number | null;
  editingId: number | null;
  projectFormData: Partial<Project>;
  locationCheckResult: LOCATION_CHECK_RESULT;
  validationErrors: ValidationErrors | null;
}

const projectAdapter = createEntityAdapter<Project>();

const initialState = projectAdapter.getInitialState({
  formState: 'listing',
  showingId: null,
  editingId: null,
  projectFormData: {},
  locationCheckResult: LOCATION_CHECK_RESULT.NONE,
  validationErrors: null,
} as State);

const messages = defineMessages({
  createProjectSuccess: {
    id: 'projects-create-success-toast',
    defaultMessage: 'Kohde luotu',
  },
  createProjectError: {
    id: 'projects-create-success-toast',
    defaultMessage: 'Kohteen luomisessa tapahtui virhe',
  },
});

const slice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setFormState(
      state,
      action: PayloadAction<
        | { showing: number }
        | { editing: number }
        | Exclude<FormState, 'showing' | 'editing'>
      >
    ) {
      const formState = action.payload;
      state.showingId = null;
      state.editingId = null;

      if (typeof formState === 'string') {
        if (formState === 'listing') {
          state.formState = 'listing';
          state.locationCheckResult = LOCATION_CHECK_RESULT.NONE;
          state.projectFormData = {};
          state.validationErrors = {};
        } else if (formState === 'creating') {
          state.formState = 'creating';
        }
      } else {
        if ('showing' in formState) {
          state.formState = 'showing';
          state.showingId = formState.showing;
        } else if ('editing' in formState) {
          state.formState = 'editing';
          state.editingId = formState.editing;
          state.locationCheckResult = LOCATION_CHECK_RESULT.OK;
        }
      }
    },
    setProjectData(state, action: PayloadAction<Partial<Project>>) {
      state.projectFormData = removeNulls({
        ...state.projectFormData,
        ...action.payload,
      });
    },
    resetLocationCheck(state) {
      state.locationCheckResult = LOCATION_CHECK_RESULT.NONE;
    },

    parseLocationCheckResult(state, action) {
      switch (action.payload) {
        case 'OK':
          state.locationCheckResult = LOCATION_CHECK_RESULT.OK;
          break;
        case 'FAIL':
          state.locationCheckResult = LOCATION_CHECK_RESULT.FAIL;
          break;
        case 'WARNING':
          state.locationCheckResult = LOCATION_CHECK_RESULT.WARNING;
          break;
        default:
          state.locationCheckResult = LOCATION_CHECK_RESULT.NONE;
      }
    },

    setValidationErrors(state, action) {
      state.validationErrors = action.payload;
    },
  },
});

export const { actions } = slice;
export default slice.reducer;

const adapterSelectors = projectAdapter.getSelectors(
  (state: RootState) => state.projects
);

export const selectors = {
  formState: (state: RootState) => state.projects.formState,
  showingId: (state: RootState) => state.projects.showingId,
  editingId: (state: RootState) => state.projects.editingId,
  locationCheckCompleted: (state: RootState) =>
    state.projects.locationCheckResult !== LOCATION_CHECK_RESULT.NONE,
  locationCheckResult: (state: RootState) => state.projects.locationCheckResult,
  projectFormData: (state: RootState) => state.projects.projectFormData,
  validationErrors: (state: RootState) => state.projects.validationErrors,
};
