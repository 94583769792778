import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import Application from '~models/Application';

type PageState = 'listing' | 'editing' | 'deleting';

interface State {
  pageState: PageState;
  deletingId: number | null;
  formData: Partial<Application> | null;
  updateTimeoutTime: number | null;
  loading: any;
  error: any;
  selectedApplication: Application | null;
}

const certificateApplicationAdapter = createEntityAdapter<Application>();

const initialState = certificateApplicationAdapter.getInitialState({
  pageState: 'listing',
  deletingId: null,
  formData: null,
  updateTimeoutTime: null,
  loading: {},
  error: {},
  selectedApplication: null,
} as State);

const slice = createSlice({
  name: 'certificateApplications',
  initialState,
  reducers: {
    setPageState(
      state,
      action: PayloadAction<
        { deleting: number } | Exclude<PageState, 'deleting'>
      >
    ) {
      const pageState = action.payload;

      state.deletingId = null;

      if (typeof pageState === 'string') {
        if (pageState === 'listing') {
          state.pageState = 'listing';
        } else if (pageState === 'editing') {
          state.pageState = 'editing';
        }
      } else {
        if ('deleting' in pageState) {
          state.pageState = 'deleting';
          state.deletingId = pageState.deleting;
        }
      }
    },

    updateField(state, action: PayloadAction<Partial<Application>>) {
      state.formData = { ...state.formData, ...action.payload };
    },

    setUpdateTimeoutTime(state, action: PayloadAction<number | null>) {
      state.updateTimeoutTime = action.payload;
    },

    setSelectedApplication(state, action: PayloadAction<Application | null>) {
      state.selectedApplication = action.payload;
    },
  },
});

export const { actions } = slice;
export default slice.reducer;

const adapterSelectors = certificateApplicationAdapter.getSelectors(
  (state: any) => state.certificateApplications
);

export const selectors = {
  pageState: state => state.certificateApplications.pageState,
  formData: state => state.certificateApplications.formData,
  deletingId: state => state.certificateApplications.deletingId,
  updateTimeoutTime: state => state.certificateApplications.updateTimeoutTime,
  selectedApplication: state =>
    state.certificateApplications.selectedApplication,
};
