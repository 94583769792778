/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import {
  createSlice,
  PayloadAction,
  createEntityAdapter,
} from '@reduxjs/toolkit';

import { defineMessages } from 'react-intl';
import { isBefore, isAfter } from 'date-fns';
import range from 'lodash/range';

import { COMMON } from '../common';
import { actions as toastActions, Toast, ToastType } from '../toaster';

import * as api from './api';

import Message from '../../models/RalaMessage';
import { RootState } from '../index';
import { APIError } from '../api';
import { selectors as userSelectors } from '~store/user';
import { asSnakeCase, asCamelCase } from '~common/helpers';

const messages = defineMessages({
  createMessageSuccess: {
    id: 'message-create-success-toast',
    defaultMessage: 'Viesti lähetetty',
  },
  destroyMessageSuccess: {
    id: 'message-destroy-success-toast',
    defaultMessage: 'Viesti poistettu',
  },
});

const PAGESIZE = 15;

interface Recipient {
  id: number;
  firstName: string;
  lastName: string;
}

interface State {
  recipientSuggestions: Recipient[];
  ralaSuggestions: Recipient[];
  ralaRecipients: Recipient[];
  loading: { [type: string]: boolean };
  error: { [type: string]: APIError | null };
}

const messagesAdapter = createEntityAdapter<Message & { page?: number }>({
  sortComparer: (a, b) => {
    if (isBefore(new Date(a.updatedAt), new Date(b.updatedAt))) {
      return 1;
    } else if (isAfter(new Date(a.updatedAt), new Date(b.updatedAt))) {
      return -1;
    } else {
      return 0;
    }
  },
});

const initialState = messagesAdapter.getInitialState({
  recipientSuggestions: [],
  ralaSuggestions: [],
  ralaRecipients: [],
  loading: {},
  error: {},
} as State);

const slice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    fetchRecipientSuggestions(
      state,
      action: PayloadAction<{ companyId: number }>
    ) {},

    fetchRalaSuggestions(state) {},

    getRalaSuggestions(state) {},

    receiveRecipientSuggestions(state, action: PayloadAction<Recipient[]>) {
      state.recipientSuggestions = action.payload;
    },

    receiveRalaSuggestions(state, action: PayloadAction<Recipient[]>) {
      state.ralaSuggestions = action.payload;
    },

    receiveRalaRecipients(state, action: PayloadAction<Recipient[]>) {
      state.ralaRecipients = action.payload;
    },

    loading(state, action) {
      const type = action.payload;
      state.loading[type] = true;
      state.error[type] = null;
    },

    success(state, action) {
      const type = action.payload;
      state.loading[type] = false;
      state.error[type] = null;
    },

    error(state, action) {
      const { type, error } = action.payload;
      state.loading[type] = false;
      state.error[type] = error;
    },
  },
  extraReducers: builder => {
    builder.addCase(COMMON.NAVIGATE, state => {
      Object.keys(state.error).forEach(k => {
        state.error[k] = null;
      });
    })
  },
});

export const { actions } = slice;
export default slice.reducer;

const adapterSelectors = messagesAdapter.getSelectors(
  (state: RootState) => state.messages
);

export const selectors = {
  recipientSuggestions: (state: RootState) => {
    const suggestions = [...state.messages.recipientSuggestions];
    suggestions.sort((a, b) => a.firstName.localeCompare(b.firstName));
    return suggestions;
  },
  ralaSuggestions: (state: RootState) => {
    const suggestions = [...state.messages.ralaSuggestions];
    suggestions.sort((a, b) => a.firstName.localeCompare(b.firstName));
    return suggestions;
  },
  ralaRecipients: (state: RootState) => {
    const recipients = [...state.messages.ralaRecipients];
    recipients.sort((a, b) => a.firstName.localeCompare(b.firstName));
    return recipients;
  },
  loading: (state: RootState, key?) =>
    key
      ? !!state.messages.loading[key]
      : Object.values(state.messages.loading).some(v => Boolean(v)),
  error: (state: RootState, key?) =>
    key ? state.messages.error[key] || null : state.messages.error,
};

export function* fetchRecipientSuggestions(
  action: ReturnType<typeof actions.fetchRecipientSuggestions>
) {
  const { type } = action;
  const { companyId } = action.payload;

  try {
    yield put(actions.loading(type));

    const currentUser = yield select(userSelectors.currentUser);

    const { data } = yield call(api.fetchRecipientSuggestions, companyId);
    yield put(
      actions.receiveRecipientSuggestions(
        data
          .map(s => asCamelCase(s))
          .filter(
            s => parseInt(s.id, 10) !== parseInt(currentUser.person.id, 10)
          )
      )
    );

    yield put(actions.success(type));
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* fetchRalaSuggestions(
  action: ReturnType<typeof actions.fetchRalaSuggestions>
) {
  const { type } = action;

  try {
    yield put(actions.loading(type));

    const currentUser = yield select(userSelectors.currentUser);

    const { data } = yield call(api.fetchRalaSuggestions);
    const dataAsCamelCase = data.map(s => asCamelCase(s));
    yield put(actions.receiveRalaRecipients(dataAsCamelCase));
    yield put(
      actions.receiveRalaSuggestions(
        dataAsCamelCase.filter(s => s.id !== currentUser.id)
      )
    );

    yield put(actions.success(type));
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* messagesSaga() {
  yield all([
    yield takeLatest(
      actions.fetchRecipientSuggestions.type,
      fetchRecipientSuggestions
    ),
    yield takeLatest(actions.fetchRalaSuggestions.type, fetchRalaSuggestions),
  ]);
}
