/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import { RootState } from '~store/index';
import { IdAndName } from '~models/IdAndName';
import { TypeaheadCombinedListItem } from '~common/MultiSelectTypeahead';

const companyMembershipsAdapter = createEntityAdapter<IdAndName>();

interface State {
  combinedMembershipsList: TypeaheadCombinedListItem<number>[];
}

const initialState = companyMembershipsAdapter.getInitialState({
  combinedMembershipsList: [],
} as State);

const slice = createSlice({
  name: 'companyMemberships',
  initialState,
  reducers: {
    receiveSCombinedMembershipsList(state, action) {
      state.combinedMembershipsList = action.payload;
    },
  },
});

export const { actions } = slice;
export default slice.reducer;

const adapterSelectors = companyMembershipsAdapter.getSelectors(
  (state: RootState) => state.companyMemberships
);

export const selectors = {
  combinedMembershipsList: state =>
    state.companyMemberships.combinedMembershipsList
      ? state.companyMemberships.combinedMembershipsList
      : [],
  selectedCompanyMemberships: state =>
    state.companyMemberships.selectedCompanyMemberships
      ? state.companyMemberships.selectedCompanyMemberships
      : [],
};
