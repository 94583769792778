import camelCase from 'lodash/camelCase';

import Address from './Address';
import Union from './Union';
import Certificate from './Certificate';
import Person from './Person';
import { CERTIFICATE_PRODUCTS } from '~common/enums';

export default class Company {
  public readonly id: string;
  public readonly name: string;
  public readonly addresses: Address[];
  public readonly registrationNumber: string;
  public readonly managingDirectorId: number;
  public readonly switchboardNumber?: string;
  public readonly memberships: { union: Union; membershipId: string }[];
  public readonly companyStatusId: number;
  public readonly companyTypeId: number;
  public readonly customerTypeId: number;
  public readonly logoFileHash: string;
  public readonly monitoringStartedAt: string;
  public readonly auditorComments: string;
  public readonly certificates?: Certificate[];
  public readonly legacyRelatedCompanies?: string;
  public readonly legacyOtherManagement: string | null;
  public readonly legacySpecialQualifications: string | null;
  public readonly environmentContactPerson?: Person;
  public readonly proficiencyContactPerson?: Person;
  public readonly qualityContactPerson?: Person;
  public readonly safetyContactPerson?: Person;
  public readonly moistureContactPerson?: Person;
  public readonly syncEnvironmentCertificatePlan?: boolean;
  public readonly syncSafetyCertificatePlan?: boolean;
  public readonly syncMoistureCertificatePlan?: boolean;
  public readonly latestApplications?: {
    environment: number | null;
    proficiency: number | null;
    quality: number | null;
    safety: number | null;
    moisture: number | null;
  };

  constructor(data) {
    Object.keys(data).forEach(k => {
      this[camelCase(k)] = data[k];
    });

    this.addresses = (this.addresses || []).map(a => new Address(a));
    this.certificates = (this.certificates || []).map(a => new Certificate(a));
    this.environmentContactPerson = this.environmentContactPerson
      ? new Person(this.environmentContactPerson)
      : undefined;
    this.proficiencyContactPerson = this.proficiencyContactPerson
      ? new Person(this.proficiencyContactPerson)
      : undefined;
    this.qualityContactPerson = this.qualityContactPerson
      ? new Person(this.qualityContactPerson)
      : undefined;
    this.safetyContactPerson = this.safetyContactPerson
      ? new Person(this.safetyContactPerson)
      : undefined;
    this.moistureContactPerson = this.moistureContactPerson
      ? new Person(this.moistureContactPerson)
      : undefined;
  }

  get productList() {
    if (!this.certificates) {
      return [];
    }

    const products = this.certificates
      .filter(
        certificate =>
          Number(certificate.certificateTypeId) in CERTIFICATE_PRODUCTS
      )
      .map(
        certificate =>
          CERTIFICATE_PRODUCTS[Number(certificate.certificateTypeId)]
      )
      .sort((a, b) => a - b);

    return [...new Set(products)];
  }
}
