import api from '../api';

export function createCompanyCertificate({ companyId, certificate }) {
  const data = new FormData();
  const { file, ...rest } = certificate;
  data.append('file', file);
  for (const [key, val] of Object.entries(rest)) {
    data.append(key, val);
  }
  return api.post(`/companies/${companyId}/certificates`, data);
}

export function fetchCompanyCertificates({ companyId }) {
  return api.get(`/companies/${companyId}/certificates`);
}

export function fetchCompanyCertificateFile({ companyId, certificateId }) {
  return api.get(`/companies/${companyId}/certificates/${certificateId}/file`);
}

export function updateCompanyCertificate({ companyId, certificate }) {
  const data = new FormData();
  const { file, ...rest } = certificate;
  if (file) {
    data.append('file', file);
  }
  for (const [key, val] of Object.entries(rest)) {
    data.append(key, val);
  }
  // FormData can't be sent with PUT and files require using FormData.
  // Fortunately Laravel understands setting the method from payload.
  data.append('_method', 'PUT');
  return api.post(
    `/companies/${companyId}/certificates/${certificate.id}`,
    data
  );
}

export function deleteCompanyCertificate({ companyId, certificateId }) {
  return api.delete(`/companies/${companyId}/certificates/${certificateId}`);
}

export function createCompanyCertificateFile({
  companyId,
  certificateId,
  certificateFile,
}) {
  const data = new FormData();
  data.append('file', certificateFile);

  return api.post(
    `/companies/${companyId}/certificates/${certificateId}/files`,
    data
  );
}

export function deleteCompanyCertificateFile({
  companyId,
  certificateId,
  fileId,
}) {
  return api.delete(
    `/companies/${companyId}/certificates/${certificateId}/files/${fileId}`
  );
}

export function createPersonCertificate(payload) {
  return api.post(
    `/people/${payload.companyPerson.personId}/certificates`,
    payload.certification
  );
}

export function updatePersonCertificate(payload) {
  return api.put(
    `/people/${payload.companyPerson.personId}/certificates/${payload.data.id}`,
    payload.certification
  );
}

export function deletePersonCertificate(payload) {
  return api.delete(
    `/people/${payload.companyPerson.personId}/certificates/${payload.certificateId}`
  );
}
