interface Props {
  center?: boolean;
  small?: boolean;
  white?: boolean;
}

const Spinner = ({ center = false, small = false, white = false }: Props) => (
  <div className={`spinner ${center ? 'center' : ''}`} />
);

export default Spinner;
