import camelCase from 'lodash/camelCase';
import Person from './Person';
import { asCamelCase } from '~common/helpers';

export interface Role {
  id: number;
  longDescription: string;
  shortDescription: string;
}

export default class BoardMember {
  public readonly id: number;
  public readonly boardId: string;
  public readonly personId: string;
  public readonly boardMemberRoleId: number;
  public readonly active: string;
  public readonly person: Person | null;
  public readonly role: Role | null;
  public readonly createdAt: string;
  public readonly updatedAt: string;
  public readonly deletedAt: string;

  constructor(data) {
    Object.keys(data).forEach(k => {
      this[camelCase(k)] = data[k];
    });

    this.person = data.person ? new Person(data.person) : null;
    this.person = data.person ? new Person(data.person) : null;
    this.role = data.role ? asCamelCase(data.role) : null;
  }
}
