import camelCase from 'lodash/camelCase';
import Person from './Person';
import Permissions from './Permissions';

export default class AuditorUser {
  public readonly person: Person;
  public readonly createdAt: string;
  public readonly deletedAt: string;
  public readonly updatedAt: number;
  public readonly email: string;
  public readonly emailVerifiedAt: string | null;
  public readonly id: number;
  public readonly permissions: Permissions[];
  public readonly personId: number;

  constructor(data) {
    Object.keys(data).forEach(k => {
      this[camelCase(k)] = data[k];
    });

    this.person = this.person ? new Person(this.person) : undefined;
    this.permissions = this.permissions
      ? this.permissions.map(permission => new Permissions(permission))
      : [];
  }
}
