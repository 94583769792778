export default class Invite {
  public readonly id: string;
  public readonly company_id: string;
  public readonly email: string;
  public readonly token: string;
  public readonly company_admin: boolean;
  public readonly company_managing_director: boolean;
  public readonly company_contact_person: boolean;
  public readonly created_at: string;
  public readonly updated_at: string;
  public readonly deleted_at: string;
  public readonly expires_at: string;

  constructor(data: Invite) {
    Object.keys(data).forEach(k => {
      this[k] = data[k];
    });
  }
}

export interface InviteToAccept {
  company_id: string;
  token: string;
  company_name?: string;
  email?: string;
  validated?: boolean;
  is_valid?: boolean;
  new_user?: boolean;
  accepted?: boolean;
}
