/* eslint-disable no-param-reassign */
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { APIError } from '../api';
import * as api from './api';
import * as companiesAPI from '../companies/api';

import reducer, { actions } from './slice';

import { actions as companiesActions } from '../companies/slice';
import {
  actions as inviteActions,
  selectors as inviteSelectors,
} from '../invites';
import { ADDRESS_TYPES } from '~common/enums';
import Address from '~models/Address';
import { RootState } from '~store/index';
import User from '~models/User';

export { actions };
export default reducer;

const actionCompleted = (
  actions,
  action: PayloadAction<any>,
  actionType: string
) =>
  (action.type === actions.success.type && action.payload === actionType) ||
  (action.type === actions.error.type && action.payload?.type === actionType);

export const selectors = {
  currentUser: (state: RootState): User | null => state.user.user,
  registrationStatus: (state: RootState) => state.user.registrationStatus,
  companySearchStatus: (state: RootState) => state.user.companySearchStatus,
  registrationToken: (state: RootState) => state.user.registrationToken,
  registrationCompany: (state: RootState) => state.user.registrationCompany,
  registrationCompanyFound: (state: RootState) =>
    state.user.registrationCompanyFound,
  registrationCompanyStatus: (state: RootState) =>
    state.user.registrationCompanyStatus,
  registrationCompanySearchPending: (state: RootState) =>
    state.user.registrationCompanySearchPending,
  registrationCompanyFirstViableAddress: (state: RootState): Address | null => {
    let matchingAddress: Address | null = null;
    if (
      state.user.registrationCompany &&
      state.user.registrationCompany.addresses
    ) {
      state.user.registrationCompany.addresses.forEach(address => {
        if (address.street && address.zipcode && address.city) {
          matchingAddress = address;
        }
      });
    }
    return matchingAddress;
  },
  loading: (state: RootState, key?) =>
    key ? !!state.user.loading[key] : state.user.loading,
  error: (state: RootState, key?) =>
    key ? state.user.error[key] || null : state.error,
  distributionLists: (state: RootState) => state.user.distributionLists,
  personDistributionLists: (state: RootState) =>
    state.user.personDistributionLists,
  isNavShrunk: state => state.user.isNavShrunk,
  isNavItemCollapsed: state => state.user.isNavItemCollapsed,
  isMobileMenuActive: state => state.user.isMobileMenuActive,
};

export function* fetchCurrentUser() {
  const { type } = actions.fetch;

  try {
    yield put(actions.loading(type));

    let user = null;
    if (localStorage.getItem('authenticated')) {
      ({ data: user } = yield call(api.fetchCurrentUser));
    }

    yield put(actions.receive(user));
    yield put(actions.success(type));
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* login(action) {
  const { type } = actions.login;

  try {
    yield put(actions.loading(type));

    const response = yield call(api.login, action.payload.auth);
    yield put(actions.receive(response.data));

    const inviteToAccept = yield select(inviteSelectors.inviteToAccept);
    if (inviteToAccept) {
      yield put(inviteActions.accept({ invite: inviteToAccept }));
      yield take(action =>
        actionCompleted(inviteActions, action, inviteActions.accept.type)
      );
    }

    yield put(actions.success(type));

    localStorage.setItem('authenticated', 'true');

    yield put(action.payload.push('/'));
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

/**
 * Used when user is invited to join a company and has to register for a new user account.
 * In this case, server will automatically log in user after registration is done, but client does not detect this server-side login.
 * This function makes an api call to /me and if there is no error, then a local storage value "authenticated" is set to make user logged in for the client side also.
 */
export function* loginByServerSession() {
  const { type } = actions.loginByServerSession;

  try {
    const response = yield call(api.fetchCurrentUser);
    yield put(actions.receive(response.data));

    yield put(actions.success(type));

    localStorage.setItem('authenticated', 'true');
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* logout() {
  api.logout().then(() => {
    window.location.replace('/auth/login');
    localStorage.clear();
    sessionStorage.clear();
  });
}

export function* fetchRegistrationCompany(action) {
  const { type } = actions.fetchRegistrationCompany;
  try {
    yield put(actions.loading(type));

    const internal = yield call(companiesAPI.fetchCompanies, action.payload);
    if (internal.data.length) {
      yield put(actions.receiveRegistrationCompany(internal.data[0]));
      yield put(actions.success(type));
    }

    if (!internal.data.length) {
      try {
        const external = yield call(
          companiesAPI.fetchExternalCompany,
          action.payload
        );

        yield put(actions.receiveRegistrationCompany(external.data));
        yield put(actions.success(type));
      } catch (error) {
        if (error instanceof APIError) {
          yield put(actions.error({ type, error }));
        } else {
          throw error;
        }

        yield put(
          actions.receiveRegistrationCompany({
            registration_number: action.payload.registrationNumber,
            addresses: [
              new Address({ addressTypeId: ADDRESS_TYPES.POSTAL_STREET }),
            ],
          })
        );
      }
    }
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* register(action) {
  const { type } = actions.register;

  try {
    yield put(actions.loading(type));
    const response = yield call(api.register, action.payload);
    yield put(actions.receivedRegistrationToken(response.data.token));
    yield put(actions.setRegistrationStatus('authentication'));
    yield put(actions.success(type));
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* verifyEmail(action) {
  const { type } = actions.verifyEmail;

  try {
    yield put(actions.loading(type));
    yield call(api.verifyEmail, action.payload);
    yield put(actions.success(type));
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* resetPasswordRequest(
  action: ReturnType<typeof actions.resetPasswordRequest>
) {
  const { type, payload } = action;

  try {
    yield put(actions.loading(type));
    yield call(api.resetPasswordRequest, payload);
    yield put(actions.success(type));
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* resetPassword(
  action: ReturnType<typeof actions.resetPassword>
) {
  const { type, payload } = action;

  try {
    yield put(actions.loading(type));
    yield call(api.resetPassword, payload);
    yield put(actions.success(type));
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* confirmEmail(action: ReturnType<typeof actions.confirmEmail>) {
  const { type, payload } = action;

  try {
    yield call(api.confirmEmail, payload);
    yield put(actions.success(type));
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* userSaga() {
  yield all([
    yield takeLatest(actions.resetPasswordRequest.type, resetPasswordRequest),
    yield takeLatest(actions.resetPassword.type, resetPassword),
    yield takeLatest(actions.confirmEmail.type, confirmEmail),
    yield takeLatest(actions.fetch.type, fetchCurrentUser),
    yield takeLatest(actions.login.type, login),
    yield takeLatest(actions.loginByServerSession.type, loginByServerSession),
    yield takeLatest(actions.logout.type, logout),
    yield takeLatest(
      actions.fetchRegistrationCompany.type,
      fetchRegistrationCompany
    ),
    yield takeLatest(actions.register.type, register),
    yield takeLatest(actions.verifyEmail.type, verifyEmail),
  ]);
}
