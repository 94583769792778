/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { all, call, put, take, takeLatest, select } from 'redux-saga/effects';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { actions as companiesActions } from '../companies';
import { actions as userActions, selectors as userSelectors } from '../user';
import {
  actions as inviteActions,
  selectors as inviteSelectors,
} from '../invites';
import { RootState } from '../rootReducer';

const slice = createSlice({
  name: 'init',
  initialState: {
    done: false,
  },
  reducers: {
    init(_state, action: PayloadAction<{ push: any }>) {},

    initDone(state) {
      state.done = true;
    },
  },
});

export const { actions } = slice;
export default slice.reducer;

export const selectors = {
  done: (state: RootState) => state.init.done,
};

function* init(action: ReturnType<typeof actions.init>) {
  try {
    yield put(userActions.fetch());

    const userAction = yield take([userActions.receive, userActions.error]);
    if (userAction.type === userActions.receive.type) {
      const user = yield select(userSelectors.currentUser);
      if (user) {
        const inviteToAccept = yield select(inviteSelectors.inviteToAccept);
        if (inviteToAccept) {
          yield put(
            inviteActions.accept({
              invite: inviteToAccept,
            })
          );
          yield take(
            action =>
              (action.type === inviteActions.success.type &&
                action.payload === inviteActions.accept.type) ||
              (action.type === inviteActions.error.type &&
                action.payload?.type === inviteActions.accept.type)
          );
        }
      }
    }

    yield put(actions.initDone());
  } catch (error) {
    console.error(error); // eslint-disable-line no-console
  }
}

export function* initSaga() {
  yield all([yield takeLatest(actions.init.type, init)]);
}
