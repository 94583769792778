/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { COMMON } from '../common';
import Company from '~models/Company';
import User from '~models/User';

type NavItemNames =
  | 'board'
  | 'customerService'
  | 'rala'
  | 'certificate'
  | 'companyRestructuring'
  | 'invoicing';

type NavItem = {
  [name in NavItemNames]: boolean;
};

interface State {
  user: User | null;
  registrationStatus:
    | 'registration_form'
    | 'authentication'
    | 'email_confirmed'
    | 'company_search';
  companySearchStatus: 'none' | 'company_received' | 'completed';
  registrationToken: string | null;
  registrationCompany: Company | null;
  registrationCompanyFound: boolean | null;
  registrationCompanyStatus: 'none' | 'has_admin' | 'no_admin';
  registrationCompanySearchPending: boolean;
  loading: { [k: string]: boolean };
  error: { [k: string]: Error | null };
  addressTypes: any;
  distributionLists: any;
  personDistributionLists: number[];
  selectedCompany: number | null;
  isNavShrunk: boolean;
  isNavItemCollapsed: NavItem;
  isMobileMenuActive: boolean;
}

const initialState: State = {
  user: null,
  registrationStatus: 'registration_form',
  companySearchStatus: 'none',
  registrationToken: null,
  registrationCompany: null,
  registrationCompanyFound: null,
  registrationCompanyStatus: 'none',
  registrationCompanySearchPending: false,
  loading: {},
  error: {},
  addressTypes: [],
  distributionLists: [],
  personDistributionLists: [],
  selectedCompany: null,
  isNavShrunk: false,
  isNavItemCollapsed: {
    board: false,
    customerService: false,
    rala: false,
    certificate: false,
    companyRestructuring: false,
    invoicing: false,
  },
  isMobileMenuActive: false,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    fetch(state) {
      state.loading.fetch = true;
      state.error.fetch = null;
    },
    setRegistrationStatus(state, action) {
      state.registrationStatus = action.payload;
    },
    setCompanySearchStatus(state, action) {
      state.companySearchStatus = action.payload;
    },
    receive(state, action) {
      state.user = action.payload ? new User(action.payload) : null;
      state.loading.fetch = false;
    },
    clearRegistrationCompany(state) {
      state.registrationCompany = null;
    },
    clearError(state) {
      state.error = {};
    },

    login(
      state,
      action: PayloadAction<{
        auth: { email: string; password: string };
        push: any;
      }>
    ) {},

    loginByServerSession(state) {},

    logout() {},

    fetchRegistrationCompany(state, action: PayloadAction<any>) {
      state.registrationCompany = null;
    },

    receiveRegistrationCompany(state, action) {
      state.registrationCompany = action.payload
        ? new Company(action.payload)
        : null;
      if (action.payload.name) {
        state.registrationCompanyFound = true;
      } else {
        state.registrationCompanyFound = false;
      }

      const registrationCompanyStatus = action.payload.id
        ? 'has_admin'
        : 'no_admin';
      state.registrationCompanyStatus = registrationCompanyStatus;
      state.companySearchStatus = 'company_received';
    },

    register(
      state,
      action: PayloadAction<{
        email: string;
        firstName: string;
        lastName: string;
        password: string;
        passwordConfirmation: string;
        newsletter: boolean;
        invite_token?: string;
      }>
    ) {},

    receivedRegistrationToken(state, action) {
      state.registrationToken = action.payload;
    },

    verifyEmail(state, action: PayloadAction<any>) {},

    resetPasswordRequest(state, action: PayloadAction<{ email: string }>) {},

    resetPassword(
      state,
      action: PayloadAction<{
        email: string;
        token: string;
        newPassword: string;
        confirmPassword: string;
      }>
    ) {},

    confirmEmail(
      state,
      action: PayloadAction<{
        signature: string;
        expires: string;
        id: string;
        hash: string;
      }>
    ) {},

    loading(state, action) {
      const type = action.payload;
      state.loading[type] = true;
      state.error[type] = null;
    },

    success(state, action) {
      const type = action.payload;
      state.loading[type] = false;
      state.error[type] = null;
    },

    error(state, action) {
      const { type, error } = action.payload;
      state.loading[type] = false;
      state.error[type] = error;
    },
    setIsNavShrunk(state, action: PayloadAction<any>) {
      const { payload } = action;
      state.isNavShrunk = payload;
    },
    setIsNavItemCollapsed(
      state,
      action: PayloadAction<{ name: NavItemNames; value: boolean }>
    ) {
      const { name, value } = action.payload;
      if (name !== undefined && value !== undefined) {
        state.isNavItemCollapsed[name] = value;
      }
    },
    setIsMobileMenuActive(state, action: PayloadAction<boolean>) {
      const { payload } = action;
      state.isMobileMenuActive = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(COMMON.NAVIGATE, state => {
      Object.keys(state.error).forEach(k => {
        state.error[k] = null;
      });
    })
  },
});

export default slice.reducer;

export const { actions } = slice;
