import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit';
import { RootState } from '~store/index';
type modalState = 'listing' | 'create' | 'edit' | 'delete';
type ValidationErrors = Partial<{ [key in keyof FormData]: string[] }>;
interface State {
  formData: {
    id: number | null;
    name: string;
    referenceIds: number[];
  };
  showModal: modalState;
  validationErrors: ValidationErrors | null;
}

const companyOfferPacksAdapter = createEntityAdapter<any>();

const initialState = companyOfferPacksAdapter.getInitialState({
  formData: {
    id: null,
    name: '',
    referenceIds: [],
  },
  showModal: 'listing',
  validationErrors: null,
} as State);

const slice = createSlice({
  name: 'companyOfferPacks',
  initialState,
  reducers: {
    setFormField(state, action: PayloadAction<any>) {
      state.formData = { ...state.formData, ...action.payload };
    },
    setModalState(state, action: PayloadAction<any>) {
      state.showModal = action.payload;
      if (action.payload === 'listing') {
        state.formData = initialState.formData;
      }
    },
    setValidationErrors(state, action: PayloadAction<ValidationErrors | null>) {
      state.validationErrors = action.payload;
    },
  },
});

export const { actions } = slice;
export default slice.reducer;

export const selectors = {
  formData: (state: RootState) => state.companyOfferPacks.formData,
  modalState: (state: RootState) => state.companyOfferPacks.showModal,
  validationErrors: state => state.companyOfferPacks.validationErrors,
};
