import { formatNumberToParts } from '@formatjs/intl';
import camelCase from 'lodash/camelCase';
import { number } from 'prop-types';

export interface Descriptions {
  id: number;
  code: string | null;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  describable_type: string;
  describable_id: number;
  help_text: string | null;
  language_code: string;
  long_description: string;
  short_description: string;
}
export interface EducationDegree {
  id: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  education_degree_id: number;
  has_descriptions: number;
  help_text: string | null;
  long_description: string;
  short_description: string;
  descriptions: Descriptions[];
}

export default class PersonEducation {
  public readonly id: string;
  educationLevelId: number;
  educationDegreeId: number;
  description: string;
  educationalInstitute: string;
  graduationYear: string;
  public readonly createdAt: string;
  public readonly updatedAt: string;
  public readonly deletedAt: string;
  public educationDegree: EducationDegree;

  constructor(data) {
    Object.keys(data).forEach(k => {
      this[camelCase(k)] = data[k];
    });
  }
}
