import camelCase from 'lodash/camelCase';
import CertificateType from './CertificateType';

export default class ApplicationTarget {
  public readonly id: number;
  public readonly certificateTypeId: string;
  public readonly longDescription: string;
  public readonly shortDescription: string;
  public readonly helpText: string;
  public readonly isAvailable: boolean;
  public readonly certificateType?: CertificateType;
  public readonly createdAt: string;
  public readonly updatedAt: string;
  public readonly deletedAt: string;
  public readonly isRenewal: boolean;

  constructor(data) {
    Object.keys(data).forEach(k => {
      this[camelCase(k)] = data[k];
    });
    this.certificateType = this.certificateType
      ? new CertificateType(this.certificateType)
      : undefined;
  }
}
