/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { all, call, put, takeEvery, select } from 'redux-saga/effects';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import kebabCase from 'lodash/kebabCase';

import * as api from './api';

import { RootState } from '../rootReducer';

import Description, {
  Describable,
  ApiDescribable,
  Descriptions,
  CertificateTypeDescription,
} from '~models/Description';

interface State {
  descriptions: Descriptions;
  loading: { [type: string]: boolean };
  error: { [type: string]: any };
}

const initialState: State = {
  descriptions: {},
  loading: {},
  error: {},
};

const slice = createSlice({
  name: 'descriptions',
  initialState,
  reducers: {
    fetchDescribables(
      state,
      action: PayloadAction<Describable | Describable[]>
    ) {},

    receiveDescribable(
      state,
      action: PayloadAction<{
        describable: Describable;
        data: any[];
      }>
    ) {
      const { describable, data } = action.payload;
      state.descriptions = {
        ...state.descriptions,
        [describable]: data.map(d =>
          describable === 'certificateTypes'
            ? new CertificateTypeDescription(d)
            : new Description(d)
        ),
      };
    },
    loading(state, action) {
      const type = action.payload;
      state.loading[type] = true;
      state.error[type] = null;
    },

    success(state, action) {
      const type = action.payload;
      state.loading[type] = false;
      state.error[type] = null;
    },

    error(state, action) {
      const { type, error } = action.payload;
      state.loading[type] = false;
      state.error[type] = error;
    },

    clearError(state, action) {
      const type = action.payload;
      state.error[type] = null;
    },
  },
});

export const { actions } = slice;
export default slice.reducer;

export const selectors = {
  descriptions: (state: RootState) => state.descriptions.descriptions,
  loading: (state, key?) =>
    key ? !!state.descriptions.loading[key] : state.descriptions.loading,
  error: (state, key?) =>
    key ? state.descriptions.error[key] || null : state.descriptions.error,
};

export function* fetchDescribables(
  action: ReturnType<typeof actions.fetchDescribables>
) {
  const { type } = actions.fetchDescribables;

  // If single parameter is passed, fetch describable from server
  if (typeof action.payload === 'string') {
    yield put(actions.loading(type));
    const describable = action.payload;
    const descriptions = yield select(selectors.descriptions);
    if (descriptions[describable]) {
      yield put(actions.success(type));
      return;
    }

    try {
      const { data } = yield call(
        api.fetchDescribable,
        kebabCase(describable) as ApiDescribable
      );
      yield put(actions.receiveDescribable({ describable, data }));
      yield put(actions.success(type));
    } catch (error) {
      yield put(actions.error({ type, error }));
      console.error(error);
    }
  } else {
    try {
      yield put(actions.loading(type));
      // If array of parameters is passed, call saga recursively
      const describables = action.payload;
      yield all(
        describables.map(d =>
          call(fetchDescribables, actions.fetchDescribables(d))
        )
      );
      yield put(actions.success(type));
    } catch (error) {
      yield put(actions.error({ type, error }));
      console.error(error);
    }
  }
}

export function* descriptionsSaga() {
  yield all([
    yield takeEvery(actions.fetchDescribables.type, fetchDescribables),
  ]);
}
