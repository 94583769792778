/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  createSlice,
  PayloadAction,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import { defineMessages } from 'react-intl';

import Reference, {
  FormFields as ReferenceFields,
} from '~models/CompanyReference';
import { actions as modalActions } from '~store/modal';
import config from '~/config';
import {
  REFERENCE_VERIFIED_BY,
  REFERENCE_VISIBILITY,
} from '~/features/common/enums';

const messages = defineMessages({
  createReferenceSuccess: {
    id: 'references-create-success-toast',
    defaultMessage: 'Referenssi luotu',
  },
});

type FormState = 'listing' | 'showing' | 'creating' | 'editing' | 'deleting';
type FormTab = 'project' | 'reference' | 'contractee';
type SearchState = 'basic' | 'advanced';
type ValidationErrors = Partial<{ [key in keyof ReferenceFields]: string[] }>;

type Url = { id: number; url: string };
type FileObject = { file: File };

interface State {
  formData: Partial<ReferenceFields>;
  formState: FormState;
  formTab: FormTab;
  validationErrors: ValidationErrors | null;
  showingId: number | null;
  editingId: number | null;
  deletingId: number | null;
  searchState: SearchState;
  referenceSearch: {
    search?: string;
    company_search?: string;
    client_company_search?: string;
    lifecycle_phase_ids?: number[];
    company_role_ids?: number[];
    contract_type_ids?: number[];
    expertise_ids?: number[];
    planning_type_ids?: number[];
    subcontractor_degree_min?: number;
    subcontractor_degree_max?: number;
    work_start_years_ago_min?: number;
    work_start_years_ago_max?: number;
    work_end_years_ago_min?: number;
    work_end_years_ago_max?: number;
    project_search?: string;
    project_regions?: string;
    project_sector_ids?: number[];
    project_classification_ids?: number[];
    project_size_min?: number;
    project_size_max?: number;
    visibility_type_id?: REFERENCE_VISIBILITY;
    reference_status_id?: number;
    verifications?: REFERENCE_VERIFIED_BY[];
    sort?: string;
    order?: string;
    paginate?: boolean | number;
    all_clients?: boolean;
  };
  isReferenceFormFilled: boolean;
}

const referenceAdapter = createEntityAdapter<Reference>();

const initialState = referenceAdapter.getInitialState({
  formData: {},
  formState: 'listing',
  formTab: 'project',
  validationErrors: null,
  showingId: null,
  editingId: null,
  deletingId: null,
  searchState: 'basic',
  referenceSearch: { page: 1, paginate: 12, sort: 'id', order: 'desc', all_clients: true },
  isReferenceFormFilled: false,
} as State);

const slice = createSlice({
  name: 'references',
  initialState,
  reducers: {
    setFormField(state, action: PayloadAction<Partial<ReferenceFields>>) {
      state.formData = { ...state.formData, ...action.payload };
    },
    setFormState(
      state,
      action: PayloadAction<
        | { showing: Reference }
        | { editing: Reference }
        | { deleting: number }
        | Exclude<FormState, 'editing' | 'deleting'>
      >
    ) {
      const formState = action.payload;

      state.showingId = null;
      state.editingId = null;
      state.deletingId = null;
      state.formData = {};

      if (typeof formState === 'string') {
        if (formState === 'listing') {
          state.formState = 'listing';
          state.formTab = 'project';
        } else if (formState === 'creating') {
          state.formState = 'creating';
        }
      } else {
        if ('showing' in formState) {
          const r = formState.showing;
          state.formState = 'showing';
          state.showingId = r.id;

          state.formData.images =
            r?.images?.map(img => ({
              id: img.id,
              url: `${config.API_URL}/companies/${r.contractorId}/references/${r.id}/images/${img.id}/content?size=md`,
            })) ?? [];
          state.formData.defaultImage = r.defaultImage
            ? {
                id: r.defaultImage.id,
                url: `${config.API_URL}/companies/${r.contractorId}/references/${r.id}/images/${r.defaultImage.id}/content?size=md`,
              }
            : undefined;
        }
        if ('editing' in formState) {
          state.formState = 'editing';
          state.editingId = formState.editing.id;
          return;
        }

        if ('deleting' in formState) {
          state.formState = 'deleting';
          state.deletingId = formState.deleting;
        }
      }
    },
    setFormTab(state, action: PayloadAction<FormTab>) {
      state.formTab = action.payload;
    },
    setReferenceSearchField(state, action: PayloadAction<any>) {
      state.referenceSearch = { ...state.referenceSearch, ...action.payload };
    },
    clearReferenceSearch(state) {
      state.referenceSearch = initialState.referenceSearch;
    },
    setSearchState(state, action: PayloadAction<SearchState>) {
      state.searchState = action.payload;
    },
    setValidationErrors(state, action: PayloadAction<ValidationErrors | null>) {
      state.validationErrors = action.payload;
    },
    setIsReferenceFormFilled(state, action: PayloadAction<boolean>) {
      state.isReferenceFormFilled = action.payload;
    },
  },
});

export const { actions } = slice;
export default slice.reducer;

const adapterSelectors = referenceAdapter.getSelectors(
  (state: any) => state.references
);

export const selectors = {
  formData: state => state.references.formData,
  formState: state => state.references.formState,
  formTab: state => state.references.formTab,
  validationErrors: state => state.references.validationErrors,
  showingId: state => state.references.showingId,
  editingId: state => state.references.editingId,
  deletingId: state => state.references.deletingId,
  activeImage: state => state.references.activeImage,
  referenceSearch: state => state.references.referenceSearch,
  searchState: state => state.references.searchState,
  isReferenceFormFilled: state => state.references.isReferenceFormFilled,
};
