/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  createSlice,
  PayloadAction,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import { defineMessages } from 'react-intl';
import ApplicationAnswer from '~/models/ApplicationAnswer';
import ApplicationQuestion, { AnswerForm } from '~models/ApplicationQuestion';

const messages = defineMessages({
  createAnswerSuccess: {
    id: 'answer-create-success-toast',
    defaultMessage: 'Vastaus tallennettu',
  },
});

type FormState = 'listing' | 'showing' | 'creating' | 'editing' | 'deleting';
type ValidationErrors = Partial<{ [key in keyof AnswerForm]: string[] }>;
type ModalState = 'showing' | 'listing';

interface State {
  formData: Partial<ApplicationQuestion>;
  answerData: Partial<ApplicationAnswer>;
  formState: FormState;
  selfEvaluation: {
    questionTitle: string;
    question: ApplicationQuestion | null;
  };
  validationErrors: ValidationErrors | null;
  showingId: number | null;
  editingId: number | null;
  deletingId: number | null;
  selfEvaluationModal: ModalState;
}

const questionAdapter = createEntityAdapter<ApplicationQuestion>();

const initialState = questionAdapter.getInitialState({
  formData: {},
  answerData: {
    answer: '',
    finished: false,
    applicationAnswerGradeId: null,
    reviewerApplicationAnswerGradeId: null,
    reviewerComment: '',
    public: false,
  },
  selfEvaluation: {
    questionTitle: '',
    question: null,
  },
  selfEvaluationModal: 'listing',
  formState: 'listing',
  validationErrors: null,
  showingId: null,
  editingId: null,
  deletingId: null,
} as State);

const slice = createSlice({
  name: 'applicationQuestions',
  initialState,
  reducers: {
    setFormField(state, action: PayloadAction<Partial<ApplicationQuestion>>) {
      state.formData = { ...state.formData, ...action.payload };
    },
    setAnswerField(state, action: PayloadAction<Partial<ApplicationAnswer>>) {
      state.answerData = { ...state.answerData, ...action.payload };
    },
    setSelfEvaluation(
      state,
      action: PayloadAction<{
        questionTitle: string;
        question: ApplicationQuestion;
        reviewerComment: string;
        reviewerApplicationAnswerGradeId: number | null;
        public: boolean;
      }>
    ) {
      state.selfEvaluation = {
        questionTitle: action.payload.questionTitle,
        question: action.payload.question,
      };
      state.answerData = {
        ...state.answerData,
        reviewerComment: action.payload.reviewerComment,
        reviewerApplicationAnswerGradeId:
          action.payload.reviewerApplicationAnswerGradeId,
        public: action.payload.public,
      };
      state.selfEvaluationModal = 'showing';
    },
    setFormState(
      state,
      action: PayloadAction<
        | { showing: number }
        | { editing: number }
        | { deleting: number }
        | Exclude<FormState, 'editing' | 'deleting'>
      >
    ) {
      const formState = action.payload;

      state.showingId = null;
      state.editingId = null;
      state.deletingId = null;

      if (typeof formState === 'string') {
        if (formState === 'listing') {
          state.formData = {};
          state.formState = 'listing';
        } else if (formState === 'creating') {
          state.formState = 'creating';
        }
      } else {
        if ('showing' in formState) {
          state.formState = 'showing';
          state.showingId = formState.showing;
        }
        if ('editing' in formState) {
          state.formState = 'editing';
          state.editingId = formState.editing;
          return;
        }

        if ('deleting' in formState) {
          state.formState = 'deleting';
          state.deletingId = formState.deleting;
        }
      }
    },
    clearFormData(state) {
      state.formData = initialState.formData;
      state.answerData = initialState.answerData;
      state.selfEvaluation = initialState.selfEvaluation;
      state.selfEvaluationModal = initialState.selfEvaluationModal;
    },
    setValidationErrors(state, action: PayloadAction<ValidationErrors | null>) {
      state.validationErrors = action.payload;
    },
  },
});

export const { actions } = slice;
export default slice.reducer;

const adapterSelectors = questionAdapter.getSelectors(
  (state: any) => state.applicationQuestionaries
);

export const selectors = {
  formData: state => state.applicationQuestionaries.formData,
  answerData: state => state.applicationQuestionaries.answerData,
  formState: state => state.applicationQuestionaries.formState,
  selfEvaluation: state => state.applicationQuestionaries.selfEvaluation,
  selfEvaluationModal: state =>
    state.applicationQuestionaries.selfEvaluationModal,
  validationErrors: state => state.applicationQuestionaries.validationErrors,
  showingId: state => state.applicationQuestionaries.showingId,
  editingId: state => state.applicationQuestionaries.editingId,
  deletingId: state => state.applicationQuestionaries.deletingId,
};
