import camelCase from 'lodash/camelCase';
import Company from './Company';

export enum COMPANY_RELATIONSHIP_TYPES {
  UNKNOWN = 1,
  DAUGHTER = 2,
  JOINT_VENTURE = 3,
}

export default class CompanyRelationship {
  public readonly id: string;
  public readonly companyRelationshipTypeId: number;
  public readonly parentCompany?: Company;
  public readonly daughterCompany?: Company;
  public readonly daughterCompanyId: number;
  public readonly parentCompanyId: number;

  constructor(data) {
    Object.keys(data).forEach(k => {
      this[camelCase(k)] = data[k];
    });
    this.parentCompany = this.parentCompany
      ? new Company(this.parentCompany)
      : undefined;
    this.daughterCompany = this.daughterCompany
      ? new Company(this.daughterCompany)
      : undefined;
  }
}
