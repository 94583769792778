import { all, call, takeEvery, put } from 'redux-saga/effects';
import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit';

import * as api from './api';
import { actions as companyPeopleActions } from '../companyPeople/index';

import Certificate from '~models/Certificate';

const certificatesAdapter = createEntityAdapter<Certificate>();

const initialState = certificatesAdapter.getInitialState({
  loading: {},
  error: {},
});

const slice = createSlice({
  name: 'certificates',
  initialState,
  reducers: {
    createOrUpdatePersonCertificate(state, action: PayloadAction<any>) {},
    deletePersonCertificate(state, action: PayloadAction<any>) {},

    deleteSuccess(state, action: PayloadAction<{ certificateId: string }>) {
      const { certificateId } = action.payload;
      certificatesAdapter.removeOne(state, certificateId);
    },

    loading(state, action) {
      const type = action.payload;
      state.loading[type] = true;
      state.error[type] = null;
    },

    success(state, action) {
      const type = action.payload;
      state.loading[type] = false;
      state.error[type] = null;
    },

    error(state, action) {
      const { type, error } = action.payload;
      state.loading[type] = false;
      state.error[type] = error;
    },
  },
});

export const { actions } = slice;
export default slice.reducer;

const adapterSelectors = certificatesAdapter.getSelectors(
  (state: any) => state.certificates
);

export const selectors = {
  certificates: (state, companyId) =>
    adapterSelectors.selectAll(state).filter(c => c.companyId === companyId),
  loading: (state, key?) =>
    key
      ? state.certificates.loading[key] || null
      : Object.values(state.certificates.loading).some(v => v),
  error: (state, key?) =>
    key ? state.certificates.error[key] || null : state.certificates.error,
};

export function* deletePersonCertificate(action) {
  const { type } = actions.deletePersonCertificate;

  try {
    yield put(actions.loading(type));
    yield call(api.deletePersonCertificate, action.payload);
    yield put(actions.success(type));
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* createOrUpdatePersonCertificate(action) {
  const { type } = actions.createOrUpdatePersonCertificate;

  try {
    yield put(actions.loading(type));

    if (localStorage.getItem('authenticated')) {
      if (action.payload.certification && action.payload.certification.id) {
        yield call(api.updatePersonCertificate, action.payload);
      } else {
        const { companyPerson, certifications, removeCertifications } =
          action.payload;

        yield all(
          removeCertifications.map(certification =>
            call(
              deletePersonCertificate,
              actions.deletePersonCertificate({
                certificateId: certification.id,
                companyPerson,
              })
            )
          )
        );

        yield all(
          certifications.map(certification =>
            call(api.createPersonCertificate, { companyPerson, certification })
          )
        );
      }
    }

    yield put(
      companyPeopleActions.fetchCompanyPerson(action.payload.companyPerson)
    );
    yield put(actions.success(type));
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* certificatesSaga() {
  yield all([
    yield takeEvery(
      actions.createOrUpdatePersonCertificate.type,
      createOrUpdatePersonCertificate
    ),
    yield takeEvery(
      actions.deletePersonCertificate.type,
      deletePersonCertificate
    ),
  ]);
}
