import {
  put,
  call,
  all,
  takeEvery,
  takeLatest,
  select,
} from 'redux-saga/effects';
import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit';

import * as api from './api';
import Union from '~models/Union';
import { actions as companyActions } from '~store/companies';
import { RootState } from '~store/rootReducer';

const unionsAdapter = createEntityAdapter<Union>();

const initialState = unionsAdapter.getInitialState({
  loading: {},
  error: {},
});

const slice = createSlice({
  name: 'unions',
  initialState,
  reducers: {
    fetchUnions(state) {},

    replaceUnions(state, action) {
      const { unions } = action.payload;
      unionsAdapter.setAll(
        state,
        unions.map(u => new Union(u))
      );
    },

    createCompanyMembership(state, action) {},

    fetchCompanyMemberships(
      state,
      action: PayloadAction<{ companyId: string }>
    ) {},

    loading(state, action) {
      const type = action.payload;
      state.loading[type] = true;
      state.error[type] = null;
    },

    success(state, action) {
      const type = action.payload;
      state.loading[type] = false;
      state.error[type] = null;
    },

    error(state, action) {
      const { type, error } = action.payload;
      state.loading[type] = false;
      state.error[type] = error;
    },
  },
});

export const { actions } = slice;
export default slice.reducer;

const adapterSelectors = unionsAdapter.getSelectors(
  (state: RootState) => state.unions
);

export const selectors = {
  unions: state => adapterSelectors.selectAll(state),
  loading: (state, key?) =>
    key ? state.unions.loading[key] || null : state.unions.loading,
  unionById: (state, id) => adapterSelectors.selectById(state, id),
  error: (state, key?) =>
    key ? state.unions.error[key] || null : state.unions.error,
};

export function* fetchUnions(action) {
  const { type } = actions.fetchUnions;

  try {
    const res = yield call(api.fetchUnions);
    yield put(actions.replaceUnions({ unions: res.data }));
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* createCompanyMembership(
  action: PayloadAction<
    | { companyId: string; unionId: string }
    | { companyId: string; description: string }
  >
) {
  const { type } = actions.createCompanyMembership;

  yield put(actions.loading(type));
  try {
    if ('description' in action.payload) {
      const { companyId, description } = action.payload;
      const res = yield call(
        api.createCompanyMembership,
        companyId,
        undefined,
        description
      );

      yield put(
        companyActions.receiveCompanyMemberships({
          companyId,
          memberships: [
            { union: new Union(res.data), membershipId: res.data.id },
          ],
        })
      );
    } else if ('unionId' in action.payload) {
      const { companyId, unionId } = action.payload;
      const res = yield call(
        api.createCompanyMembership,
        companyId,
        unionId,
        undefined
      );

      const unions = yield select(selectors.unions);

      yield put(
        companyActions.receiveCompanyMemberships({
          companyId,
          memberships: [
            {
              union: unions.find(
                ({ id }) => id === res.data.company_membership_union_id
              ),
              membershipId: res.data.id,
            },
          ],
        })
      );
    }
    yield put(actions.success(type));
  } catch (error) {
    yield put(actions.error({ type, error }));
    return error;
  }
}

export function* fetchCompanyMemberships(
  action: PayloadAction<{ companyId: string }>
) {
  const { type } = actions.fetchCompanyMemberships;
  const { companyId } = action.payload;

  try {
    yield put(actions.loading(type));

    const res = yield call(api.fetchCompanyMemberships, companyId);
    yield put(
      companyActions.replaceCompanyMemberships({
        companyId,
        memberships: res.data.map(u => ({
          union: new Union(u),
          membershipId: u.id,
        })),
      })
    );

    yield put(actions.success(type));
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* unionsSaga() {
  yield all([
    yield takeEvery(
      actions.createCompanyMembership.type,
      createCompanyMembership
    ),
    yield takeLatest(
      actions.fetchCompanyMemberships.type,
      fetchCompanyMemberships
    ),
    yield takeLatest(actions.fetchUnions.type, fetchUnions),
  ]);
}
