/* eslint-disable import/prefer-default-export */
import api from '../api';

export function fetchEducation() {
  return api.get('/descriptions/education-degrees');
}

export function fetchEducationLevels() {
  return api.get('/descriptions/education-levels');
}

export function getPersonEducation(payload) {
  return api.get(`/people/${payload}/educations`);
}

export function savePersonEducation(payload) {
  return api.post(`/people/${payload.personId}/educations`, payload.data);
}

export function deletePersonEducation(payload) {
  return api.delete(
    `/people/${payload.personId}/educations/${payload.educationId}`
  );
}

export function updatePersonData(companyPerson) {
  return api.put(`/people/${companyPerson.id}`, companyPerson);
}
