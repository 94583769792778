import camelCase from 'lodash/camelCase';
import Application from './Application';
import CertificateType from './CertificateType';

export default class Certificate {
  public readonly id: number;
  public readonly companyId: number;
  public readonly companyName: string;
  public readonly certificateName: string;
  public readonly certificateTypeId: number;
  public readonly certificateNumber: number;
  public readonly certificateSubId: number;
  public readonly issuer: string;
  public readonly isSelfIssuable: boolean;
  public readonly isPersonCertificate: boolean;
  public readonly validFrom: string;
  public readonly validUntil: string;
  public readonly validTo: string;
  public readonly additionalInfo: string;
  public readonly filename: string;
  public readonly application: Application | null;
  public readonly certificateType: CertificateType | null;
  public readonly renewalAvailable: boolean;

  constructor(data) {
    for (const k of Object.keys(data)) {
      if (k === 'file') {
        continue;
      }
      this[camelCase(k)] = data[k];
    }
    this.application = this.application
      ? new Application(this.application)
      : null;
    this.certificateType =
      this.certificateType !== undefined
        ? new CertificateType(this.certificateType)
        : null;

    this.validUntil = data.valid_to;
    this.additionalInfo = data.additional_info ?? '';
    if (data.file) {
      this.filename = data.file.filename;
    }
  }
}
