import camelCase from 'lodash/camelCase';
import Description from './Description';

export default class Product {
  public readonly id: string;
  public readonly code: string;
  public readonly salesUnit: string;
  public readonly vatPercentage: string;
  public readonly billingPeriod: string;
  public readonly periodUnit: string;
  public readonly automaticRenewal: string;
  public readonly directBuy: string;
  public readonly singleLicense: string;
  public readonly laravelThroughKey: string;
  public readonly longDescription: string;
  public readonly shortDescription: string;
  public readonly helpText: string;
  public readonly createdAt: string;
  public readonly updatedAt: string;
  public readonly deletedAt: string;
  public readonly descriptions?: Description[];

  constructor(data) {
    Object.keys(data).forEach(k => {
      this[camelCase(k)] = data[k];
    });
    this.descriptions =
      this?.descriptions?.map(status => new Description(status)) || [];
  }
}
