import { all, call, takeLatest, put } from 'redux-saga/effects';
import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit';

import * as api from './api';
import { actions as toastActions, ToastType, Toast } from '../toaster';
import AccountingPeriod from '~models/AccountingPeriod';
import { RootState } from '~store/index';

type FormState = 'listing' | 'creating' | `editing_${'finances' | 'personnel'}`;
type ValidationErrors = Partial<{ [key in keyof AccountingPeriod]: string[] }>;

interface State {
  formData: Partial<AccountingPeriod>;
  formState: FormState;
  editingId: number | null;
  validationErrors: ValidationErrors | null;
}

const accountingPeriodsAdapter = createEntityAdapter<AccountingPeriod>();

const initialState = accountingPeriodsAdapter.getInitialState({
  formData: {},
  formState: 'listing',
  editingId: null,
  validationErrors: null,
  financesValidation: {},
  loading: {},
  error: {},
} as State);

const slice = createSlice({
  name: 'finances',
  initialState,
  reducers: {
    setFormField(state, action: PayloadAction<Partial<AccountingPeriod>>) {
      state.formData = { ...state.formData, ...action.payload };
    },
    setFormState(
      state,
      action: PayloadAction<
        | { editing: 'finances' | 'personnel'; id: number }
        | Exclude<FormState, 'editing'>
      >
    ) {
      const formState = action.payload;

      state.editingId = null;

      if (typeof formState === 'string') {
        if (formState === 'listing') {
          state.formData = {};
          state.formState = 'listing';
        } else if (formState === 'creating') {
          state.formState = 'creating';
        }
      } else {
        if ('editing' in formState) {
          state.formState = `editing_${formState.editing}`;
          state.editingId = formState.id;
        }
      }
    },
    setValidationErrors(state, action: PayloadAction<ValidationErrors | null>) {
      state.validationErrors = action.payload;
    },

    fetchFinancesValidation(
      state,
      action: PayloadAction<{ companyId: string }>
    ) {},

    receiveFinancesValidation(state, action) {
      state.financesValidation = action.payload;
    },

    loading(state, action) {
      const type = action.payload;
      state.loading[type] = true;
    },

    success(state, action) {
      const type = action.payload;
      state.loading[type] = false;
    },
  },
});

export const { actions } = slice;
export default slice.reducer;

export const selectors = {
  formData: (state: RootState) => state.finances.formData,
  formState: (state: RootState) => state.finances.formState,
  editingId: (state: RootState) => state.finances.editingId,
  validationErrors: (state: RootState) => state.finances.validationErrors,
  financesValidation: state => state.finances.financesValidation,
  loading: (state, key) => state.finances.loading[key],
};

export function* fetchFinancesValidation(action) {
  const { type } = actions.fetchFinancesValidation;
  const { companyId } = action.payload;

  try {
    yield put(actions.loading(type));

    const res = yield call(api.fetchFinancesValidation, companyId);
    yield put(actions.receiveFinancesValidation(res.data));

    yield put(actions.success(type));
  } catch (error) {
    yield all(
      Object.values(error.errors || {})
        .flat()
        .map(e =>
          put(
            toastActions.toast(
              new Toast({ message: e, type: ToastType.Danger })
            )
          )
        )
    );
  }
}

export function* financesSaga() {
  yield all([
    yield takeLatest(
      actions.fetchFinancesValidation.type,
      fetchFinancesValidation
    ),
  ]);
}
