import { useReducer, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import add from 'date-fns/add';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import isValid from 'date-fns/isValid';
import snakeCase from 'lodash/snakeCase';
import camelCase from 'lodash/camelCase';

import _ from 'lodash';
import {
  actions as suggestionActions,
  selectors as suggestionSelectors,
} from '~store/suggestions';
import { Message } from '~store/modal';
import { VALUE_TYPES } from '~common/enums';

type Id = string;
type State = 'showing' | 'creating' | 'editing' | 'deleting';
type ValueType =
  | VALUE_TYPES.ARRAY
  | VALUE_TYPES.OBJECT
  | VALUE_TYPES.STRING
  | VALUE_TYPES.NUMBER
  | null;

type UpdateField<T> = {
  type: 'updateField';
  fieldData: Partial<T>;
};
type SetShowing = {
  type: 'setShowing';
};
type SetCreating = {
  type: 'setCreating';
};
type SetEditing = {
  type: 'setEditing';
  entityId: Id;
};
type SetDeleting = {
  type: 'setDeleting';
  entityId: Id;
};
export type FormAction<T> =
  | UpdateField<T>
  | SetShowing
  | SetCreating
  | SetEditing
  | SetDeleting;

export interface FormState<T> {
  state: State;
  formData?: Partial<T>;
  entityId?: Id;
}

export function useFormState<T>() {
  const [formState, dispatch] = useReducer(
    (state: FormState<T>, action: FormAction<T>) => {
      switch (action.type) {
        case 'updateField':
          return state.state === 'creating' || state.state === 'editing'
            ? {
                ...state,
                formData: { ...state.formData, ...action.fieldData },
              }
            : state;
        case 'setShowing':
          return { state: 'showing' } as FormState<T>;
        case 'setCreating':
          return { state: 'creating', formData: {} } as FormState<T>;
        case 'setEditing':
          return {
            state: 'editing',
            entityId: action.entityId,
            formData: {},
          } as FormState<T>;
        case 'setDeleting':
          return {
            state: 'deleting',
            entityId: action.entityId,
          } as FormState<T>;
        default:
          return state;
      }
    },
    { state: 'showing' }
  );

  const updateField = (updatedField: Partial<T>) =>
    dispatch({ type: 'updateField', fieldData: updatedField });

  const setShowing = () => dispatch({ type: 'setShowing' });
  const setCreating = () => dispatch({ type: 'setCreating' });
  const setEditing = (entityId: Id) =>
    dispatch({ type: 'setEditing', entityId });
  const setDeleting = (entityId: Id) =>
    dispatch({ type: 'setDeleting', entityId });

  return {
    formState,
    actions: {
      updateField,
      setShowing,
      setCreating,
      setEditing,
      setDeleting,
    },
  };
}

type Constructor<T = unknown> = new (...args: any[]) => T;

export function useSearch<T>(
  constructor: Constructor<T>,
  searchType: string,
  url: string,
  searchWord?: string
): T[] {
  const T = constructor;

  const fullUrl = searchWord ? `${url}?search=${searchWord}` : url;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(suggestionActions.fetch({ type: searchType, url: fullUrl }));
  }, [searchType, url]);

  const rawSuggestions = useSelector(state =>
    suggestionSelectors.suggestions(state, searchType)
  );

  return rawSuggestions?.data?.map(s => new T(s)) ?? [];
}

export const formatDate = (d: string | Date, forceParseISO?: boolean) => {
  if (
    d === '0000-00-00' ||
    d === '0000-00-00 00:00:00' ||
    (typeof d === 'string' && d.charAt(0) === '-')
  ) {
    return '';
  }
  // parseISO crashes if d is too long
  if (!forceParseISO && d?.toString().length > 27) {
    return format(new Date(), 'dd.MM.yyyy');
  }

  const parsedDate = parseISO(d?.toString());
  if (!isValid(parsedDate)) {
    return '';
  }
  return d
    ? format(parsedDate, 'dd.MM.yyyy')
    : format(new Date(), 'dd.MM.yyyy');
};

export const formatShortDate = (d: string | Date) => {
  if (
    d === '0000-00-00' ||
    d === '0000-00-00 00:00:00' ||
    (typeof d === 'string' && d.charAt(0) === '-')
  ) {
    return '';
  }
  // parseISO crashes if d is too long
  if (d?.toString().length > 10) {
    return format(new Date(d), 'd.M.yy');
  }

  const parsedDate = parseISO(d?.toString());
  if (!isValid(parsedDate)) {
    return '';
  }
  return d ? format(parsedDate, 'd.M.yy') : format(new Date(), 'd.M.yy');
};

export const formatMonthAndYearDate = (d: string | Date) => {
  if (
    d === '0000-00-00' ||
    d === '0000-00-00 00:00:00' ||
    (typeof d === 'string' && d.charAt(0) === '-')
  ) {
    return '';
  }
  // parseISO crashes if d is too long
  if (d?.toString().length > 10) {
    return format(new Date(d), 'MM/yyyy');
  }

  const parsedDate = parseISO(d?.toString());
  if (!isValid(parsedDate)) {
    return '';
  }
  return d ? format(parsedDate, 'MM/yyyy') : format(new Date(), 'MM/yyyy');
};

export const formatTime = (d: string | Date) => {
  if (
    d === '0000-00-00' ||
    d === '0000-00-00 00:00:00' ||
    (typeof d === 'string' && d.charAt(0) === '-')
  ) {
    return '';
  }
  const time = parseISO(d.toString());
  if (!isValid(time)) {
    return '';
  }
  return `${format(time, 'd.M.yyyy')} klo ${format(time, 'HH.mm')}`;
};

export const formatYear = (d: string | Date) => {
  if (
    d === '0000-00-00' ||
    d === '0000-00-00 00:00:00' ||
    (typeof d === 'string' && d.charAt(0) === '-')
  ) {
    return '';
  }
  const date = new Date(d);
  return format(date, 'yyyy');
};

export const formatCurrency = (n: number) => {
  if (n === undefined) return;
  return new Intl.NumberFormat('fi-FI', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format(n);
};

export const formatCurrencyToMil = (n: number) =>
  new Intl.NumberFormat('fi-FI', {
    style: 'currency',
    currency: 'EUR',
    notation: 'compact',
  }).format(n);

export const formatNumberToCompact = (n: number) =>
  new Intl.NumberFormat('fi-FI', {
    notation: 'compact',
  }).format(n);

export const addYearToDate = date => {
  return format(add(new Date(date), { years: 1 }), 'dd.MM.yyyy');
};

export const isEmail = (value: string): boolean => {
  const reg =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  return reg.test(value);
};

export const asSnakeCase = (o: Record<string, unknown>) => {
  if (o === null) {
    return null;
  }

  return Object.entries(o).reduce((obj, curr) => {
    const [key, val] = curr;
    if (
      val !== null &&
      typeof val === 'object' &&
      !(val instanceof Array) &&
      !(val instanceof File)
    ) {
      return { ...obj, [snakeCase(key)]: asSnakeCase({ ...val }) };
    } else {
      return { ...obj, [snakeCase(key)]: val };
    }
  }, {});
};

export const asCamelCase = (o: Record<string, unknown>) => {
  if (o === null || o === undefined) {
    return null;
  }

  return Object.entries(o).reduce((obj, curr) => {
    const [key, val] = curr;
    if (val !== null && typeof val === 'object' && !(val instanceof File)) {
      return { ...obj, [camelCase(key)]: asCamelCase({ ...val }) };
    } else {
      return { ...obj, [camelCase(key)]: val };
    }
  }, {});
};

export const removeEmptyKeys = (o: Record<string, unknown>) => {
  if (o === null || o === undefined) {
    return {};
  }

  return Object.entries(o).reduce((obj, curr) => {
    const [key, val] = curr;
    if (!val) {
      return obj;
    } else {
      return { ...obj, [key]: val };
    }
  }, {});
};

/*
 * Detects which is the type of given value.
 * is able to detect objects, arrays, strings and numbers.
 */
const detectValueType = value => {
  if (!value) {
    return null;
  }

  if (typeof value === 'object') {
    if (Array.isArray(value)) {
      return VALUE_TYPES.ARRAY;
    } else {
      return VALUE_TYPES.OBJECT;
    }
  } else if (typeof value === 'string' || value instanceof String) {
    return VALUE_TYPES.STRING;
  } else if (typeof value === 'number' && !isNaN(value)) {
    return VALUE_TYPES.NUMBER;
  }

  return null;
};

/*
 * Parses recursively any kind of "error" value. Can handle arrays, objects and string.
 * An array of Message objects is returned as response
 */
const parseErrorEntry = (
  entry: any,
  code: string | undefined = undefined,
  param: string | undefined = undefined
): Array<Message> => {
  const valueType: ValueType = detectValueType(entry);
  if (!valueType) {
    return [];
  }

  const errors: Array<Message> = [];

  if (valueType === VALUE_TYPES.ARRAY) {
    // Handle any kind of array of errors
    for (const item of entry) {
      errors.push(...parseErrorEntry(item, item, code));
    }
  } else if (valueType === VALUE_TYPES.OBJECT) {
    if ('message' in entry) {
      // Handle error object with message: { message: "error_message", code: "ERROR_CODE" }
      errors.push({
        type: 'error',
        text: entry.message,
        code: 'code' in entry ? entry.code : undefined,
      });
    } else {
      // Handle error object with code as key: { ERROR_CODE1: "error_message1", ERROR_CODE2: "error_message2" }
      for (const key in entry) {
        errors.push(...parseErrorEntry(entry[key], key));
      }
    }
  } else if (valueType === VALUE_TYPES.STRING) {
    // Handle error that is only a single string
    errors.push({
      type: 'error',
      text: entry,
      code: code,
      param: param && !['general', 'year'].includes(param) ? param : undefined,
    });
  }

  return errors;
};

/*
 * General function for parsing errors from backend
 */
export function parseErrors(
  errors: FetchBaseQueryError | SerializedError
): Message[] {
  if ('data' in errors && typeof errors.status === 'number') {
    const errorData = errors.data as {
      message: string;
      code?: string;
      errors?: Record<string, string[]>;
    };
    const messages: Message[] = [];

    if ('message' in errorData) {
      messages.push({
        type: 'error',
        text: errorData.message,
        code: 'code' in errorData ? errorData.code : undefined,
      });
    }

    if ('errors' in errorData) {
      const subMessages = parseErrorEntry(errorData.errors);
      messages.push(...subMessages);
    }

    return messages;
  }

  return [];
}

export function removeNulls(obj: Record<string, unknown>) {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([_, v]) => v !== null)
      .map(([k, v]) => (typeof v === 'object' ? [k, removeNulls(v)] : [k, v]))
  );
}
/**
 * Reference search selection of reference completion years
 * @returns list of objects [{ value: '{ "min": 0, "max": 1 }', label: `0 - 1 ${yearsAgoText}` }, ...]
 */
export const getReferenceSearchWorkEndYears = (yearsAgoText: string) => {
  return [
    {
      value: '{ "min": 0, "max": 1 }',
      label: `0 - 1 ${yearsAgoText}`,
    },
    {
      value: '{ "min": 1, "max": 2 }',
      label: `1 - 2 ${yearsAgoText}`,
    },
    {
      value: '{ "min": 2, "max": 3 }',
      label: `2 - 3 ${yearsAgoText}`,
    },
    {
      value: '{ "min": 3, "max": 4 }',
      label: `3 - 4 ${yearsAgoText}`,
    },
    {
      value: '{ "min": 4, "max": null }',
      label: `4 - ${yearsAgoText}`,
    },
  ];
};
