/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit';

import { COMMON, addMappingItem } from '../common';
import Company from '~models/Company';
import CompanyPerson from '~models/CompanyPerson';
import Union from '~models/Union';
import { RootState } from '~store/index';
import Address, {
  StreetAddressFields,
  billingOperators,
} from '~models/Address';
import { ADDRESS_TYPES } from '~common/enums';

const emptyAddress = { country: 'FI', street: '', zipcode: '', city: '' };

export const companiesAdapter = createEntityAdapter<Company>();

export interface FormData<
  SeparateVisiting extends boolean = boolean,
  InvoiceAddressType extends 'paper' | 'electronic' = 'paper' | 'electronic'
> {
  name: string;
  registrationNumber: string;
  managingDirectorId?: number;
  contactPersonId?: number;
  switchboardNumber: string;
  postalAddress: StreetAddressFields;
  separateVisitingAddress: SeparateVisiting;
  visitingAddress?: SeparateVisiting extends true
    ? StreetAddressFields
    : undefined;
  eInvoiceAddress: string;
  eInvoiceOperator?: keyof typeof billingOperators;
  paperInvoiceAddress: StreetAddressFields;
  invoiceAddressType: InvoiceAddressType;
  email: string;
  website: string;
  linkedin: string;
  twitter: string;
  facebook: string;
  instagram: string;
}

type ValidationErrors = Partial<
  {
    // This allows accessing country errors with (invoice|postal|visiting)Address.country
    [AddressKey in keyof Pick<
      FormData,
      'postalAddress' | 'visitingAddress' | 'paperInvoiceAddress'
    > as `${AddressKey}.country`]: string[];
  } &
    {
      [K in keyof Omit<
        FormData,
        'postalAddress' | 'visitingAddress' | 'paperInvoiceAddress'
      >]: string[];
    }
>;

interface State {
  basicFormData: FormData;
  validationErrors: ValidationErrors | null;
  peopleToCompanies: any;
  responsibilityInfo: any;
  joinRequest: any;
  loading: any;
  error: any;
  rights: any;
}

const initialState = companiesAdapter.getInitialState({
  basicFormData: {
    name: '',
    registrationNumber: '',
    switchboardNumber: '',
    invoiceAddressType: 'electronic',
    eInvoiceAddress: '',
    paperInvoiceAddress: emptyAddress,
    postalAddress: emptyAddress,
    separateVisitingAddress: false,
    email: '',
    website: '',
    linkedin: '',
    twitter: '',
    facebook: '',
    instagram: '',
  },
  validationErrors: null,
  peopleToCompanies: {},
  responsibilityInfo: {},
  joinRequest: {},
  loading: {},
  error: {},
  rights: {},
} as State);

const slice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    fetchCompany(state, action: PayloadAction<any>) {},

    receiveCompany(state, action) {
      const company = action.payload;
      companiesAdapter.upsertOne(state, new Company(company));
    },

    replaceCompanyMemberships(
      state,
      action: PayloadAction<{
        companyId: string;
        memberships: { union: Union; membershipId: string }[];
      }>
    ) {
      const { companyId, memberships } = action.payload;
      companiesAdapter.updateOne(state, {
        id: companyId,
        changes: { memberships },
      });
    },

    receiveCompanyMemberships(
      state,
      action: PayloadAction<{
        companyId: string;
        memberships: { union: Union; membershipId: string }[];
      }>
    ) {
      const { companyId, memberships } = action.payload;

      const company = state.entities[companyId]!;
      companiesAdapter.updateOne(state, {
        id: companyId,
        changes: { memberships: [...company.memberships, ...memberships] },
      });
    },

    removeCompanyMemberships(
      state,
      action: PayloadAction<{ companyId: string; membershipIds: string[] }>
    ) {
      const { companyId, membershipIds } = action.payload;

      const company = state.entities[companyId]!;
      companiesAdapter.updateOne(state, {
        id: companyId,
        changes: {
          memberships: company.memberships.filter(
            ({ membershipId }) => !membershipIds.includes(membershipId)
          ),
        },
      });
    },

    create(state, action: PayloadAction<any>) {},

    joinRequest(state, action: PayloadAction<{ companyId: string }>) {},

    receiveJoinRequest(state, action) {
      state.joinRequest = action.payload;
    },

    setCompanyData(
      state,
      action: PayloadAction<
        Company & {
          eInvoiceAddress: string;
          eInvoiceOperator: string;
        }
      >
    ) {
      const {
        name,
        switchboardNumber,
        registrationNumber,
        eInvoiceAddress,
        eInvoiceOperator,
      } = action.payload;
      state.basicFormData = {
        ...state.basicFormData,
        name,
        switchboardNumber: switchboardNumber ?? '',
        registrationNumber,
        eInvoiceAddress: eInvoiceAddress ?? '',
        eInvoiceOperator:
          (eInvoiceOperator as keyof typeof billingOperators) ?? undefined,
      };
    },

    setCompanyPeople(
      state,
      action: PayloadAction<{
        managingDirector?: CompanyPerson;
        contactPerson?: CompanyPerson;
      }>
    ) {
      const { managingDirector, contactPerson } = action.payload;
      state.basicFormData.managingDirectorId = managingDirector?.personId;
      state.basicFormData.contactPersonId = contactPerson?.personId;
    },

    // This initializes form data with addresses returned by backend
    setAddresses(state, action: PayloadAction<Address[]>) {
      const addresses = action.payload;
      const findAddressByType = (...typeIds: ADDRESS_TYPES[]) =>
        addresses?.find(({ addressTypeId }) => typeIds.includes(addressTypeId));

      // Postal address
      const postalAddressData = findAddressByType(
        ADDRESS_TYPES.POSTAL_STREET,
        ADDRESS_TYPES.POSTAL_POBOX
      );
      state.basicFormData.postalAddress = postalAddressData
        ? new Address({
            ...postalAddressData,
            country: postalAddressData.country.toLocaleUpperCase(),
          })
        : emptyAddress;

      // Visiting addess, if undefined, postal address is used
      const visitingAddressData = findAddressByType(
        ADDRESS_TYPES.VISITING_FINNISH
      );
      const visitingAddress = visitingAddressData
        ? new Address({
            ...visitingAddressData,
            country: visitingAddressData.country.toLocaleUpperCase(),
          })
        : undefined;

      if (visitingAddress) {
        state.basicFormData.visitingAddress = visitingAddress;
        if (visitingAddressData?.street === postalAddressData?.street && visitingAddressData?.city === postalAddressData?.city) {
          state.basicFormData.separateVisitingAddress = false;
        } else {
          state.basicFormData.separateVisitingAddress = true;
        }
      } else {
        state.basicFormData.separateVisitingAddress = false;
      }

      // Company should have either e-invoice address or invoice address
      const eInvoiceAddress = findAddressByType(
        ADDRESS_TYPES.BILLING_ELECTRONIC_FINNISH
      )?.electronicBillingAddress;

      const invoiceAddressData = findAddressByType(
        ADDRESS_TYPES.BILLING_FINNISH,
        ADDRESS_TYPES.BILLING_FOREIGN
      );
      const paperInvoiceAddress = invoiceAddressData
        ? new Address(invoiceAddressData)
        : undefined;

      if (eInvoiceAddress) {
        state.basicFormData.eInvoiceAddress = eInvoiceAddress;
        state.basicFormData.invoiceAddressType = 'electronic';
      } else if (paperInvoiceAddress) {
        state.basicFormData.paperInvoiceAddress = paperInvoiceAddress;
        state.basicFormData.invoiceAddressType = 'paper';
      }

      const email = findAddressByType(ADDRESS_TYPES.EMAIL)?.electronicAddress;
      const website = findAddressByType(ADDRESS_TYPES.WEB)?.electronicAddress;
      const linkedin = findAddressByType(
        ADDRESS_TYPES.LINKEDIN
      )?.electronicAddress;
      const twitter = findAddressByType(
        ADDRESS_TYPES.TWITTER
      )?.electronicAddress;
      const facebook = findAddressByType(
        ADDRESS_TYPES.FACEBOOK
      )?.electronicAddress;
      const instagram = findAddressByType(
        ADDRESS_TYPES.INSTAGRAM
      )?.electronicAddress;

      state.basicFormData.email = email ?? '';
      state.basicFormData.website = website ?? '';
      state.basicFormData.linkedin = linkedin ?? '';
      state.basicFormData.twitter = twitter ?? '';
      state.basicFormData.facebook = facebook ?? '';
      state.basicFormData.instagram = instagram ?? '';
    },

    setInvoiceAddressType(
      state,
      action: PayloadAction<'electronic' | 'paper'>
    ) {
      state.basicFormData = {
        ...state.basicFormData,
        invoiceAddressType: action.payload,
      };
    },

    setSeparateVisitingAddress(state, action: PayloadAction<boolean>) {
      const separateVisitingAddress = action.payload;
      state.basicFormData = {
        ...state.basicFormData,
        separateVisitingAddress,
        visitingAddress: separateVisitingAddress ? emptyAddress : undefined,
      };
    },

    setFormField(
      state,
      action: PayloadAction<
        Omit<
          Partial<FormData>,
          | 'invoiceAddressType'
          | 'separateVisitingAddress'
          | 'registrationNumber'
        >
      >
    ) {
      state.basicFormData = { ...state.basicFormData, ...action.payload };
    },

    setValidationErrors(state, action: PayloadAction<ValidationErrors | null>) {
      state.validationErrors = action.payload;
    },

    loading(state, action) {
      const type = action.payload;
      state.loading[type] = true;
      state.error[type] = null;
    },

    success(state, action) {
      const type = action.payload;
      state.loading[type] = false;
      state.error[type] = null;
    },

    error(state, action) {
      const { type, error } = action.payload;
      state.loading[type] = false;
      state.error[type] = error;
    },
  },
  extraReducers: builder => {
    builder.addCase(COMMON.NAVIGATE, state => {
      Object.keys(state.error).forEach(k => {
        state.error[k] = null;
      });
    })
  },
});

const adapterSelectors = companiesAdapter.getSelectors(
  (state: RootState) => state.companies
);

export const selectors = {
  company: (state, companyId) => adapterSelectors.selectById(state, companyId),
  joinRequest: state => state.companies.joinRequest,
  companyRights: (state, personId?: number) =>
    personId !== undefined && personId !== null
      ? state.companies.rights[personId]
        ? state.companies.rights[personId]
        : []
      : [],
  basicFormData: (state: RootState) => state.companies.basicFormData,
  validationErrors: (state: RootState) => state.companies.validationErrors,
  loading: (state, key?) =>
    key ? Boolean(state.companies.loading[key]) : state.companies.loading,
  error: (state, key?) =>
    key ? state.companies.error[key] || null : state.companies.error,
};

export default slice.reducer;

export const { actions } = slice;
