import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import format from 'date-fns/format';

import { RootState } from '~store/index';

type Modal = 'listing' | 'createCompanyHistory' | 'editCompanyHistory';
type ValidationErrors = Partial<{ [key in keyof FormData]: string[] }>;
interface State {
  modalState: Modal;
  formData: {
    validFrom: string;
    validTo: string;
    content: string;
    companyHistoryEntryTypeId: number | null;
  };
  historyId: number | null;
  validationErrors: ValidationErrors | null;
}

const companyHistoryAdapter = createEntityAdapter<void>();

const initialState = companyHistoryAdapter.getInitialState({
  modalState: 'listing',
  formData: {
    validFrom: format(new Date(), 'yyyy-MM-dd'),
    validTo: '',
    content: '',
    companyHistoryEntryTypeId: null,
  },
  historyId: null,
  validationErrors: null,
} as State);

const slice = createSlice({
  name: 'companyHistory',
  initialState,
  reducers: {
    setFormDataField(state, action: PayloadAction<any>) {
      state.formData = { ...state.formData, ...action.payload };
    },
    setHistoryId(state, action: PayloadAction<number | null>) {
      state.historyId = action.payload;
    },
    setModalState(state, action: PayloadAction<Modal>) {
      state.modalState = action.payload;
      if (action.payload === 'listing') {
        state.formData = initialState.formData;
        state.historyId = initialState.historyId;
        state.validationErrors = initialState.validationErrors;
      }
    },
    setValidationErrors(state, action: PayloadAction<ValidationErrors | null>) {
      state.validationErrors = action.payload;
    },
  },
});

export const { actions } = slice;
export default slice.reducer;

export const selectors = {
  formData: (state: RootState) => state.companyHistory.formData,
  modalState: (state: RootState) => state.companyHistory.modalState,
  historyId: (state: RootState) => state.companyHistory.historyId,
  validationErrors: state => state.companyHistory.validationErrors,
};
