import api from '../api';

export function fetchRelationship(companyId, relationshipId) {
  return api.get(`/companies/${companyId}/relationships/${relationshipId}`);
}

export function relationshipsSearch(companyId, params) {
  return api.get(`/companies/${companyId}/relationships`, { params });
}

export function createRelationship({ companyId, data }) {
  return api.post(`/companies/${companyId}/relationships`, data);
}

export function destroyRelationship({ companyId, relationshipId }) {
  return api.delete(`/companies/${companyId}/relationships/${relationshipId}`);
}
