import { lazy, Suspense } from 'react';
import { createRoot } from 'react-dom/client'
import * as Sentry from '@sentry/react';

import { Provider } from 'react-redux';

import Countries from 'i18n-iso-countries';
import countriesFI from 'i18n-iso-countries/langs/fi.json';
import countriesSV from 'i18n-iso-countries/langs/sv.json';
import countriesEN from 'i18n-iso-countries/langs/en.json';
import {
  Route,
  Routes,
  unstable_HistoryRouter as HistoryRouter,
} from 'react-router-dom';

import '@popperjs/core';
import 'bootstrap';

import store, { history } from './store';
import Spinner from '~common/Spinner';
import config from '~/config';

// Await is required to make sure external styles are applied after bootstrap to
// prevent it from overwriting them
await import('bootstrap/scss/bootstrap.scss');
await import('assets/scss/styles.scss');

const App = lazy(() => import('features/app'));
const Audit = lazy(() => import('features/audit'));
const Board = lazy(() => import('features/board'));
const Office = lazy(() => import('features/office'));
const CompanyRestructuring = lazy(
  () => import('features/companyRestructuring')
);

if (config.PROD) {
  Sentry.init({
    dsn: 'https://64887db5504c27966198dbbc3c999ca3@o4506709655945216.ingest.sentry.io/4506712081956864',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: 1.0, //  Capture 100% of the transactions, TODO: adjust lower for production?
    tracePropagationTargets: ['localhost', 'https://asiointi-api.rala.fi'],
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

Countries.registerLocale(countriesFI);
Countries.registerLocale(countriesSV);
Countries.registerLocale(countriesEN);

const container = document.getElementById('app');
const root = createRoot(container!);
root.render(
  <Provider store={store}>
    <HistoryRouter history={history}>
      <Suspense fallback={<Spinner />}>
        <Routes>
          <Route path="/audit/*" element={<Audit />} />
          <Route path="/board/*" element={<Board />} />
          <Route path="/office/*" element={<Office />} />
          <Route
            path="/company-restructuring/*"
            element={<CompanyRestructuring />}
          />
          <Route path="/*" element={<App />} />
        </Routes>
      </Suspense>
    </HistoryRouter>
  </Provider>
);
