import axios from 'axios';
import config from '../config';
import { actions as userActions } from '~store/user';

export class APIError extends Error {
  constructor(status, data) {
    super();
    this.status = status;
    this.data = data;
    this.errors = data.errors;
  }
}

let store; // eslint-disable-line no-unused-vars

const api = axios.create({
  baseURL: config.API_URL,
  withCredentials: true,
});

api.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    if (error.response.status === 401) {
      console.debug(`Unauthorized request to ${error.response.config.url}`);
      store.dispatch(userActions.logout());
    }
    if (!error.response) throw error;
    throw new APIError(error.response.status, error.response.data);
  }
);

export const applyStoreToApi = s => {
  store = s;
};

export default api;
