import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import Application from '~models/Application';
import ApplicationIndustry from '~models/ApplicationIndustry';
import Reference from '~models/CompanyReference';

type PageState = 'listing' | 'editing' | 'deleting';
type IndustryState =
  | 'listing'
  | 'adding-industry'
  | 'editing-industry'
  | 'creating-reference';
type ReferenceState = 'listing' | 'creating' | 'showing';

interface State {
  pageState: PageState;
  industryState: IndustryState;
  selectedIndustry: ApplicationIndustry | null;
  referenceState: ReferenceState;
  selectedReference: Reference | null;
  deletingId: number | null;
  editingId: number | null;
  loading: any;
  error: any;
}

const qualificationApplicationAdapter = createEntityAdapter<Application>();

const initialState = qualificationApplicationAdapter.getInitialState({
  pageState: 'listing',
  industryState: 'listing',
  selectedIndustry: null,
  referenceState: 'listing',
  selectedReference: null,
  deletingId: null,
  editingId: null,
  loading: {},
  error: {},
} as State);

const slice = createSlice({
  name: 'qualificationApplications',
  initialState,
  reducers: {
    setPageState(
      state,
      action: PayloadAction<
        | { deleting: number }
        | { editing: number }
        | Exclude<PageState, 'deleting' | 'editing'>
      >
    ) {
      const pageState = action.payload;

      state.deletingId = null;
      state.editingId = null;
      state.industryState = 'listing';

      if (typeof pageState === 'string') {
        state.pageState = pageState;
      } else {
        if ('deleting' in pageState) {
          state.pageState = 'deleting';
          state.deletingId = pageState.deleting;
        } else if ('editing' in pageState) {
          state.pageState = 'editing';
          state.editingId = pageState.editing;
        }
      }
    },

    setIndustryState(state, action: PayloadAction<IndustryState>) {
      const industryState = action.payload;

      if (industryState === 'listing') {
        state.selectedIndustry = new ApplicationIndustry({});
      }

      state.industryState = action.payload;
    },

    editIndustry(state, action: PayloadAction<ApplicationIndustry>) {
      state.industryState = 'editing-industry';
      state.selectedIndustry = action.payload;
    },

    setReferenceState(state, action: PayloadAction<ReferenceState>) {
      state.referenceState = action.payload;
    },

    showReference(state, action: PayloadAction<Reference>) {
      state.referenceState = 'showing';
      state.selectedReference = action.payload;
    },

    closeReferenceModal(state) {
      state.referenceState = 'listing';
      state.selectedReference = null;
    },

    setSelectedReference(state, action: PayloadAction<Reference | null>) {
      state.selectedReference = action.payload;
    },

    setSelectedIndustry(state, action: PayloadAction<ApplicationIndustry | null>) {
      state.selectedIndustry = action.payload;
    },
  },
});

export const { actions } = slice;
export default slice.reducer;

const adapterSelectors = qualificationApplicationAdapter.getSelectors(
  (state: any) => state.qualificationApplications
);

export const selectors = {
  pageState: state => state.qualificationApplications.pageState,
  industryState: state => state.qualificationApplications.industryState,
  selectedIndustry: state => state.qualificationApplications.selectedIndustry,
  referenceState: state => state.qualificationApplications.referenceState,
  selectedReference: state => state.qualificationApplications.selectedReference,
  editingId: state => state.qualificationApplications.editingId,
  deletingId: state => state.qualificationApplications.deletingId,
};
