/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit';

import CertificateMeeting from '~/models/CertificateMeeting';

type FormState =
  | 'listing'
  | 'creating'
  | 'editing'
  | 'editingAllDayEvent'
  | 'deleting';
interface FormData {
  id: number | undefined;
  startDate: string | undefined;
  startTime: string | undefined;
  endDate: string | undefined;
  endTime: string | undefined;
  location: string;
  remoteMeetingUrl: string;
  title: string;
  remoteMeeting: boolean | undefined;
  description: string;
  auditProgramId?: string | null;
}
interface State {
  formState: FormState;
  formData: FormData;
}

const evaluationCalendarAdapter = createEntityAdapter<CertificateMeeting>();

const initialState = evaluationCalendarAdapter.getInitialState({
  formState: 'listing',
  formData: {
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
    location: '',
    remoteMeetingUrl: '',
    title: '',
    description: '',
    remoteMeeting: false,
  },
} as State);

const slice = createSlice({
  name: 'evaluationCalendar',
  initialState,
  reducers: {
    setFormState(state, action) {
      state.formState = action.payload;
    },
    setFormDataField(state, action: PayloadAction<Partial<FormData>>) {
      state.formData = { ...state.formData, ...action.payload };
    },
    clearFormData(state) {
      state.formData = initialState.formData;
    },
  },
});

export const { actions } = slice;
export default slice.reducer;

const adapterSelectors = evaluationCalendarAdapter.getSelectors(
  (state: any) => state.evaluationCalendar
);

export const selectors = {
  formState: state => state.evaluationCalendar.formState,
  formData: state => state.evaluationCalendar.formData,
};
