/* eslint-disable import/prefer-default-export */
import api from '../api';

export function create(companyId, application) {
  const data = new FormData();
  const { files, application_area_codes, ...rest } = application;
  if (files !== undefined) {
    for (const f of files) {
      data.append('file[][file]', f.f);
      data.append('file[][file_type_id]', f.file_type_id);
    }
  }
  if (application_area_codes !== undefined) {
    for (const code of application_area_codes) {
      data.append('application_area_codes[]', code);
    }
  }
  for (const [key, val] of Object.entries(rest)) {
    data.append(key, val);
  }
  // FormData can't be sent with PUT and files require using FormData.
  // Fortunately Laravel understands setting the method from payload.
  // If files and area codes are undefined the _method POST does not work
  // so we have to send data as json object.
  data.append('_method', 'POST');
  return api.post(
    `/companies/${companyId}/applications`,
    files !== undefined && application_area_codes !== undefined
      ? data
      : application
  );
}

export function fetch({ companyId, applicationId }) {
  return api.get(`/companies/${companyId}/applications/${applicationId}`);
}

export function fetchApplicationTargets({ companyId }) {
  return api.get(`/companies/${companyId}/application-targets`);
}

export function fetchAreaCodes() {
  return api.get(`/application-area-codes`);
}

export function fetchApplicationAreaCodes(companyId, applicationId) {
  return api.get(
    `/companies/${companyId}/applications/${applicationId}/area-codes`
  );
}

export function update(companyId, applicationId, application) {
  const data = new FormData();
  const { files, application_area_codes, ...rest } = application;

  if (files !== undefined && files.length > 0) {
    for (const f of files) {
      data.append('file[][file]', f.f);
      data.append('file[][file_type_id]', f.file_type_id);
    }
  }

  if (
    application_area_codes !== undefined &&
    application_area_codes.length > 0
  ) {
    for (const code of application_area_codes) {
      data.append('application_area_codes[]', code);
    }
  }

  for (const [key, val] of Object.entries(rest)) {
    data.append(key, val);
  }
  // FormData can't be sent with PUT and files require using FormData.
  // Fortunately Laravel understands setting the method from payload.
  // If files and area codes are undefined the _method PUT does not work
  // so we have to send data as json object.
  data.append('_method', 'PUT');
  if (files !== undefined || application_area_codes !== undefined) {
    return api.post(
      `/companies/${companyId}/applications/${applicationId}`,
      data
    );
  } else {
    return api.put(
      `/companies/${companyId}/applications/${applicationId}`,
      application
    );
  }
}

export function cancel(companyId, applicationId) {
  return api.post(
    `/companies/${companyId}/applications/${applicationId}/cancel`
  );
}

export function destroy(companyId, applicationId) {
  return api.delete(`/companies/${companyId}/applications/${applicationId}`);
}

export function search(companyId, params) {
  return api.get(`/companies/${companyId}/applications`, { params });
}

export function linkAreaCode(companyId, applicationId, data) {
  return api.post(
    `/companies/${companyId}/applications/${applicationId}/area-codes`,
    data
  );
}

export function unlinkAreaCode(
  companyId,
  applicationId,
  applicationAreaCodeId
) {
  return api.delete(
    `/companies/${companyId}/applications/${applicationId}/area-codes/${applicationAreaCodeId}`
  );
}

export function validate(companyId, applicationId) {
  return api.get(
    `/companies/${companyId}/applications/${applicationId}/validate`
  );
}

export function send(companyId, applicationId) {
  return api.post(`/companies/${companyId}/applications/${applicationId}/send`);
}
