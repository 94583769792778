import camelCase from 'lodash/camelCase';
import Person from './Person';

export default class CertificateAuditCost {
  public readonly id: number;
  public readonly certificateAuditProgramId: number;
  public readonly personId: number;
  public readonly preparationHours: number;
  public readonly preparationDate: number;
  public readonly siteHours: number;
  public readonly siteDate: string;
  public readonly reportHours: number;
  public readonly reportDate: number;
  public readonly travellingHours: number;
  public readonly distanceDriven: number;
  public readonly otherTravellingCosts: number;
  public readonly perDiem: number;
  public readonly lodgingCosts: number;
  public readonly createdAt: string;
  public readonly updatedAt: string;
  public readonly deletedAt: string;
  public readonly perDiemPcs: number;
  public readonly halfPerDiemPcs: number;
  public readonly person: Person | null;

  constructor(data) {
    for (const k of Object.keys(data)) {
      this[camelCase(k)] = data[k];
    }

    this.person = this.person ? new Person(this.person) : null;
  }
}
