import camelCase from 'lodash/camelCase';
import { format } from 'date-fns';
import ApplicationSource from './ApplicationSource';
import ApplicationStatus from './ApplicationStatus';
import ApplicationTarget from './ApplicationTarget';
import Person from './Person';
import AuditorUser from './AuditorUser';
import ApplicationAreaCode from './ApplicationAreaCode';
import CompanyOffice from './CompanyOffice';
import ApplicationIndustry from './ApplicationIndustry';
import Description from './Description';
import Company from './Company';
import BoardMeeting from './BoardMeeting';
import CertificateAuditProgram from './CertificateAuditProgram';
import {
  APPLICATION_FILE_TYPES,
  APPLICATION_STATUS,
} from '~features/common/enums';
import { formatShortDate } from '~/features/common/helpers';

export interface FileStruct {
  id: string;
  file: any;
  fileName: string;
}

export default class Application {
  public readonly id: number;
  public readonly companyId: number;
  public readonly applicationTargetId: number;
  public readonly applicationSourceId: number;
  public readonly applicationStatusId: number;
  public readonly auditorUser: AuditorUser;
  public readonly contactPersonId: number;
  public readonly ralaResponsiblePersonId: number;
  public readonly boardMeetingId: number;
  public readonly industryDescription: string;
  public readonly vastuuGroupAgreement: boolean;
  public readonly contractAgreement: boolean;
  public readonly openedAt: string;
  public readonly sentAt: string;
  public readonly finishedAt: string;
  public readonly createdAt: string;
  public readonly updatedAt: string;
  public readonly deletedAt: string;
  public readonly applicationAreaCodes?: ApplicationAreaCode[];
  public readonly target?: ApplicationTarget;
  public readonly source?: ApplicationSource;
  public readonly status?: ApplicationStatus;
  public readonly applicationCompanyOffices?: CompanyOffice[];
  public readonly applicationIndustries?: ApplicationIndustry[];
  public readonly applicationScopes?: Description[];
  public readonly contactPerson?: Person;
  public readonly ralaResponsiblePerson?: Person;
  public readonly possibleStatuses?: ApplicationStatus[];
  public readonly liabilityFiles: FileStruct[];
  public readonly consultingFiles: FileStruct[];
  public readonly qualityManualFiles: FileStruct[];
  public readonly otherQualityFiles: FileStruct[];
  public readonly files: FileStruct[] | [];
  public readonly company: Company;
  public readonly boardMeeting: BoardMeeting | null;
  public readonly boardMeetings: BoardMeeting[];
  public readonly attachmentsToDelete: string[] | [];
  public readonly industriesCount: number;
  public readonly referencesCount: number;
  public readonly handlerInitials: string | null;
  public readonly auditorInitials: string | null;
  public readonly certificateAuditProgram: CertificateAuditProgram | null;
  public readonly certificateAuditProgramId: number | null;
  public readonly isInReportStage: boolean;
  public readonly certificateAuditProgramStatusId: number | null;

  constructor(data) {
    Object.keys(data).forEach(k => {
      this[camelCase(k)] = data[k];
    });
    this.target = this.target ? new ApplicationTarget(this.target) : undefined;
    this.source = this.source ? new ApplicationSource(this.source) : undefined;
    this.status = this.status ? new ApplicationStatus(this.status) : undefined;
    this.contactPerson = this.contactPerson
      ? new Person(this.contactPerson)
      : undefined;
    this.ralaResponsiblePerson = this.ralaResponsiblePerson
      ? new Person(this.ralaResponsiblePerson)
      : undefined;
    this.possibleStatuses =
      this?.possibleStatuses?.map(status => new ApplicationStatus(status)) ||
      [];
    this.applicationAreaCodes =
      this?.applicationAreaCodes?.map(a => new ApplicationAreaCode(a)) || [];
    this.liabilityFiles = this?.files?.filter(
      f => f.file_types[0].id === APPLICATION_FILE_TYPES.LIABILITY_INSURANCE
    );
    this.consultingFiles = this?.files?.filter(
      f => f.file_types[0].id === APPLICATION_FILE_TYPES.CONSULTING_INSURANCE
    );
    this.qualityManualFiles = this?.files?.filter(
      f => f.file_types[0].id === APPLICATION_FILE_TYPES.QUALITY_MANUAL
    );
    this.otherQualityFiles = this?.files?.filter(
      f => f.file_types[0].id === APPLICATION_FILE_TYPES.OTHER_QUALITY_MANUAL
    );
    this.applicationCompanyOffices =
      this?.applicationCompanyOffices?.map(o => new CompanyOffice(o)) || [];
    this.applicationIndustries =
      this?.applicationIndustries?.map(o => new ApplicationIndustry(o)) || [];
    this.applicationScopes =
      this?.applicationScopes?.map(o => new Description(o)) || [];
    this.company = this.company ? new Company(this.company) : this.company;
    this.boardMeeting = this.boardMeeting
      ? new BoardMeeting(this.boardMeeting)
      : null;
    this.boardMeetings =
      this?.boardMeetings?.map(bm => new BoardMeeting(bm)) || [];

    this.certificateAuditProgram = this.certificateAuditProgram
      ? new CertificateAuditProgram(this.certificateAuditProgram)
      : null;

    this.auditorUser = this.auditorUser
      ? new AuditorUser(this.auditorUser)
      : undefined;
  }

  get createdAtDate() {
    return format(new Date(this.createdAt), 'dd.MM.yyyy');
  }

  get finishedAtDate() {
    return format(new Date(this.finishedAt), 'dd/MM/yyyy HH:mm');
  }

  get canReview() {
    return [
      APPLICATION_STATUS.SENT,
      APPLICATION_STATUS.RETURNED,
      APPLICATION_STATUS.CANCELED,
    ].includes(this.applicationStatusId);
  }

  get canAssign() {
    return this.applicationStatusId !== APPLICATION_STATUS.ARCHIVED;
  }

  get boardMeetingsAsStringList() {
    return this.boardMeetings
      .map(bm => `${bm.title} (${formatShortDate(bm.meetingTime)})`)
      .join(', ');
  }

  get lastBoardMeetingNameAndMeetingTime() {
    const bm = this.boardMeetings[0];
    return bm ? `${bm.title} (${formatShortDate(bm.meetingTime)})` : '';
  }
}
