import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit';
import BoardMeetingIndustryResolution from '~/models/BoardMeetingIndustryResolution';
import ClassificationIndustry from '~/models/ClassificationIndustry';
import IndustryRequirement from '~/models/IndustryRequirement';
import ReferenceResolution from '~/models/ReferenceResolution';
import ApplicationIndustry from '~models/ApplicationIndustry';
interface FormData {
  mainIndustry: number;
  subIndustry: number;
  subSubIndustry: number;
  selectedIndustry: number | null;
  selectedReferences: number[];
  referencesToDelete: number[];
  industryRequirement: IndustryRequirement | null;
  industryHelpText: string | null;
  industry: BoardMeetingIndustryResolution | ClassificationIndustry | null;
}
type ValidationErrors = Partial<{ [key in keyof FormData]: string[] }>;
type ShowModal =
  | 'listing'
  | 'referenceModal'
  | 'deleteModal'
  | 'changeIndustryModal'
  | 'deleteReferencesModal'
  | 'copyReferencesModal';
interface State {
  industriesFormData: Partial<FormData>;
  showModal: ShowModal;
  selectedReferenceResolution: ReferenceResolution | null;
  validationErrors: ValidationErrors | null;
}
const applicationIndustryAdapter = createEntityAdapter<ApplicationIndustry>();

const initialState = applicationIndustryAdapter.getInitialState({
  industriesFormData: {
    selectedReferences: [],
    referencesToDelete: [],
    industryRequirement: null,
    industryHelpText: '',
    industry: null,
  },
  selectedReferenceResolution: null,
  showModal: 'listing',
  validationErrors: null,
} as State);

const slice = createSlice({
  name: 'applicationIndustries',
  initialState,
  reducers: {
    setIndustryFormData(state, action: PayloadAction<Partial<FormData>>) {
      state.industriesFormData = {
        ...state.industriesFormData,
        ...action.payload,
      };
    },
    setShowModal(state, action: PayloadAction<ShowModal>) {
      state.showModal = action.payload;
    },
    clearIndustryFormData(state) {
      state.industriesFormData = initialState.industriesFormData;
      state.showModal = 'listing';
    },
    setSelectedReference(
      state,
      action: PayloadAction<ReferenceResolution | null>
    ) {
      state.selectedReferenceResolution = action.payload;
    },
    setValidationErrors(state, action: PayloadAction<ValidationErrors | null>) {
      state.validationErrors = action.payload;
    },
  },
});

export const { actions } = slice;
export default slice.reducer;

const adapterSelectors = applicationIndustryAdapter.getSelectors(
  (state: any) => state.applicationIndustries
);

export const selectors = {
  industriesFormData: state => state.applicationIndustries.industriesFormData,
  showModal: state => state.applicationIndustries.showModal,
  selectedReferenceResolution: state =>
    state.applicationIndustries.selectedReferenceResolution,
  validationErrors: state => state.applicationIndustries.validationErrors,
};
