import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import RalaNewsFeedItem from '~/models/RalaNewsFeedItem';

import { RootState } from '~store/index';

type Modal = 'listing' | 'businessModal' | 'intraModal';
interface State {
  showModal: Modal;
  selectedNews: RalaNewsFeedItem | null;
}

const imageCarouselAdapter = createEntityAdapter<void>();

const initialState = imageCarouselAdapter.getInitialState({
  showModal: 'listing',
  selectedNews: null,
} as State);

const slice = createSlice({
  name: 'frontPageNews',
  initialState,
  reducers: {
    selectNews(
      state,
      action: PayloadAction<{ news: RalaNewsFeedItem | null; modal: Modal }>
    ) {
      state.selectedNews = action.payload.news;
      state.showModal = action.payload.modal;
    },
    closeModal(state) {
      state.showModal = 'listing';
      state.selectedNews = null;
    },
  },
});

export const { actions } = slice;
export default slice.reducer;

export const selectors = {
  selectedNews: (state: RootState) => state.frontPageNews.selectedNews,
  showModal: (state: RootState) => state.frontPageNews.showModal,
};
