/* eslint-disable import/prefer-default-export */
import api from '../api';
import config from '~/config';
import { removeEmptyKeys } from '~common/helpers';

export function fetchCurrentUser() {
  return api.get('/users/me');
}

export function login({ email, password }) {
  return api.post('/auth/login', { email, password });
}

export function logout() {
  return api.post('/auth/logout');
}

export function register({
  email,
  firstName,
  lastName,
  password,
  passwordConfirmation,
  newsletter,
  invite_token,
}) {
  return api.post(
    '/auth/register',
    removeEmptyKeys({
      email,
      first_name: firstName,
      last_name: lastName,
      password,
      password_confirmation: passwordConfirmation,
      newsletter: newsletter,
      invite_token: invite_token || null,
    })
  );
}

export function verify({ token }) {
  window.location.href = `${config.API_URL}/auth/verify?token=${token}`;
}

export function verifyEmail({ userID, hash, signature, expires }) {
  return api.post(`/auth/email/verify/${userID}/${hash}`, null, {
    params: { signature, expires },
  });
}

export function resetPasswordRequest({ email }) {
  return api.post(`/auth/password/email`, { email });
}

export function resetPassword({ email, token, newPassword, confirmPassword }) {
  return api.post(`/auth/password/reset`, {
    email,
    token,
    password: newPassword,
    password_confirmation: confirmPassword,
  });
}

export function confirmEmail({ signature, expires, id, hash }) {
  return api.get(`/auth/email/verify/${id}/${hash}`, {
    params: { signature, expires },
  });
}
