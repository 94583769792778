import { all } from 'redux-saga/effects';
import { addressesSaga } from './addresses';
import { applicationsSaga } from './applications';
import { companiesSaga } from './companies';
import { companyPeopleSaga } from './companyPeople';
import { companyAgreementsSaga } from './companyAgreements';
import { initSaga } from './init';
import { inviteSaga } from './invites';
import { messagesSaga } from './messages';
import { peopleSaga } from './people';
import { relationsSaga } from './relations';
import { suggestionsSaga } from './suggestions';
import { toastSaga } from './toaster';
import { userSaga } from './user';
import { certificatesSaga } from './certificates';
import { unionsSaga } from './unions';
import { financesSaga } from './finances';
import { financeFileSaga } from './financeFiles';
import { descriptionsSaga } from './descriptions';
import { agreementsSaga } from './agreements';
import { productsSaga } from './products';
import { applicationFileSaga } from './applicationFiles';
import { subsidiariesSaga } from './subsidiaries';

export default function* rootSaga() {
  yield all([
    addressesSaga(),
    applicationsSaga(),
    companiesSaga(),
    companyPeopleSaga(),
    companyAgreementsSaga(),
    initSaga(),
    inviteSaga(),
    messagesSaga(),
    peopleSaga(),
    relationsSaga(),
    suggestionsSaga(),
    toastSaga(),
    userSaga(),
    certificatesSaga(),
    unionsSaga(),
    financesSaga(),
    financeFileSaga(),
    descriptionsSaga(),
    agreementsSaga(),
    productsSaga(),
    applicationFileSaga(),
    subsidiariesSaga(),
  ]);
}
