/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createAction } from 'redux-actions';

export const addItem = (item, items) => {
  const index = items.findIndex(i => i.id === item.id);

  // eslint-disable-next-line no-param-reassign
  if (index !== -1) items[index] = item;
  else items.push(item);
};

export const removeItem = (itemId, items) => {
  const index = items.findIndex(i => i.id === itemId);
  if (index !== -1) items.splice(index, 1);
};

export const replaceCertificateFiles = (
  certificates,
  certificateId,
  newFiles
) => {
  const certificate = certificates.find(({ id }) => id === certificateId);
  certificate.files = newFiles;

  return [
    ...certificates.filter(({ id }) => id !== certificate.id),
    certificate,
  ];
};

export const receiveCertificateFiles = (
  certificates,
  certificateId,
  newFiles
) => {
  const certificate = certificates.find(({ id }) => id === certificateId);
  certificate.files = [...certificate.files, ...newFiles];

  return [
    ...certificates.filter(({ id }) => id !== certificate.id),
    certificate,
  ];
};

export const addMappingItem = (mapping, key, item) => {
  // eslint-disable-next-line no-param-reassign
  if (!mapping[key]) mapping[key] = [];

  const index = mapping[key].findIndex(i => i === item.id);
  if (index === -1) mapping[key].push(item.id);
};

export const removeMappingItem = (mapping, itemId) => {
  Object.keys(mapping).forEach(key => {
    // eslint-disable-next-line no-param-reassign
    mapping[key] = mapping[key].filter(i => i !== itemId);
  });
};

export const COMMON = {
  NAVIGATE: 'COMMON/NAVIGATE',
};

export const actions = {
  onNavigate: createAction(COMMON.NAVIGATE),
};
