import camelCase from 'lodash/camelCase';

export default class Education {
  public readonly id: number;
  public readonly educationLevelId: number;
  public readonly longDescription: string;
  public readonly shortDescription: string;
  public readonly helpText: string | null;

  constructor(data) {
    Object.keys(data).forEach(k => {
      this[camelCase(k)] = data[k];
    });
  }
}
