import api from '../api';

export interface SendInvitationRequest {
  email: string;
  company_admin: boolean;
  company_managing_director?: boolean;
  company_contact_person?: boolean;
  company_person_right_type_ids: number[];
}

export function search(companyId: string, params = {}) {
  return api.get(`/companies/${companyId}/invites`, { params });
}

export function send(companyId: string, invite: SendInvitationRequest) {
  return api.post(`/companies/${companyId}/invites`, invite);
}

export function accept(companyId: string, token: string) {
  return api.post(`/companies/${companyId}/invites/${token}/accept`);
}

export function remove(companyId: string, token: string) {
  return api.delete(`/companies/${companyId}/invites/${token}`);
}

export function validate(companyId: string, token: string) {
  return api.get(`/invites/${token}/validate`);
}
