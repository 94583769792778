/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit';

import CompanyOffice from '~models/CompanyOffice';
import { RootState } from '~store/index';
interface State {
  officeData: Partial<CompanyOffice> | null;
  showModal: 'edit' | 'create' | 'delete' | 'listing';
}
const companyOfficesAdapter = createEntityAdapter<CompanyOffice>();
const initialState = companyOfficesAdapter.getInitialState({
  officeData: null,
  showModal: 'listing',
} as State);

const slice = createSlice({
  name: 'companyOffices',
  initialState,
  reducers: {
    setOfficeField(state, action: PayloadAction<any>) {
      state.officeData = {
        ...state.officeData,
        ...action.payload,
      };
    },
    setShowModal(state, action: PayloadAction<any>) {
      state.showModal = action.payload;
    },
    clearOfficeData(state) {
      state.officeData = initialState.officeData;
      state.showModal = initialState.showModal;
    },
  },
});

export const { actions } = slice;
export default slice.reducer;

export const selectors = {
  officeData: (state: RootState) => state.companyOffices.officeData,
  showModal: (state: RootState) => state.companyOffices.showModal,
};
