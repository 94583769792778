import {
  createApi,
  fetchBaseQuery,
  FetchArgs,
  FetchBaseQueryError,
  BaseQueryFn,
} from '@reduxjs/toolkit/query/react';
import Cookies from 'universal-cookie';

import config from '../config';
import { actions as userActions } from '~store/user';
import { actions as errorActions } from '~store/errors';

export interface ApiResponse {
  data: any[];
  links: any;
  meta: {
    current_page: number;
    last_page: number;
    per_page: number;
    total: number;
  };
  current_page: number;
  last_page: number;
  per_page: number;
  total: number;
}

export interface PaginatedResponse<T> {
  currentPage: number;
  perPage: number;
  total: number;
  totalPages: number;
  data: T[];
}

const baseQuery = fetchBaseQuery({
  baseUrl: config.API_URL,
  credentials: 'include',
  prepareHeaders: headers => {
    const cookies = new Cookies();
    const csrf = cookies.get('XSRF-TOKEN');

    // CSRF token needs to be set manually because fetchBaseQuery
    // uses fetch in the background, not axios
    headers.set('X-XSRF-TOKEN', csrf);

    return headers;
  },
  paramsSerializer: params => {
    // if object has array property, it will be combined to the resulting string
    // this can be used to get proper query params for arrays (key[]=1&key[]=2...)
    const paramsString = Object.entries(params).reduce(
      (arr: string[], curr) => {
        const [key, val] = curr;
        if (val === undefined || val === null) return arr;

        if (val instanceof Array) {
          return [
            ...arr,
            ...val.reduce((a, c) => {
              a.push(`${key}[]=${c}`);
              return a;
            }, []),
          ];
        }

        arr.push(`${key}=${val}`);
        return arr;
      },
      []
    );

    return paramsString.join('&');
  },
});

const extendedBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    console.debug(`Unauthorized request to ${result.meta?.request.url}`);
    api.dispatch(userActions.logout());
  }
  if (
    result.error &&
    (result.error.status === 400 ||
      result.error.status === 403 ||
      result.error.status === 409 ||
      result.error.status === 422)
  ) {
    api.dispatch(errorActions.set(result.error));
  }
  if (
    result?.error?.status === 'PARSING_ERROR' &&
    (result.error.originalStatus === 500 || result.error.originalStatus === 503)
  ) {
    api.dispatch(errorActions.setErrorPage(result.error.data));
  }

  return result;
};

export const monoApi = createApi({
  reducerPath: 'monoApi',
  baseQuery: extendedBaseQuery,
  tagTypes: [
    'Company',
    'ExternalCertificate',
    'Reference',
    'Project',
    'CompanyOffice',
    'Subsidiary',
    'Application',
    'ApplicationQuestionaire',
    'CurrentUser',
    'DistributionLists',
    'InspectionsSteps',
    'ResetPassword',
    'Qualification',
    'CompanyPerson',
    'Permissions',
    'Person',
    'Education',
    'ResponsibilityInfo',
    'CompanyAddresses',
    'CertificateAuditPlans',
    'AccountingPeriod',
    'ApplicationIndustries',
    'CompanyAgreements',
    'QualificationValidation',
    'CompanyFiles',
    'CompanyAssets',
    'BoardMembers',
    'BoardMeetingMembers',
    'BoardMeeting',
    'BoardMeetingResolutionList',
    'CSRF',
    'BoardMeetingFiles',
    'BoardMeetingCases',
    'CompanyHistories',
    'Auditors',
    'Invite',
    'BoardMeetingApplicationsProficiencyList',
    'CompanyCommunications',
    'BoardMeetingItemsSummary',
    'CertificatePlan',
    'BoardMeetingApplicationsCertificationList',
    'BoardMeetingApplication',
    'BoardMeetingApplications',
    'CertificateMeetings',
    'CertificateMeetingParticipants',
    'InspectionsSummary',
    'PersonCompanies',
    'Applications',
    'CertificateAuditReports',
    'Users',
    'CompanyMembership',
    'Message',
    'AuditProgram',
    'AuditPrograms',
    'CertificateDeviations',
    'CompanysCertificateMeetings',
    'FinancesValidation',
    'ExternalRegion',
    'CertificateAuditCosts',
    'CompanyOfferPacks',
    'Descriptions',
    'ClassificationIndustries',
    'Inspectables',
    'InspectionSteps',
    'ProductOrders',
    'CertificateAuditReport',
    'CertificateAuditReportPart',
    'AllNotifications',
    'UnreadNotifications',
    'Issues',
    'Issue',
    'IssueMessages',
    'UnreadIssueMessagesCount',
    'QualificationUnusedReferences',
    'UnusedReferences',
    'CertificateTypeCompanyPeople',
    'AllowedApplicationScopes'
  ],
  endpoints: () => ({}),
});

export function paginateResponse<T>(
  data: T[],
  res: ApiResponse,
  paginate?: boolean | number
): T[] | PaginatedResponse<T> {
  return paginate
    ? {
        currentPage:
          res.current_page === undefined
            ? res.meta.current_page
            : res.current_page,
        perPage: res.per_page === undefined ? res.meta.per_page : res.per_page,
        total: res.total === undefined ? res.meta.total : res.total,
        totalPages:
          res.last_page === undefined ? res.meta.last_page : res.last_page,
        data,
      }
    : data;
}
