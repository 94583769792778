import { put, call, all, takeEvery } from 'redux-saga/effects';
import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit';

import * as api from './api';
import { FINANCE_FILE_TYPES } from '~features/common/enums';
import { RootState } from '~store/rootReducer';

type Id = string;

const financeFileAdapter =
  createEntityAdapter<{
    periodId: Id;
    file: File;
    fileType: FINANCE_FILE_TYPES[];
  }>();

const initialState = financeFileAdapter.getInitialState({
  loading: {},
  error: {},
});

const slice = createSlice({
  name: 'financeFiles',
  initialState,
  reducers: {
    fetchAccountingPeriodFiles(
      state,
      action: PayloadAction<{ companyId: string; periodId: string }>
    ) {},

    createAccountingPeriodFiles(
      state,
      action: PayloadAction<{
        companyId: string;
        periodId: string;
        files: {
          balanceSheetFiles: File[];
          profitsAndLossesFiles: File[];
          auditReportFiles: File[];
          incomeStatementFiles: File[];
          profitsAndBalanceSheetFiles: File[];
        };
      }>
    ) {},

    receiveAccountingPeriodFiles: financeFileAdapter.upsertMany,
    createApplicationAccountingPeriodFiles(
      state,
      action: PayloadAction<{
        companyId: string;
        periods: [
          {
            periodId: string;
            balanceSheetFiles: File[];
            profitsAndLossesFiles: File[];
            auditReportFiles: File[];
            incomeStatementFiles: File[];
            profitsAndBalanceSheetFiles: File[];
          }
        ];
      }>
    ) {},

    deleteAccountingPeriodFiles(
      state,
      action: PayloadAction<{
        companyId: string;
        periodId: string;
        fileIds: string[];
      }>
    ) {},

    deleteSuccess: financeFileAdapter.removeOne,

    loading(state, action) {
      const type = action.payload;
      state.loading[type] = true;
      state.error[type] = null;
    },

    success(state, action) {
      const type = action.payload;
      state.loading[type] = false;
      state.error[type] = null;
    },

    error(state, action) {
      const { type, error } = action.payload;
      state.loading[type] = false;
      state.error[type] = error;
    },
  },
});

export const { actions } = slice;
export default slice.reducer;

const adapterSelectors = financeFileAdapter.getSelectors(
  (state: RootState) => state.financeFiles
);

export const selectors = {
  periodFiles: (state, periodId) =>
    adapterSelectors
      .selectAll(state)
      .filter(f => f.periodId === periodId)
      .reduce(
        (
          {
            balanceSheetFiles,
            profitsAndLossesFiles,
            auditReportFiles,
            incomeStatementFiles,
            profitsAndBalanceSheetFiles,
          },
          { id, filename, ...rest }
        ) => ({
          balanceSheetFiles:
            rest.fileType.length === 1 &&
            rest.fileType[0] === FINANCE_FILE_TYPES.BALANCE_SHEET
              ? [...balanceSheetFiles, { id, fileName: filename, ...rest }]
              : balanceSheetFiles,
          profitsAndLossesFiles:
            rest.fileType.length === 1 &&
            rest.fileType[0] === FINANCE_FILE_TYPES.PROFITS_AND_LOSSES
              ? [...profitsAndLossesFiles, { id, fileName: filename, ...rest }]
              : profitsAndLossesFiles,
          auditReportFiles:
            rest.fileType.length === 1 &&
            rest.fileType[0] === FINANCE_FILE_TYPES.AUDIT_REPORT
              ? [...auditReportFiles, { id, fileName: filename, ...rest }]
              : auditReportFiles,
          incomeStatementFiles:
            rest.fileType.length === 1 &&
            rest.fileType[0] === FINANCE_FILE_TYPES.INCOME_STATEMENT
              ? [...incomeStatementFiles, { id, fileName: filename, ...rest }]
              : incomeStatementFiles,
          profitsAndBalanceSheetFiles:
            rest.fileType.length > 1
              ? [
                  ...profitsAndBalanceSheetFiles,
                  { id, fileName: filename, ...rest },
                ]
              : profitsAndBalanceSheetFiles,
        }),
        {
          balanceSheetFiles: [],
          profitsAndLossesFiles: [],
          auditReportFiles: [],
          incomeStatementFiles: [],
          profitsAndBalanceSheetFiles: [],
        }
      ),
  loading: (state: RootState, key?) =>
    key
      ? !!state.financeFiles.loading[key]
      : Object.values(state.financeFiles.loading).some(v => Boolean(v)),
  error: (state: RootState, key?) =>
    key ? state.financeFiles.error[key] || null : state.financeFiles.error,
};

export function* fetchAccountingPeriodFiles(action) {
  const { type } = actions.fetchAccountingPeriodFiles;
  const { companyId, periodId } = action.payload;

  try {
    yield put(actions.loading(type));

    const files = yield call(
      api.fetchAccountingPeriodFiles,
      companyId,
      periodId
    );
    yield put(
      actions.receiveAccountingPeriodFiles(
        files.data.map(d => ({
          ...d,
          periodId,
          fileType: d.file_types.map(d => d.id),
          fileName: d.filename,
        }))
      )
    );

    yield put(actions.success(type));
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}
export function* createApplicationAccountingPeriodFiles(action) {
  const { type } = actions.createApplicationAccountingPeriodFiles;
  const { companyId, periods } = action.payload;

  try {
    yield put(actions.loading(type));
    let newFiles: any[] = [];
    for (const period of periods) {
      newFiles = newFiles.concat(
        yield all(
          [
            period.balanceSheetFiles.map(f =>
              call(
                api.createAccountingPeriodFile,
                companyId,
                period.id,
                f,
                FINANCE_FILE_TYPES.BALANCE_SHEET
              )
            ),
            period.auditReportFiles.map(f =>
              call(
                api.createAccountingPeriodFile,
                companyId,
                period.id,
                f,
                FINANCE_FILE_TYPES.AUDIT_REPORT
              )
            ),
            period.profitsAndLossesFiles.map(f =>
              call(
                api.createAccountingPeriodFile,
                companyId,
                period.id,
                f,
                FINANCE_FILE_TYPES.PROFITS_AND_LOSSES
              )
            ),
          ].flat()
        )
      );
    }

    yield put(
      actions.receiveAccountingPeriodFiles(
        newFiles.map(d => ({
          ...d,
          fileType: d.file_types[0].id,
          fileName: d.filename,
        }))
      )
    );

    yield put(actions.success(type));
  } catch (error) {
    yield put(actions.error({ type, error }));
    return error;
  }
}

export function* createAccountingPeriodFiles(action) {
  const { type } = actions.createAccountingPeriodFiles;
  const { companyId, periodId, files } = action.payload;
  const {
    balanceSheetFiles,
    profitsAndLossesFiles,
    auditReportFiles,
    incomeStatementFiles,
    profitsAndBalanceSheetFiles,
  } = files;
  try {
    yield put(actions.loading(type));
    const balanceSheetFiltered = balanceSheetFiles.filter(
      f => f.id === undefined
    );
    const profitsAndLossesFiltered = profitsAndLossesFiles.filter(
      f => f.id === undefined
    );
    const auditReportFiltered = auditReportFiles.filter(
      f => f.id === undefined
    );
    const incomeStatementFiltered = incomeStatementFiles.filter(
      f => f.id === undefined
    );
    const profitsAndBalanceSheetFiltered = profitsAndBalanceSheetFiles.filter(
      f => f.id === undefined
    );
    let newFiles: any[] = [];
    newFiles = newFiles.concat(
      yield all(
        [
          balanceSheetFiltered.map(f =>
            call(
              api.createAccountingPeriodFile,
              companyId,
              periodId,
              f,
              FINANCE_FILE_TYPES.BALANCE_SHEET
            )
          ),
          auditReportFiltered.map(f =>
            call(
              api.createAccountingPeriodFile,
              companyId,
              periodId,
              f,
              FINANCE_FILE_TYPES.AUDIT_REPORT
            )
          ),
          profitsAndLossesFiltered.map(f =>
            call(
              api.createAccountingPeriodFile,
              companyId,
              periodId,
              f,
              FINANCE_FILE_TYPES.PROFITS_AND_LOSSES
            )
          ),
          incomeStatementFiltered.map(f =>
            call(
              api.createAccountingPeriodFile,
              companyId,
              periodId,
              f,
              FINANCE_FILE_TYPES.INCOME_STATEMENT
            )
          ),
          profitsAndBalanceSheetFiltered.map(f =>
            call(api.createAccountingPeriodFile, companyId, periodId, f, [
              FINANCE_FILE_TYPES.PROFITS_AND_LOSSES,
              FINANCE_FILE_TYPES.BALANCE_SHEET,
            ])
          ),
        ].flat()
      )
    );

    yield put(
      actions.receiveAccountingPeriodFiles(
        newFiles.map(d => ({
          ...d,
          periodId,
          fileType: d.file_types[0].id,
          fileName: d.filename,
        }))
      )
    );

    yield put(actions.success(type));
    return newFiles;
  } catch (error) {
    yield put(actions.error({ type, error }));
    return error;
  }
}

export function* deleteAccountingPeriodFiles(action) {
  const { type } = actions.deleteAccountingPeriodFiles;
  const { companyId, periodId, fileIds } = action.payload;

  try {
    yield put(actions.loading(type));

    for (const fileId of fileIds) {
      yield call(api.deleteAccountingPeriodFile, companyId, periodId, fileId);
      yield put(actions.deleteSuccess(fileId));
    }

    yield put(actions.success(type));
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* financeFileSaga() {
  yield all([
    yield takeEvery(
      actions.fetchAccountingPeriodFiles.type,
      fetchAccountingPeriodFiles
    ),
    yield takeEvery(
      actions.createAccountingPeriodFiles.type,
      createAccountingPeriodFiles
    ),
    yield takeEvery(
      actions.createApplicationAccountingPeriodFiles.type,
      createApplicationAccountingPeriodFiles
    ),
    yield takeEvery(
      actions.deleteAccountingPeriodFiles.type,
      deleteAccountingPeriodFiles
    ),
  ]);
}
