import { createSlice, SerializedError, PayloadAction } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { parseErrors } from '~common/helpers';

export interface Message {
  text: string;
  type: 'info' | 'success' | 'warning' | 'error';
  code?: string;
  param?: string;
}

interface State {
  messages: Message[];
  page?: string; // html returned by the backend
}

const slice = createSlice({
  name: 'errors',
  initialState: {
    messages: [],
  } as State,
  reducers: {
    set(
      state,
      action: PayloadAction<FetchBaseQueryError | SerializedError | null>
    ) {
      state.messages = action.payload ? parseErrors(action.payload) : [];
    },
    setErrorPage(state, action: PayloadAction<string | null>) {
      state.page = action.payload ?? undefined;
    },
  },
});

export const { actions } = slice;
export default slice.reducer;

export const selectors = {
  errors: state => state.errors.messages,
  errorPage: state => state.errors.page,
};
