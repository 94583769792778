/* eslint-disable import/prefer-default-export */
import api from '../api';

export function fetchProducts() {
  return api.get(`/products`);
}

export function fetchProductAgreement(productId) {
  return api.get(`/products/${productId}/agreements`);
}
