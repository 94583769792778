import camelCase from 'lodash/camelCase';

import Application from './Application';
import CertificateAuditReport from './CertificateAuditReport';
import Person from './Person';
import { CERTIFICATE_AUDIT_PROGRAM_STATUS } from '~common/enums';

export default class CertificateAuditProgram {
  public readonly id: number;
  public readonly createdAt: string;
  public readonly billingDate: string | null;
  public readonly certificateAuditProgramStatusId: CERTIFICATE_AUDIT_PROGRAM_STATUS;
  public readonly certificateAuditReport?: CertificateAuditReport;
  public readonly date: string;
  public readonly plannedStartDate: string;
  public readonly startDate: string | null;
  public readonly durationInDays: number | null;
  public readonly status: number;
  public readonly auditorPersonId: number;
  public readonly auditorName: string;
  public readonly secondaryAuditorPersonId: number;
  public readonly secondaryAuditorName: string;
  public readonly prevAuditorId: number;
  public readonly prevAuditorName: string;
  public readonly nextAuditorId: number;
  public readonly nextAuditorName: string;
  public readonly requiresNewAuditor: boolean;
  public readonly application?: Application;
  public readonly applicationId?: number | null;
  public readonly companyId?: number | null;
  public readonly lastCertificateMeetingDate: string | null;
  public readonly auditorPerson: Person | null;
  public readonly secondaryAuditorPerson: Person | null;
  public readonly certificateTypeId: number;
  public readonly statementId: number;
  public readonly statementAdditionalInfo: string;
  public readonly finishedAt: string | null;
  public readonly reportFinishedAt: string | null;
  public readonly reportCreatedAt: string | null;
  public readonly reportSentAt: string | null;
  public readonly type: string | null;

  constructor(data) {
    Object.keys(data).forEach(k => {
      this[camelCase(k)] = data[k];
    });
    this.application =
      this.application !== undefined && this.application !== null
        ? new Application(this.application)
        : undefined;
    this.auditorPerson = this.auditorPerson
      ? new Person(this.auditorPerson)
      : null;
    this.secondaryAuditorPerson = this.secondaryAuditorPerson
      ? new Person(this.secondaryAuditorPerson)
      : null;
    this.certificateAuditReport = this.certificateAuditReport
      ? new CertificateAuditReport(this.certificateAuditReport)
      : undefined;
  }
}
