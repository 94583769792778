import camelCase from 'lodash/camelCase';

export default class RalaNewsFeedItem {
  public readonly id: number;
  public readonly title: string;
  public readonly date: string;
  public readonly description: string;
  public readonly image: string;
  public readonly categoryName: string;

  constructor(data) {
    Object.keys(data).forEach(k => {
      this[camelCase(k)] = data[k];
    });
  }
}
