import camelCase from 'lodash/camelCase';
import CompanyReference from './CompanyReference';
import Description from './Description';
import IndustryRequirement from './IndustryRequirement';

export default class ClassificationIndustry {
  public readonly id: number;
  public readonly parentId: number;
  public readonly symbolicNumber: string;
  public readonly longDescription: string;
  public readonly shortDescription: string;
  public readonly helpText: string;
  public readonly createdAt: string;
  public readonly updatedAt: string;
  public readonly deletedAt: string;
  public readonly descriptions: Description[];
  public readonly requirements?: IndustryRequirement;
  public readonly references: CompanyReference[];
  public children?: ClassificationIndustry[];
  public sortNumber: number;
  public readonly availableProficiency?: boolean;
  public readonly availableCertification?: boolean;

  constructor(data) {
    Object.keys(data).forEach(k => {
      this[camelCase(k)] = data[k];
    });
    this.requirements = this?.requirements
      ? new IndustryRequirement(this?.requirements)
      : undefined;
    this.descriptions = this?.descriptions?.map(o => new Description(o)) ?? [];
    this.references = this?.references?.map(o => new CompanyReference(o)) ?? [];
    this.createSortNumber(this.symbolicNumber || '');
  }

  getIndustryCategoryTitles(classificationIndustryDescriptions) {
    let mainCategoryTitle = '';
    let subCategoryTitle = '';
    let subSubCategoryTitle = '';
    const parentOfThisIndustry = this?.parentId
      ? classificationIndustryDescriptions.find(
          desc => desc.id === this?.parentId
        )
      : null;

    // if parentId is defined in parentOfThisIndustry --> there is another level where shortDescription needs to be read
    if (parentOfThisIndustry) {
      if (parentOfThisIndustry.parentId) {
        // There is sub sub category
        mainCategoryTitle = classificationIndustryDescriptions.find(
          desc => desc.id === parseInt(parentOfThisIndustry.parentId, 10)
        )?.shortDescription;
        subCategoryTitle = parentOfThisIndustry.shortDescription;
        subSubCategoryTitle = this.shortDescription;
      } else {
        // There is sub category
        mainCategoryTitle = parentOfThisIndustry.shortDescription;
        subCategoryTitle = this.shortDescription;
      }
    } else {
      // There is only main category
      mainCategoryTitle = this.shortDescription;
    }

    return `${mainCategoryTitle}${
      subCategoryTitle ? ', ' + subCategoryTitle : ''
    }${subSubCategoryTitle ? ', ' + subSubCategoryTitle : ''}`;
  }

  createSortNumber(symbolicNumber) {
    const parts: string[] = symbolicNumber.split('.');
    const p1 = parseInt(parts[0], 10) * 10000;
    const p2 = parts.length > 1 ? parseInt(parts[1], 10) * 100 : 0;
    const p3 = parts.length > 2 ? parseInt(parts[2], 10) : 0;
    this.sortNumber = p1 + p2 + p3;
  }
}
