/* eslint-disable import/prefer-default-export */
import api from '../api';

export function getCompanyPerson(companyPerson) {
  return api.get(
    `/companies/${companyPerson.companyId}/people/${companyPerson.personId}`
  );
}

export function getPending(companyId) {
  return api.get(`/companies/${companyId}/people/pending`);
}

export function approve(companyId, personId, request) {
  return api.post(
    `/companies/${companyId}/people/${personId}/approve`,
    request
  );
}

export function reject(companyId, personId) {
  return api.post(`/companies/${companyId}/people/${personId}/reject`, {});
}
