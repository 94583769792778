import camelCase from 'lodash/camelCase';

export default class IndustryRequirement {
  public readonly certificateTypeId?: number;
  public readonly minTotalBilling?: number;
  public readonly maxReferenceAge?: number;
  public readonly fileTypeId?: number;
  public readonly companyId?: number;
  public readonly minReferenceCount?: number;
  public readonly classificationIndustryId?: number;
  public readonly educationDegreeIds?: string[];
  public readonly personCertificateTypeIds?: string[];

  constructor(data) {
    Object.keys(data).forEach(k => {
      this[camelCase(k)] = data[k];
    });
  }
}
