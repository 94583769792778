/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  createSlice,
  PayloadAction,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import { USER_PERMISSIONS } from '~/features/common/enums';

interface State {
  searchParams: {
    search?: string;
    page?: number;
    sort?: string;
    order?: 'asc' | 'desc';
    paginate?: boolean;
  };
}

const auditorContactsAdapter = createEntityAdapter<void>();

const initialState = auditorContactsAdapter.getInitialState({
  searchParams: {
    paginate: true,
    page: 1,
  },
} as State);

const slice = createSlice({
  name: 'auditorContacts',
  initialState,
  reducers: {
    setSearchParams(state, action: PayloadAction<any>) {
      state.searchParams = { ...state.searchParams, ...action.payload };
    },
    clearSearchParams(state) {
      state.searchParams = initialState.searchParams;
    },
  },
});

export const { actions } = slice;
export default slice.reducer;

const adapterSelectors = auditorContactsAdapter.getSelectors(
  (state: any) => state.auditorContacts
);

export const selectors = {
  searchParams: state => state.auditorContacts.searchParams,
};
