import camelCase from 'lodash/camelCase';
import ProductAgreement from './ProductAgreement';
import CompanyPerson from './CompanyPerson';
import Product from './Product';

export default class Agreement {
  public readonly id: string;
  public readonly companyId: string;
  public readonly companyContactPersonId: string;
  public readonly ralaContactPersonId: string;
  public readonly productAgreementId: string;
  public readonly signedAt: string;
  public readonly terminatedAt: string;
  public readonly terminatedReason: string;
  public readonly createdAt: string;
  public readonly updatedAt: string;
  public readonly deletedAt: string;
  public readonly productAgreement?: ProductAgreement;
  public readonly companyContactPerson?: CompanyPerson;
  public readonly product?: Product;

  constructor(data) {
    Object.keys(data).forEach(k => {
      this[camelCase(k)] = data[k];
    });

    this.productAgreement = this.productAgreement
      ? new ProductAgreement(this.productAgreement)
      : undefined;
    this.companyContactPerson = this.companyContactPerson
      ? new CompanyPerson(this.companyContactPerson)
      : undefined;
    this.product = this.product ? new Product(this.product) : undefined;
  }
}
