import api from '../api';

export function fetchFileTypes() {
  return api.get(`/descriptions/file-types`);
}

export function fetchApplicationFiles(companyId, applicationId) {
  return api.get(
    `/companies/${companyId}/applications/${applicationId}/files/`
  );
}

export function createApplicationFile(
  companyId,
  applicationId,
  file,
  fileType
) {
  const data = new FormData();
  data.append('file', file);
  data.append('file_type_id', fileType);

  return api.post(
    `/companies/${companyId}/applications/${applicationId}/files/`,
    data
  );
}

export function createDuplicateForApplicationFile(
  companyId,
  applicationId,
  duplicateFileId,
  fileType
) {
  const data = new FormData();
  data.append('duplicate_file_id', duplicateFileId);
  data.append('file_type_id', fileType);

  return api.post(
    `/companies/${companyId}/applications/${applicationId}/files/`,
    data
  );
}

export function deleteApplicationFile(companyId, applicationId, fileId) {
  return api.delete(
    `/companies/${companyId}/applications/${applicationId}/files/${fileId}`
  );
}
