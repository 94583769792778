import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit';
import BoardMeeting from '~/models/BoardMeeting';

type ValidationErrors = Partial<{ [key in keyof FormData]: string[] }>;
interface State {
  selectedApplicationId: number | null;
  removeApplicationId: number | null;
  selectedBoardMeeting: BoardMeeting | null;
  validationErrors: ValidationErrors | null;
  selectedParticipants: { id: number; name: string }[];
  modalState: 'listing' | 'addParticipants';
}
const boardsAdapter = createEntityAdapter<any>();
const initialState = boardsAdapter.getInitialState({
  selectedApplicationId: null,
  removeApplicationId: null,
  selectedBoardMeeting: null,
  validationErrors: null,
  selectedParticipants: [],
  modalState: 'listing',
} as State);

const slice = createSlice({
  name: 'boards',
  initialState,
  reducers: {
    setSelectedApplicationId(state, action: PayloadAction<number | null>) {
      state.selectedApplicationId = action.payload;
    },
    setRemoveApplicationId(state, action: PayloadAction<number | null>) {
      state.removeApplicationId = action.payload;
    },
    setSelectedBoardMeeting(state, action: PayloadAction<BoardMeeting | null>) {
      state.selectedBoardMeeting = action.payload;
    },
    setValidationErrors(state, action: PayloadAction<ValidationErrors | null>) {
      state.validationErrors = action.payload;
    },
    setModalState(state, action: PayloadAction<'listing' | 'addParticipants'>) {
      state.modalState = action.payload;
      if (action.payload === 'listing') {
        state.selectedParticipants = initialState.selectedParticipants;
      }
    },
    setSelectedParticipants(
      state,
      action: PayloadAction<{ id: number; name: string }[]>
    ) {
      state.selectedParticipants = action.payload;
    },
  },
});

export const { actions } = slice;
export default slice.reducer;

export const selectors = {
  selectedApplicationId: state => state.boards.selectedApplicationId,
  removeApplicationId: state => state.boards.removeApplicationId,
  selectedBoardMeeting: state => state.boards.selectedBoardMeeting,
  validationErrors: state => state.boards.validationErrors,
  modalState: state => state.boards.modalState,
  selectedParticipants: state => state.boards.selectedParticipants,
};
