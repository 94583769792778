/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  createSlice,
  PayloadAction,
  createEntityAdapter,
} from '@reduxjs/toolkit';

import { COMMON } from '../common';

import { RootState } from '../rootReducer';
import { APIError } from '../api';
import * as api from './api';

import Application from '~models/Application';
import ApplicationTarget from '~models/ApplicationTarget';
import { APPLICATION_FILE_TYPES } from '~common/enums';

const applicationsAdapter = createEntityAdapter<Application>();

// the save state shown in sticky footer, certificate applications also use this state
// TODO: bad place for such state? does it need different store script?
export type SaveState = 'saved' | 'saving' | 'error';
const initialSaveState: SaveState = 'saved';

const initialState = applicationsAdapter.getInitialState({
  applicationTargets: [],
  search: {
    data: [],
    meta: {},
  },
  pagination: null,
  currentApplications: [],
  create: {},
  update: {},
  validate: {},
  send: {},
  loading: {},
  error: {},
  showStickyFooter: false,
  saveState: initialSaveState,
});

const slice = createSlice({
  name: 'applications',
  initialState,
  reducers: {
    create(
      state,
      action: PayloadAction<{
        companyId: string;
        data?: Partial<Application>;
      }>
    ) {},

    receiveCreate(state, action) {
      const application = action.payload;
      application !== null
        ? (state.create = new Application(application))
        : (state.create = {});
      applicationsAdapter.addOne(state, new Application(application));
    },

    fetch(
      state,
      action: PayloadAction<{ companyId: string; applicationId: string }>
    ) {},

    fetchApplicationTargets(
      state,
      action: PayloadAction<{ companyId: string }>
    ) {},

    receiveApplicationTargets(state, action: PayloadAction<any>) {
      state.applicationTargets = action.payload.data.map(
        a => new ApplicationTarget(a)
      );
    },

    receive(state, action) {
      applicationsAdapter.upsertOne(state, new Application(action.payload));
    },

    update(
      state,
      action: PayloadAction<{
        companyId: string;
        applicationId: string;
        data?: Partial<Application>;
      }>
    ) {},

    receiveUpdate(state, action) {
      applicationsAdapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      });
    },

    cancel(
      state,
      action: PayloadAction<{ companyId: string; applicationId: string }>
    ) {},

    cancelSuccess(state, action) {
      applicationsAdapter.removeOne(state, action.payload.id);
    },

    clearApplication() {},

    validate(
      state,
      action: PayloadAction<{
        companyId: string;
        applicationId: string;
      }>
    ) {},

    receiveValidation(state, action) {
      state.validate = action.payload;
    },

    send(
      state,
      action: PayloadAction<{
        companyId: string;
        applicationId: string;
      }>
    ) {},

    receiveSend(state, action) {
      state.send = action.payload;
    },

    destroy(
      state,
      action: PayloadAction<{ companyId: string; applicationId: string }>
    ) {},

    search(
      state,
      action: PayloadAction<{
        companyId?: string;
        params?: {
          page: number;
          application_status_id?: number;
          application_target_id?: number;
        };
      }>
    ) {},

    receiveSearch(state, action: PayloadAction<any>) {
      const total = action.payload.data
        .map(i => new Application(i))
        .filter(a => parseInt(a.applicationStatusId) < 10).length;
      const meta = Object.assign({}, action.payload.meta, {
        total: total,
      });

      state.search = {
        data: action.payload.data.map(i => new Application(i)),
        meta: meta,
      };
      applicationsAdapter.setAll(
        state,
        action.payload.data.map(i => new Application(i))
      );
    },

    receivePagination(state, action) {
      state.pagination = action.payload;
    },

    receiveCurrentApplications(state, action) {
      state.currentApplications = action.payload.data
        .map(a => new Application(a))
        .filter(a => parseInt(a.applicationStatusId) === 5);
    },

    loading(state, action: PayloadAction<string>) {
      const type = action.payload;
      state.loading[type] = true;
      state.error[type] = null;
    },

    success(state, action: PayloadAction<string>) {
      const type = action.payload;
      state.loading[type] = false;
      state.error[type] = null;
    },

    error(state, action: PayloadAction<{ type: string; error: APIError }>) {
      const { type, error } = action.payload;
      state.loading[type] = false;
      state.error[type] = error;
    },

    clearError(state, action: PayloadAction<string>) {
      const type = action.payload;
      state.error[type] = null;
    },

    setShowStickyFooter(state, action) {
      state.showStickyFooter = action.payload;
    },

    setSaveState(state, action) {
      state.saveState = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(COMMON.NAVIGATE, state => {
      Object.keys(state.error).forEach(k => {
        state.error[k] = null;
      });
    })
  },
});

export const { actions } = slice;
export default slice.reducer;

const adapterSelectors = applicationsAdapter.getSelectors(
  (state: RootState) => state.applications
);

export const selectors = {
  applications: (state, applicationId) =>
    adapterSelectors.selectById(state, applicationId),
  qualifications: state => adapterSelectors.selectAll(state),
  applicationsTargets: state => state.applications.applicationTargets,
  search: state => state.applications.search,
  pagination: state => state.applications.pagination,
  create: state => state.applications.create,
  update: state => state.applications.update,
  validate: state => state.applications.validate,
  send: state => state.applications.send,
  loading: (state: RootState, key?) =>
    key
      ? !!state.applications.loading[key]
      : Object.values(state.applications.loading).some(v => Boolean(v)),
  error: (state, key?) =>
    key ? state.applications.error[key] || null : state.applications.error,
  saveState: state => state.applications.saveState,
  showStickyFooter: state => state.applications.showStickyFooter,
};

export function* search(action: ReturnType<typeof actions.search>) {
  const { type, payload } = action;
  const { companyId, params } = payload;

  try {
    yield put(actions.loading(type));
    const response = yield call(api.search, companyId, params);
    yield put(actions.receiveSearch(response));

    const total = response.data.filter(
      a => parseInt(a.application_status_id) < 10
    ).length;
    const meta = Object.assign({}, response.meta, {
      total: total,
    });
    yield put(actions.receivePagination(meta));
    yield put(actions.receiveCurrentApplications(response));
    yield put(actions.success(type));
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* create(action: ReturnType<typeof actions.create>) {
  const { type, payload } = action;
  const { companyId, data } = payload;
  try {
    yield put(actions.loading(type));
    // Add file_type_id to file objects and concat lists
    const { consultingFiles, liabilityFiles } = data;

    const newConsultingFiles =
      consultingFiles?.map(f => ({
        f,
        file_type_id: APPLICATION_FILE_TYPES.CONSULTING_INSURANCE,
      })) ?? [];

    const newLiabilityFiles =
      liabilityFiles?.map(f => ({
        f,
        file_type_id: APPLICATION_FILE_TYPES.LIABILITY_INSURANCE,
      })) ?? [];

    const files = newConsultingFiles.concat(newLiabilityFiles);
    // Include only data that exists to the object
    const newApplication = {
      application_target_id: data?.applicationTargetId,
      contact_person_id: data?.contactPersonId,
      ...(data?.applicationAreaCodes?.length && {
        application_area_codes: data?.applicationAreaCodes,
      }),
      ...(data?.boardMeetingId !== undefined && {
        board_meeting_id: data?.boardMeetingId,
      }),
      ...(data?.industryDescription !== undefined && {
        industry_description: data?.industryDescription,
      }),
      ...(files.length > 0 && {
        files: files,
      }),
      ...(data?.applicationCompanyOffices?.length && {
        application_company_offices: data?.applicationCompanyOffices,
      }),
      ...(data?.applicationIndustries?.length && {
        application_industries: data?.applicationIndustries,
      }),
      ...(data?.applicationScopes?.length && {
        application_scopes: data?.applicationScopes,
      }),
    };

    const response = yield call(api.create, companyId, newApplication);
    yield put(actions.receiveCreate(response.data));
    yield put(actions.success(type));
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* fetch(action: ReturnType<typeof actions.fetch>) {
  const { type, payload } = action;

  try {
    yield put(actions.loading(type));

    const response = yield call(api.fetch, payload);
    yield put(actions.receiveCreate(response.data));

    yield put(actions.success(type));
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* fetchApplicationTargets(
  action: ReturnType<typeof actions.fetchApplicationTargets>
) {
  const { type } = action;
  const companyId = action.payload;
  try {
    yield put(actions.loading(type));
    const response = yield call(api.fetchApplicationTargets, { companyId });
    yield put(actions.receiveApplicationTargets(response));

    yield put(actions.success(type));
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* cancel(action: ReturnType<typeof actions.cancel>) {
  const { type, payload } = action;
  const { companyId, applicationId } = payload;
  try {
    yield put(actions.loading(type));
    const response = yield call(api.cancel, companyId, applicationId);
    yield put(actions.cancelSuccess(response));
    yield put(actions.success(type));
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* clearApplication(
  action: ReturnType<typeof actions.clearApplication>
) {
  const { type, payload } = action;
  try {
    yield put(actions.loading(type));
    yield put(actions.receiveCreate(null));
    yield put(actions.success(type));
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* update(action: ReturnType<typeof actions.update>) {
  const { type, payload } = action;
  const { companyId, applicationId, data } = payload;
  try {
    yield put(actions.loading(type));
    const { consultingFiles, liabilityFiles } = data;
    // Add file_type_id to file objects and concat lists
    const newConsultingFiles = consultingFiles
      .filter(f => f.id === undefined)
      .map(f => ({
        f,
        file_type_id: APPLICATION_FILE_TYPES.CONSULTING_INSURANCE,
      }));
    const newLiabilityFiles = liabilityFiles
      .filter(f => f.id === undefined)
      .map(f => ({
        f,
        file_type_id: APPLICATION_FILE_TYPES.LIABILITY_INSURANCE,
      }));
    const files = newConsultingFiles.concat(newLiabilityFiles);

    // Include only data that exists to the object
    const newApplication = {
      application_target_id: data?.applicationTargetId,
      contact_person_id: data?.contactPersonId,
      ...(data?.applicationAreaCodes?.length && {
        application_area_codes: data?.applicationAreaCodes?.map(a =>
          a.id ? a.id : a
        ),
      }),
      ...(data?.boardMeetingId !== null && {
        board_meeting_id: data?.boardMeetingId,
      }),
      ...(data?.industryDescription !== null && {
        industry_description: data?.industryDescription?.toString(),
      }),
      ...(files.length > 0 && {
        files: files,
      }),
      ...(data?.applicationCompanyOffices?.length && {
        application_company_offices: data?.applicationCompanyOffices?.map(a =>
          a.id ? a.companyOfficeId : a
        ),
      }),
      ...(data?.applicationIndustries?.length && {
        application_industries: data?.applicationIndustries?.map(a =>
          a.id ? a.id : a
        ),
      }),
      ...(data?.applicationScopes !== undefined && {
        application_scopes: data?.applicationScopes?.map(a =>
          a.id ? a.id : a
        ),
      }),
    };
    const response = yield call(
      api.update,
      companyId,
      applicationId,
      newApplication
    );
    yield put(actions.receiveUpdate(new Application(response.data)));
    yield put(actions.success(type));
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* validate(action: ReturnType<typeof actions.validate>) {
  const { type, payload } = action;
  const { companyId, applicationId } = payload;
  try {
    yield put(actions.loading(type));
    const response = yield call(api.validate, companyId, applicationId);
    yield put(actions.receiveValidation(response.data));
    yield put(actions.success(type));
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* send(action: ReturnType<typeof actions.send>) {
  const { type, payload } = action;
  const { companyId, applicationId } = payload;
  try {
    yield put(actions.loading(type));
    const response = yield call(api.send, companyId, applicationId);
    yield put(actions.receiveSend(response.data));
    yield put(actions.success(type));
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* destroy(action: ReturnType<typeof actions.destroy>) {
  const { type, payload } = action;
  const { companyId, applicationId } = payload;
  try {
    yield put(actions.loading(type));
    const response = yield call(api.destroy, companyId, applicationId);
    yield put(actions.cancelSuccess(response.data));
    yield put(actions.success(type));
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* applicationsSaga() {
  yield all([
    takeEvery(actions.create.type, create),
    takeEvery(actions.cancel.type, cancel),
    takeEvery(actions.clearApplication.type, clearApplication),
    takeEvery(actions.update.type, update),
    takeEvery(actions.fetch.type, fetch),
    takeLatest(actions.search, search),
    takeEvery(actions.fetchApplicationTargets.type, fetchApplicationTargets),
    takeEvery(actions.validate, validate),
    takeEvery(actions.send, send),
    takeEvery(actions.destroy.type, destroy),
  ]);
}
