import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit';
import ClassificationIndustry from '~/models/ClassificationIndustry';
import { RootState } from '~store/index';
type modalState = 'listing' | 'create' | 'edit' | 'delete';
type ValidationErrors = Partial<{ [key in keyof FormData]: string[] }>;
interface State {
  selectedClassificationIndustry: ClassificationIndustry | null;
  modalState: modalState;
  validationErrors: ValidationErrors | null;
}

const classificationIndustriesAdapter = createEntityAdapter<any>();

const initialState = classificationIndustriesAdapter.getInitialState({
  selectedClassificationIndustry: null,
  modalState: 'listing',
  validationErrors: null,
} as State);

const slice = createSlice({
  name: 'classificationIndustries',
  initialState,
  reducers: {
    setSelectedClassificationIndustry(state, action: PayloadAction<any>) {
      state.selectedClassificationIndustry = {
        ...state.selectedClassificationIndustry,
        ...action.payload,
      };
    },
    setModalState(state, action: PayloadAction<any>) {
      state.modalState = action.payload;
      if (action.payload === 'listing') {
        state.selectedClassificationIndustry =
          initialState.selectedClassificationIndustry;
      }
    },
    setValidationErrors(state, action: PayloadAction<ValidationErrors | null>) {
      state.validationErrors = action.payload;
    },
  },
});

export const { actions } = slice;
export default slice.reducer;

export const selectors = {
  selectedClassificationIndustry: (state: RootState) =>
    state.classificationIndustries.selectedClassificationIndustry,
  modalState: (state: RootState) => state.classificationIndustries.modalState,
  validationErrors: state => state.classificationIndustry.validationErrors,
};
