import camelCase from 'lodash/camelCase';

export default class Board {
  public readonly id: number;
  public readonly name: string;
  public readonly shortName: string;
  public readonly description: string;
  public readonly active: string;
  public readonly ralaResponsibleId: string;
  public readonly createdAt: string;
  public readonly updatedAt: string;
  public readonly deletedAt: string;

  constructor(data) {
    Object.keys(data).forEach(k => {
      this[camelCase(k)] = data[k];
    });
  }
}
