import camelCase from 'lodash/camelCase';
import { format, eachDayOfInterval, subDays } from 'date-fns';

import BoardMember from './BoardMember';
import Board from '~models/Board';
import Person from '~models/Person';

export default class BoardMeeting {
  public readonly id: number;
  public readonly boardId: number;
  public readonly title: string;
  public readonly description: string;
  public readonly meetingTime: string;
  public readonly location: string;
  public readonly callerPersonId: string;
  public readonly messageTitleCompany: string;
  public readonly messageContentCompany: string;
  public readonly messageTitleMember: string;
  public readonly messageContentMember: string;
  public readonly statusText: string;
  public readonly active: boolean;
  public readonly archivedAt: string;
  public readonly createdAt: string;
  public readonly updatedAt: string;
  public readonly deletedAt: string;
  public readonly boardMeetingMembers: BoardMember[];
  public readonly boardMeetingMembersCount: number;
  public readonly applicationCount: number;
  public readonly startedAt: string | null;
  public readonly endedAt: string | null;
  public readonly returnDueDate: string | null;
  public readonly previousBoardMeetingId: string | null;
  public board: Board;
  public callerPerson: Person;
  public readonly sentRenewalDate: string;
  public readonly remindRenewalDate: string;

  constructor(data) {
    Object.keys(data).forEach(k => {
      const keyName = camelCase(k);
      switch (keyName) {
        case 'callerPerson':
          this[keyName] = new Person(data[k]);
          break;
        case 'board':
          this[keyName] = new Board(data[k]);
          break;
        default:
          this[keyName] = data[k];
      }
    });
    this.boardMeetingMembers = (this.boardMeetingMembers || []).map(
      bm => new BoardMember(bm)
    );
  }

  get timeLeftToEditDays() {
    const timeLeft = eachDayOfInterval({
      start: new Date(),
      end: new Date(this.meetingTime),
    });
    return timeLeft.length;
  }

  get meetingTimeDate() {
    return format(new Date(this.meetingTime), 'dd.MM.yyyy');
  }

  get meetingTimeSqlDate() {
    return format(new Date(this.meetingTime), 'yyyy-MM-dd');
  }

  get meetingTimeHours() {
    return format(new Date(this.meetingTime), 'HH.mm');
  }

  get meetingTimeHoursSql() {
    return new Date(this.meetingTime);
  }

  get meetingTimeSql() {
    return format(new Date(this.meetingTime), 'yyyy-MM-dd HH.mm') + ':00';
  }

  get remindRenewalSqlDate() {
    if (this.remindRenewalDate) {
      return format(new Date(this.remindRenewalDate), 'yyyy-MM-dd');
    }
    const placeholderDate = subDays(new Date(this.meetingTime), 60);
    return format(placeholderDate, 'yyyy-MM-dd');
  }

  get sentRenewalDateSqlDate() {
    if (this.sentRenewalDate) {
      return format(new Date(this.sentRenewalDate), 'yyyy-MM-dd');
    }
    const placeholderDate = subDays(new Date(this.meetingTime), 14);
    return format(placeholderDate, 'yyyy-MM-dd');
  }

  get returnDueSqlDate() {
    if (this.returnDueDate) {
      return format(new Date(this.returnDueDate), 'yyyy-MM-dd');
    }
    return '';
  }

  get statusIconClass() {
    switch (this.statusText) {
      case 'in_future':
        return 'material-icons text-warning tooltip-icon';
      case 'active':
        return 'material-icons text-success tooltip-icon';
      case 'archived':
        return 'material-icons text-info tooltip-icon';
      default:
        return '';
    }
  }

  get statusIcon() {
    switch (this.statusText) {
      case 'in_future':
        return 'star_outline';
      case 'active':
        return 'pending_actions';
      case 'archived':
        return 'inventory_2';
      default:
        return '';
    }
  }

  get statusIconTitle() {
    switch (this.statusText) {
      case 'in_future':
        return 'Tuleva kokous';
      case 'active':
        return 'Aktiivinen';
      case 'archived':
        return 'Arkistoitu';
      default:
        return '';
    }
  }
}
