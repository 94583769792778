import camelCase from 'lodash/camelCase';
import differenceInMonths from 'date-fns/differenceInMonths';
import Person from './Person';
import Project from './Project';
import { asCamelCase } from '~common/helpers';
import config from '~/config';
import MissingImage from '~images/image-missing.svg';

interface Client {
  confirmationTypeId: number;
  email: string | null;
  evaluationStatusId: number;
  firstName: string;
  lastName: string;
  registrationNumber?: string;
  companyName: string | null;
  updatedAt?: string | null;
  createdAt: string;
  deletedAt?: string | null;
  evaluationRequestDate?: string | null;
  evaluationResponseDate?: string | null;
  hash?: null;
  id: number;
  requesterPersonId?: number | null;
}

export default class CompanyReference {
  public readonly id: number;
  public readonly companyId: number;
  public readonly referenceTypeId: number;
  public readonly statusId: number;
  public readonly referenceStatusId: number;
  public readonly orderNumber: number;
  public readonly contractorId: number;
  public readonly contractorContactPersonId: number;
  public readonly personId: number;
  public readonly client: Client;
  public readonly title: string;
  public readonly workDescription: string;
  public readonly workStartDate: string;
  public readonly workEndDate: string;
  public readonly totalBilling: number;
  public readonly totalBillingUnitId: number;
  public readonly subcontractorDegree: number;
  public readonly subcontractorAmount: number;
  public readonly expertises: number[];
  public readonly projectId: number;
  public readonly lifecyclePhaseId: number;
  public readonly planningTypeId: number;
  public readonly contractTypeId: number;
  public readonly sectorId: undefined; // unused for now
  public readonly companyRoleId: number;
  public readonly visibilityTypeId: number;
  public readonly taskId: number;
  public readonly subtaskId: number;
  public readonly subtaskSpecificationId: number;
  public readonly complexity: number;
  public readonly duration: number;
  public readonly level1EvaluationDate: string;
  public readonly level2EvaluationDate: string;
  public readonly level3EvaluationDate: string;
  public readonly images: { id: number; filename: string }[];
  public readonly defaultImage?: { id: number; filename: string };
  public readonly locked: boolean;
  public readonly companyName: string;
  public readonly verifiedByBoard: string | null;
  public readonly verifiedByCustomer: string | null;
  public readonly verifiedByFeedback: string | null;
  public readonly companyContactPerson?: Person;
  public readonly project?: Project;
  public readonly additionalInfo: string;
  public readonly createdAt: string;
  public readonly isUsed: boolean;
  public readonly address: string;
  public readonly feedback?: { url_fi: string };

  constructor(data) {
    Object.keys(data).forEach(k => {
      this[camelCase(k)] = data[k];
    });

    this.statusId = data.reference_status_id;

    this.contractorId = data.company_id;
    this.contractorContactPersonId = data.company_contact_person_id;
    if (data.client) {
      this.client = asCamelCase(data?.client) as Client;
      this.client.registrationNumber =
        this?.client?.registrationNumber ?? undefined;
    }
    if (data.reference_client) {
      this.client = asCamelCase(data?.reference_client) as Client;
      this.client.registrationNumber =
        this?.client?.registrationNumber ?? undefined;
    }
    this.sectorId = undefined;
    this.expertises =
      data.expertises !== undefined
        ? data.expertises.map(e => e.id)
        : undefined;
    this.companyContactPerson = this.companyContactPerson
      ? new Person(this.companyContactPerson)
      : undefined;
    this.project = this.project ? new Project(this.project) : undefined;
  }

  get defaultImageUrl() {
    return this.defaultImage
      ? `${config.API_URL}/companies/${this.companyId}/references/${this.id}/images/${this.defaultImage.id}/content?size=md`
      : this.images?.[0]
      ? `${config.API_URL}/companies/${this.companyId}/references/${this.id}/images/${this.images[0].id}/content?size=md`
      : MissingImage;
  }

  getIsReferenceYearValid(maxReferenceAgeInMonths: number): boolean {
    return maxReferenceAgeInMonths
      ? differenceInMonths(new Date(), new Date(this.workEndDate)) >=
          maxReferenceAgeInMonths
      : false;
  }
}

export interface FormFields
  extends Partial<Omit<CompanyReference, 'client' | 'images'>> {
  images: ({ file: File } | { id: number; url: string })[];
  defaultImage: { file: File } | { id: number; url: string } | null;
  client: {
    firstName?: string;
    lastName?: string;
    registrationNumber?: string;
    email?: string;
    confirmationTypeId?: number;
  };
}
