import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit';
type ValidationErrors = Partial<{ [key in keyof FormData]: string[] }>;
interface State {
  validationErrors: ValidationErrors | null;
}

const communicationLogAdapter = createEntityAdapter<any>();

const initialState = communicationLogAdapter.getInitialState({
  validationErrors: null,
} as State);

const slice = createSlice({
  name: 'communicationLog',
  initialState,
  reducers: {
    setValidationErrors(state, action: PayloadAction<ValidationErrors | null>) {
      state.validationErrors = action.payload;
    },
  },
});

export const { actions } = slice;
export default slice.reducer;

export const selectors = {
  validationErrors: state => state.communicationLog.validationErrors,
};
