import camelCase from 'lodash/camelCase';
import User from './User';
import PersonEducation from './PersonEducation';
import CertificateType from './CertificateType';

export interface PersonCertificate {
  id: number;
  person_id: number;
  certificate_type_id: number;
  certificate_issuer: string;
  certificate_name: string;
  certificate_number: number;
  valid_from: string;
  valid_to: string;
  certificate_type: CertificateType;
}

export default class Person {
  public readonly id: number;
  public readonly firstName: string;
  public readonly lastName: string;
  public readonly title: string;
  public readonly birthYear: string;
  public readonly phone: string;
  public readonly contactLanguage: string;
  public readonly createdAt: string;
  public readonly updatedAt: string;
  public readonly deletedAt: string;
  public readonly certificateRoles: string[];
  public readonly personCertificates: PersonCertificate[];

  public readonly personEducations: PersonEducation[];

  public readonly user?: User;

  constructor(data) {
    Object.keys(data).forEach(k => {
      this[camelCase(k)] = data[k];
    });

    this.personEducations = this.personEducations
      ? this.personEducations.map(education => new PersonEducation(education))
      : [];
  }

  get name() {
    return [this.firstName, this.lastName].filter(v => v).join(' ');
  }

  get displayName() {
    if (this.name && this.user && this.user.email) {
      return `${this.name} (${this.user.email})`;
    }

    if ((!this.user || !this.user.email) && !this.name) return null;
    return this.user && this.user.email ? this.user.email : this.name;
  }

  get nameInitials() {
    if (this.firstName && this.lastName) {
      return `${this.firstName.substring(0, 1)}${this.lastName.substring(
        0,
        2
      )}`.toUpperCase();
    }
    return this.firstName.toUpperCase();
  }
}

export interface UserModalFields {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  title: string | null;
  contactLanguage: 'fi' | 'sv' | 'en' | string;
}
