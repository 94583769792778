import { createBrowserHistory } from 'history';
import { compose } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import createSagaMiddleware from 'redux-saga';
import * as Sentry from '@sentry/react';

import rootSaga from './rootSaga';
import rootReducer from './rootReducer';
import { applyStoreToApi } from './api';
import { monoApi } from '~services/mono';
import { ralaNewsFeedApi } from '~services/ralaNewsFeed';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const store = configureStore({
  reducer: {
    ...rootReducer,
    [monoApi.reducerPath]: monoApi.reducer,
    [ralaNewsFeedApi.reducerPath]: ralaNewsFeedApi.reducer,
  },
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware({
      serializableCheck: false,
    }).concat(sagaMiddleware, monoApi.middleware, ralaNewsFeedApi.middleware)
  },
  enhancers: getDefaultEnhancers => {
    return getDefaultEnhancers().concat(sentryReduxEnhancer)
  }
});

sagaMiddleware.run(rootSaga);

applyStoreToApi(store);

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;

export default store;
