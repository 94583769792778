/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MessageDescriptor } from 'react-intl';
import { all, delay, put, takeEvery } from 'redux-saga/effects';
import { v4 as uuid } from 'uuid';

import { RootState } from '../rootReducer';

export enum ToastType {
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
  Danger = 'danger',
  Error = 'error',
}

export class Toast {
  id: string;
  type: ToastType;
  message: string | MessageDescriptor;
  duration: number;

  constructor({
    message,
    type = ToastType.Info,
    duration = 5000, // ms
  }) {
    this.id = uuid();
    this.type = type;
    this.duration = duration;
    this.message = message;
  }
}

interface State {
  toasts: Toast[];
}

const slice = createSlice({
  name: 'toaster',
  initialState: {
    toasts: [],
  } as State,
  reducers: {
    toast(state, action: PayloadAction<Toast>) {
      const toast = action.payload;
      toast.id = toast.id + '-' + new Date().getTime();
      state.toasts.push(toast);
    },
    remove(state, action: PayloadAction<Toast>) {
      const toast = action.payload;
      state.toasts = state.toasts.filter(t => t.id !== toast.id);
    },
  },
});

export const { actions } = slice;
export default slice.reducer;

export const selectors = {
  toasts: (state: RootState) => state.toaster.toasts,
};

export function* toast(action: ReturnType<typeof actions.toast>) {
  const toast = action.payload;
  yield delay(toast.duration);
  yield put(actions.remove(toast));
}

export function* toastSaga() {
  yield all([takeEvery(actions.toast, toast)]);
}
