/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { defineMessages } from 'react-intl';

import { COMMON, addMappingItem } from '../common';
import { actions as toastActions, Toast, ToastType } from '../toaster';

import * as api from './api';

import CompanyUnit from '../../models/CompanyUnit';
import CompanyRelationship, {
  COMPANY_RELATIONSHIP_TYPES,
} from '../../models/CompanyRelationship';
import { RootState } from '../rootReducer';
import { APIError } from '../api';

const messages = defineMessages({
  updateUnitSuccess: {
    id: 'company-unit-update-success-toast',
    defaultMessage: 'Yksikköä muokattu',
  },
  destroyUnitSuccess: {
    id: 'company-unit-destroy-success-toast',
    defaultMessage: 'Yksikkö poistettu',
  },
  createRelationshipJointSuccess: {
    id: 'company-relationship-joint-create-success-toast',
    defaultMessage: 'Liitytty työyhteenliittymään',
  },
  destroyRelationshipJointSuccess: {
    id: 'company-relationship-joint-destroy-success-toast',
    defaultMessage: 'Poistuttu työyhteenliittymästä',
  },
  createRelationshipDaughterSuccess: {
    id: 'company-relationship-daughter-create-success-toast',
    defaultMessage: 'Tytäryhtiö liitetty',
  },
  destroyRelationshipDaughterSuccess: {
    id: 'company-relationship-daughter-destroy-success-toast',
    defaultMessage: 'Tytäryhtiön liitos poistettu',
  },
});

interface Search<T> {
  data: T[];
  meta: any;
}

interface State {
  companiesToUnits: { [id: string]: string[] };
  companiesToRelationships: { [id: string]: string[] };
  units: { [id: string]: CompanyUnit };
  unitsSearch: Search<CompanyUnit>;
  relationships: { [id: string]: CompanyRelationship };
  relationshipSearch: Search<CompanyRelationship>;
  loading: { [type: string]: boolean };
  error: { [type: string]: APIError | null };
}

const slice = createSlice({
  name: 'relations',
  initialState: {
    companiesToUnits: {},
    companiesToRelationships: {},
    units: {},
    unitsSearch: {
      data: [],
      meta: null,
    },
    relationships: {},
    relationshipSearch: {
      data: [],
      meta: null,
    },
    loading: {},
    error: {},
  } as State,
  reducers: {
    fetchRelationship(state, action: PayloadAction<any>) {},

    receiveRelationship(
      state,
      action: PayloadAction<CompanyRelationship | CompanyRelationship[]>
    ) {
      ([] as CompanyRelationship[]).concat(action.payload).forEach(cr => {
        state.relationships[cr.id] = new CompanyRelationship(cr);
        addMappingItem(
          state.companiesToRelationships,
          cr.company_relationship_type_id ===
            COMPANY_RELATIONSHIP_TYPES.JOINT_VENTURE
            ? cr.daughter_company.id
            : cr.parent_company.id,
          cr
        );
      });
    },

    relationshipsSearch(
      state,
      action: PayloadAction<{ companyId: string; params?: any }>
    ) {},

    receiveRelationshipSearch(state, action) {
      state.relationshipSearch = {
        data: action.payload.data.map(cr => new CompanyRelationship(cr)),
        meta: action.payload.meta,
      };
    },

    destroyRelationship(
      state,
      action: PayloadAction<{ companyId: string; relationshipId: string }>
    ) {},

    loading(state, action) {
      const type = action.payload;
      state.loading[type] = true;
      state.error[type] = null;
    },

    success(state, action) {
      const type = action.payload;
      state.loading[type] = false;
      state.error[type] = null;
    },

    error(state, action) {
      const { type, error } = action.payload;
      state.loading[type] = false;
      state.error[type] = error;
    },
  },
  extraReducers: builder => {
    builder.addCase(COMMON.NAVIGATE, state => {
      Object.keys(state.error).forEach(k => {
        state.error[k] = null;
      });
    })
  },
});

export const { actions } = slice;
export default slice.reducer;

export const selectors = {
  relationships: (state: RootState, companyId: string) => {
    if (!companyId) return state.relations.relationships;

    const ids = state.relations.companiesToRelationships[companyId];
    if (!ids) return {};

    return ids.reduce((acc, val) => {
      const cr = state.relations.relationships[val];
      if (!cr) return acc;

      return { ...acc, [cr.id]: cr };
    }, {});
  },
  relationshipSearch: (state: RootState) => state.relations.relationshipSearch,
  loading: (state: RootState, key?) =>
    key ? !!state.relations.loading[key] : state.relations.loading,
  error: (state: RootState, key?) =>
    key ? state.relations.error[key] || null : state.relations.error,
};

export function* fetchRelationship(
  action: ReturnType<typeof actions.fetchRelationship>
) {
  const { type, payload } = action;
  const { companyId, relationshipId } = payload;

  try {
    yield put(actions.loading(type));

    const response = yield call(
      api.fetchRelationship,
      companyId,
      relationshipId
    );
    yield put(actions.receiveRelationship(response.data));

    yield put(actions.success(type));
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* relationshipsSearch(
  action: ReturnType<typeof actions.relationshipsSearch>
) {
  const { type, payload } = action;
  const { companyId, params } = payload;

  try {
    yield put(actions.loading(type));

    const response = yield call(api.relationshipsSearch, companyId, params);
    yield put(actions.receiveRelationshipSearch(response));

    yield put(actions.success(type));
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* destroyRelationship(
  action: ReturnType<typeof actions.destroyRelationship>
) {
  const { type, payload } = action;

  try {
    yield put(actions.loading(type));

    const response = yield call(api.destroyRelationship, payload);

    yield put(actions.success(type));
    yield put(push(`/companies/${payload.companyId}/relations`));
    yield put(
      toastActions.toast(
        new Toast({
          message:
            response.data.type.id === COMPANY_RELATIONSHIP_TYPES.JOINT_VENTURE
              ? messages.destroyRelationshipJointSuccess
              : messages.destroyRelationshipDaughterSuccess,
          type: ToastType.Success,
        })
      )
    );
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* relationsSaga() {
  yield all([
    yield takeEvery(actions.fetchRelationship.type, fetchRelationship),
    yield takeLatest(actions.relationshipsSearch.type, relationshipsSearch),
    yield takeEvery(actions.destroyRelationship.type, destroyRelationship),
  ]);
}
