import camelCase from 'lodash/camelCase';
import ApplicationAnswer from './ApplicationAnswer';
import CertificateAuditReportObservation from './CertificateAuditReportObservation';

export default class ApplicationQuestion {
  public readonly id: number;
  public readonly applicationQuestionaireId: number;
  public readonly symbolicName: string;
  public readonly text: string;
  public readonly description: string;
  public readonly answer?: ApplicationAnswer;
  public readonly initialAnswer?: ApplicationAnswer;
  public readonly observation?: CertificateAuditReportObservation;
  public readonly observationHistory?: CertificateAuditReportObservation[];
  public readonly criteriaGroup: number;

  constructor(data) {
    Object.keys(data).forEach(k => {
      this[camelCase(k)] = data[k];
    });
    this.answer = this.answer ? new ApplicationAnswer(this.answer) : undefined;
    this.initialAnswer = this.initialAnswer
      ? new ApplicationAnswer(this.initialAnswer)
      : undefined;
    this.observation = this.observation
      ? new CertificateAuditReportObservation(this.observation)
      : undefined;
    this.observationHistory = this.observationHistory
      ? this.observationHistory.map(
          o => new CertificateAuditReportObservation(o)
        )
      : undefined;
  }
}
