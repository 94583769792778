/* eslint-disable import/prefer-default-export */
import api from '../api';

export function fetchCompanyAgreements(companyId) {
  return api.get(`/companies/${companyId}/agreements`);
}

export function terminateAgreement({
  companyId,
  agreementId,
  terminateReason,
}) {
  return api.post(
    `/companies/${companyId}/agreements/${agreementId}/terminate`,
    {
      terminate_reason: terminateReason,
    }
  );
}
