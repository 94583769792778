import api from '../api';

export function fetchMessage(messageId) {
  return api.get(`/messages/${messageId}`);
}

export function fetchMessages(params) {
  return api.get('/messages', { params });
}

export function fetchRecipientSuggestions(companyId) {
  return api.get(`/companies/${companyId}/messages/recipients`);
}

export function fetchRalaSuggestions() {
  return api.get('/messages/recipients');
}

export function createMessage(data) {
  return api.post('/messages', data);
}

export function deleteMessage({ companyId, messageId }) {
  return api.delete(`/messages/${messageId}`);
}
