/* eslint-disable no-param-reassign */
import { all, call, select, put, takeEvery } from 'redux-saga/effects';

import reducer, { actions, selectors } from './slice';

import * as api from './api';

import {
  actions as addressesActions,
  createCompanyAddress,
} from '../addresses';

import { selectors as userSelectors } from '../user';
import Address from '~models/Address';
import { asSnakeCase } from '~common/helpers';

export { actions, selectors };
export default reducer;

export function* create(action) {
  const { type } = actions.create;

  try {
    const user = yield select(userSelectors.currentUser);
    yield put(actions.loading(type));

    const response = yield call(api.create, action.payload);
    yield put(
      actions.receivePersonCompanies({
        personId: user.person.id,
        companies: [response.data],
      })
    );
    // Backend requires certain fields as snake_case
    const addresses = yield all(
      action.payload.addresses.map(a =>
        call(
          createCompanyAddress,
          addressesActions.createCompanyAddress({
            companyId: response.data.id,
            address: asSnakeCase(a),
          })
        )
      )
    );

    const errors = addresses.filter(a => !(a instanceof Address));
    if (errors.length) {
      yield put(actions.error({ type, error: errors[0], errors }));
      return;
    }

    yield put(actions.success(type));
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* joinRequest(action) {
  const { type } = actions.joinRequest;
  const { companyId } = action.payload;
  try {
    yield put(actions.loading(type));

    const response = yield call(api.joinRequest, companyId);
    yield put(actions.receiveJoinRequest(response.data));

    yield put(actions.success(type));
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* companiesSaga() {
  yield all([
    yield takeEvery(actions.create.type, create),
    yield takeEvery(actions.joinRequest.type, joinRequest),
  ]);
}
