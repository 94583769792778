/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { COMMON, addMappingItem } from '../common';

import * as api from './api';
import Address from '~models/Address';
import Description from '~models/Description';

const slice = createSlice({
  name: 'addresses',
  initialState: {
    addresses: {},
    addressesList: [],
    companiesToAddresses: {},
    companyOfficesToAddresses: {},
    companyUnitsToAddresses: {},
    personAddresses: [],
    addressTypes: [] as Description[],
    loading: {},
    error: {},
  },
  reducers: {
    receiveCompanyAddresses(state, action) {
      const { addresses, companyId } = action.payload;
      state.addressesList = addresses.map(a => new Address(a));
      addresses.forEach(a => {
        state.addresses[a.id] = new Address(a);
        addMappingItem(state.companiesToAddresses, companyId, a);
      });
    },

    createCompanyAddress(
      state,
      action: PayloadAction<{ companyId: string; address: string }>
    ) {},

    loading(state, action) {
      const type = action.payload;
      state.loading[type] = true;
      state.error[type] = null;
    },

    success(state, action) {
      const type = action.payload;
      state.loading[type] = false;
      state.error[type] = null;
    },

    error(state, action) {
      const { type, error } = action.payload;
      state.loading[type] = false;
      state.error[type] = error;
    },
  },
  extraReducers: builder => {
    builder.addCase(COMMON.NAVIGATE, state => {
      Object.keys(state.error).forEach(k => {
        state.error[k] = null;
      });
    })
  },
});

export const { actions } = slice;
export default slice.reducer;

export function* createCompanyAddress(action) {
  const { type } = actions.createCompanyAddress;
  const typeWithId = `${type}_${action.payload.address.id}`;

  try {
    yield put(actions.loading(type));
    yield put(actions.loading(typeWithId));

    const response = yield call(api.createCompanyAddress, action.payload);
    yield put(
      actions.receiveCompanyAddresses({
        companyId: action.payload.companyId,
        addresses: [response.data],
      })
    );

    yield put(actions.success(type));
    yield put(actions.success(typeWithId));

    return new Address(response.data);
  } catch (error) {
    yield put(actions.error({ type, error }));
    yield put(actions.error({ type: typeWithId, error }));

    return error;
  }
}

export function* addressesSaga() {
  yield all([
    yield takeEvery(actions.createCompanyAddress.type, createCompanyAddress),
  ]);
}
