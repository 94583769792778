import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit';
import { CERTIFICATE_TYPES } from '~/features/common/enums';
import ApplicationIndustry from '~/models/ApplicationIndustry';
import CertificatePlan from '~/models/CertificatePlan';
import CompanyOffice from '~/models/CompanyOffice';
import { RootState } from '~store/index';
type Modal =
  | 'headAuditorModal'
  | 'secondaryAuditorModal'
  | 'industryModal'
  | 'industryAuditModal'
  | 'officeAuditModal'
  | 'officeModal'
  | 'listing';
interface State {
  formData: {
    companyOfficeId: number | null;
    auditorId: number | null;
    programId: number | null;
    currentClassificationIndustry: number | null;
    modalState: Modal;
    isItemGranted: boolean;
    certificatePlan: CertificatePlan | null;
    certificateType: CERTIFICATE_TYPES | null;
  };
  industrySectionFormData: {
    applicationIndustries: ApplicationIndustry[] | null;
    industries: CertificatePlan[] | null;
  };
  officeSectionFormData: {
    applicationOffices: CompanyOffice[] | null;
    offices: CertificatePlan[] | null;
  };
  certificatePlans:
    | {
        2: CertificatePlan[];
        5: CertificatePlan[];
        20: CertificatePlan[];
        23: CertificatePlan[];
      }
    | undefined;
}

const certificatePlanAdapter = createEntityAdapter<any>();

const initialState = certificatePlanAdapter.getInitialState({
  formData: {
    companyOfficeId: null,
    auditorId: null,
    programId: null,
    currentClassificationIndustry: null,
    modalState: 'listing',
    isItemGranted: false, // If the item is found in the original list for that year then that item is granted.
    certificatePlan: null,
    certificateType: null,
  },
  industrySectionFormData: {
    applicationIndustries: null,
    industries: null,
  },
  officeSectionFormData: {
    applicationOffices: null,
    offices: null,
  },
  certificatePlans: {
    2: [],
    5: [],
    20: [],
    23: [],
  },
} as State);

const slice = createSlice({
  name: 'certificatePlan',
  initialState,
  reducers: {
    setFormField(state, action: PayloadAction<any>) {
      state.formData = { ...state.formData, ...action.payload };
    },
    setIndustrySectionFormData(state, action: PayloadAction<any>) {
      state.industrySectionFormData = {
        ...state.industrySectionFormData,
        ...action.payload,
      };
    },
    setOfficeSectionFormData(state, action: PayloadAction<any>) {
      state.officeSectionFormData = {
        ...state.officeSectionFormData,
        ...action.payload,
      };
    },
    setModalState(state, action: PayloadAction<Modal>) {
      if (action.payload === 'listing') {
        state.formData = initialState.formData;
        state.industrySectionFormData = initialState.industrySectionFormData;
        state.officeSectionFormData = initialState.officeSectionFormData;
      } else {
        state.formData.modalState = action.payload;
      }
    },
    setCertificatePlans(state, action: PayloadAction<any>) {
      state.certificatePlans = { ...state.certificatePlans, ...action.payload };
    },
  },
});

export const { actions } = slice;
export default slice.reducer;

export const selectors = {
  formData: (state: RootState) => state.certificatePlan.formData,
  industrySectionFormData: (state: RootState) =>
    state.certificatePlan.industrySectionFormData,
  officeSectionFormData: (state: RootState) =>
    state.certificatePlan.officeSectionFormData,
  certificatePlans: (state: RootState) =>
    state.certificatePlan.certificatePlans,
};
