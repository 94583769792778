import { camelCase } from 'lodash';
import { ELECTRONIC_ADDRESS_TYPES, ADDRESS_TYPES } from '~common/enums';

const TYPES_TO_COMPONENTS = {
  [ADDRESS_TYPES.POSTAL_STREET]: [['street'], ['zipcode', 'city'], ['country']],

  [ADDRESS_TYPES.POSTAL_POBOX]: [['poBox'], ['zipcode', 'city'], ['country']],

  [ADDRESS_TYPES.VISITING_FINNISH]: [
    ['street'],
    ['zipcode', 'city'],
    ['country'],
  ],

  [ADDRESS_TYPES.VISITING_FOREIGN]: [
    ['addressLine1'],
    ['addressLine2'],
    ['addressLine3'],
    ['addressLine4'],
    ['country'],
  ],

  [ADDRESS_TYPES.POSTAL_FOREIGN]: [
    ['addressLine1'],
    ['addressLine2'],
    ['addressLine3'],
    ['addressLine4'],
    ['country'],
  ],

  [ADDRESS_TYPES.WEB]: [['electronicAddress']],

  [ADDRESS_TYPES.EMAIL]: [['electronicAddress']],

  [ADDRESS_TYPES.LINKEDIN]: [['electronicAddress']],

  [ADDRESS_TYPES.TWITTER]: [['electronicAddress']],

  [ADDRESS_TYPES.FACEBOOK]: [['electronicAddress']],

  [ADDRESS_TYPES.INSTAGRAM]: [['electronicAddress']],

  [ADDRESS_TYPES.BILLING_FINNISH]: [
    ['street'],
    ['zipcode', 'city'],
    ['country'],
  ],

  [ADDRESS_TYPES.BILLING_FOREIGN]: [
    ['addressLine1'],
    ['addressLine2'],
    ['addressLine3'],
    ['addressLine4'],
    ['country'],
  ],

  [ADDRESS_TYPES.BILLING_ELECTRONIC_FINNISH]: [
    ['electronicBillingAddress'],
    ['electronicBillingOperatorId'],
    ['electronicBillingOperator'],
  ],
};

const TYPES_TO_REQUIRED_FIELDS = {
  [ADDRESS_TYPES.POSTAL_STREET]: ['street', 'zipcode', 'city', 'country'],
  [ADDRESS_TYPES.POSTAL_POBOX]: ['poBox', 'zipcode', 'city', 'country'],
  [ADDRESS_TYPES.VISITING_FINNISH]: ['street', 'zipcode', 'city', 'country'],
  [ADDRESS_TYPES.VISITING_FOREIGN]: ['addressLine1', 'country'],
  [ADDRESS_TYPES.POSTAL_FOREIGN]: ['addressLine1', 'country'],
  [ADDRESS_TYPES.WEB]: ['electronicAddress'],
  [ADDRESS_TYPES.EMAIL]: ['electronicAddress'],
  [ADDRESS_TYPES.LINKEDIN]: ['electronicAddress'],
  [ADDRESS_TYPES.TWITTER]: ['electronicAddress'],
  [ADDRESS_TYPES.BILLING_FINNISH]: ['street', 'zipcode', 'city', 'country'],
  [ADDRESS_TYPES.BILLING_FOREIGN]: ['addressLine1', 'country'],
  [ADDRESS_TYPES.BILLING_ELECTRONIC_FINNISH]: [
    'electronicBillingAddress',
    'electronicBillingOperatorId',
    'electronicBillingOperator',
  ],
};

// Billing operators are a mess. Multiple operators can have the same operator id.
// Operators can have multiple operator ids. Ids and operators can change
// at some point. Basically this object should match the list shown in
// TIEKE website
// (https://tieke.fi/palvelut/liiketoimintapalvelut/verkkolaskuosoitteisto/valittajatunnukset/,
// although I would not be surprised if the url changed at some point as well).
export const billingOperators = {
  Aktia: 'HELSFIHH',
  'Danske Bank': 'DABAFIHH',
  DNB: 'DNBAFIHX',
  Handelsbanken: 'HANDFIHH',
  'Nordea Pankki': 'NDEAFIHH',
  'Oma Säästöpankki Oyj': 'ITELFIHH',
  'Osuuspankit ja Pohjola Pankki': 'OKOYFIHH',
  'POP Pankki': 'POPFFI22',
  'S-Pankki': 'SBANFIHH',
  'S-Pankki (ent. LähiTapiola Pankki)': 'TAPIFI22',
  Säästöpankit: 'ITELFIHH',
  Ålandsbanken: 'AABAFI22',
  '4US Oy': 'UTMOST',
  'Apix Messaging Oy': '003723327487',
  'Basware Oyj': 'BAWCFI22',
  'CGI Suomi Oy': '003703575029',
  Comarch: '5909000716438',
  'Crediflow AB': 'CREDIFLOW',
  Dynatos: 'ROUTTY',
  'HighJump AS': '885790000000418',
  'InExchange Factorum AB': 'INEXCHANGE',
  'Lexmark Expert Systems AB': 'EXPSYS',
  Maventa: '003721291126',
  'Netbox Finland Oy': '003726044706',
  'Opentext Oy': '003708599126',
  'OpusCapita Solutions Oy': 'E204503',
  'Pagero Oy': '003723609900',
  'Palette Software Ab': 'PALETTE',
  'Posti Messaging Oy': 'FI28768767',
  'PostNord Strålfors Oy': '003701150617',
  'Ropo Capital Oy': '003714377140',
  'Telia Finland Oyj': '003703575029',
  'TietoEvry Oyj': '003701011385',
  'Tradeshift Ab': '885060259470028',
  'Ålands Post Ab': '003722207029',
};

export default class Address {
  public readonly id: number;
  public readonly addressTypeId: ADDRESS_TYPES;
  public readonly street: string;
  public readonly addressLine1: string;
  public readonly addressLine2: string;
  public readonly addressLine3: string;
  public readonly addressLine4: string;
  public readonly zipcode: string;
  public readonly city: string;
  public country: string;
  public readonly electronicAddress: string;
  public readonly location: string;
  public creating: boolean;
  // Address has these three fields if its type is ADDRESS_TYPES.BILLING_ELECTRONIC_FINNISH
  // Should probably refactor Address into more specific subclasses at some point
  public readonly electronicBillingAddress?: string;
  public readonly electronicBillingOperator?: string;
  public readonly electronicBillingOperatorId?: string;

  constructor(data) {
    Object.keys(data).forEach(k => {
      this[camelCase(k)] = data[k];
    });

    if (!this.country) {
      this.country = 'FI';
    }
  }

  fields() {
    const components = TYPES_TO_COMPONENTS[this.addressTypeId] || [
      Object.getOwnPropertyNames(this),
    ];

    return ([] as string[]).concat(...components);
  }

  requiredFields() {
    return TYPES_TO_REQUIRED_FIELDS[this.addressTypeId];
  }

  isFilled() {
    return TYPES_TO_REQUIRED_FIELDS[this.addressTypeId].every(
      field => this[field]
    );
  }

  canBeRemovedFromCompany() {
    if (this.id === undefined || Number(this.id) <= 0) {
      return false;
    }

    if (
      Number(this.addressTypeId) === ADDRESS_TYPES.BILLING_ELECTRONIC_FINNISH
    ) {
      if (
        this.electronicBillingAddress !== null &&
        this.electronicBillingAddress?.length === 0
      ) {
        return true;
      }
    }

    if (ELECTRONIC_ADDRESS_TYPES.includes(this.addressTypeId)) {
      if (
        this.electronicAddress !== null &&
        this.electronicAddress.length === 0
      ) {
        return true;
      }
    }

    return false;
  }

  toString() {
    const components = TYPES_TO_COMPONENTS[this.addressTypeId] || [
      Object.getOwnPropertyNames(this),
    ];

    return components
      .map(sub =>
        sub
          .map(c => this[c])
          .filter(c => c)
          .join(' ')
      )
      .filter(c => c)
      .join(', ');
  }
}

// Following types are used in form data, form structure differs somewhat
// from the objects returned by backend
export interface StreetAddressFields<Country extends string = string> {
  id?: number;
  country: Country;
  street: string;
  zipcode: string;
  city: string;
}

export type BillingAddress<
  BillingType extends 'paper' | 'electronic' = 'paper' | 'electronic',
  Country extends string = string
> = BillingType extends 'paper'
  ? StreetAddressFields<Country> & {
      addressTypeId: Country extends 'FI'
        ? ADDRESS_TYPES.BILLING_FINNISH
        : ADDRESS_TYPES.BILLING_FOREIGN;
    }
  : {
      electronicBillingAddress: string;
      electronicBillingOperator: string;
      electronicBillingOperatorId: string;
      addressTypeId: ADDRESS_TYPES.BILLING_ELECTRONIC_FINNISH;
    };

export type PostalAddress = StreetAddressFields & {
  addressTypeId: ADDRESS_TYPES.POSTAL_STREET;
};

export type VisitingAddress = StreetAddressFields & {
  addressTypeId: ADDRESS_TYPES.VISITING_FINNISH;
};

export type ElectronicAddress<T extends keyof ADDRESS_TYPES> = {
  electronicAddress: string;
  addressTypeId: ADDRESS_TYPES[T];
};
