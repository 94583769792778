import { orderBy } from 'lodash';
import camelCase from 'lodash/camelCase';
import ApplicationReferenceRelatedIndustry from './ApplicationReferenceRelatedIndustry';
import ClassificationIndustry from './ClassificationIndustry';
import CompanyReference from './CompanyReference';
import IndustryRequirement from './IndustryRequirement';
export default class ApplicationIndustry {
  public readonly id: string;
  public readonly applicationId: string;
  public readonly classificationIndustryId: string;
  public readonly granted: boolean;
  public readonly audited: boolean;
  public readonly createdAt: string;
  public readonly updatedAt: string;
  public readonly deletedAt: string;
  public readonly classificationIndustry?: ClassificationIndustry;
  public readonly requirements?: IndustryRequirement;
  public readonly references: CompanyReference[];
  public readonly applicationReferenceRelatedIndustries: ApplicationReferenceRelatedIndustry[];
  public readonly new: number;

  constructor(data) {
    Object.keys(data).forEach(k => {
      this[camelCase(k)] = data[k];
    });
    this.classificationIndustry = this?.classificationIndustry
      ? new ClassificationIndustry(this?.classificationIndustry)
      : undefined;
    this.requirements = this?.requirements
      ? new IndustryRequirement(this?.requirements)
      : undefined;
    this.references = this?.references?.map(o => new CompanyReference(o)) ?? [];
    this.applicationReferenceRelatedIndustries =
      orderBy(
        this?.applicationReferenceRelatedIndustries || [],
        [
          applicationReferenceRelatedIndustry =>
            applicationReferenceRelatedIndustry?.reference?.work_end_date,
        ],
        ['desc']
      )?.map(o => new ApplicationReferenceRelatedIndustry(o)) ?? [];
  }

  get requiredCertificateIds(): number[] {
    return this.requirements
      ? [[]].concat(this.requirements).reduce((collection, requirement) => {
          if (requirement.personCertificateTypeIds) {
            return collection.concat(requirement.personCertificateTypeIds);
          }
          return collection;
        })
      : [];
  }

  get requiredEducationDegreeIds(): number[] {
    return this.requirements
      ? [[]].concat(this.requirements).reduce((collection, requirement) => {
          if (requirement.educationDegreeIds) {
            return collection.concat(requirement.educationDegreeIds);
          }
          return collection;
        })
      : [];
  }

  get classificationIndustryFullName(): string {
    return (
      this.classificationIndustry?.symbolicNumber +
      ' ' +
      this.classificationIndustry?.shortDescription
    );
  }

  get isIndustryGrantedInPreviousApplications(): boolean {
    return this.new < 1 && this.granted;
  }
}
