/* eslint-disable import/prefer-default-export */
import api from '../api';

export function fetchAgreements(companyId) {
  return api.get(`/companies/${companyId}/agreements`);
}

export function createAgreement(companyId, data) {
  return api.post(`/companies/${companyId}/agreements`, data);
}
