/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  createSlice,
  PayloadAction,
  createEntityAdapter,
} from '@reduxjs/toolkit';

import CertificateAuditPlan from '~models/CertificateAuditPlan';

type FormState =
  | 'listing'
  | 'addNewCompanyModal'
  | 'historyModal'
  | 'certAuditorsModal'
  | 'stateModal'
  | 'eventModal'
  | 'generateEventModal'
  | 'companyCommentModal'
  | 'acceptProposalsModal';

interface State {
  formState: FormState;
}

const auditPlanAdapter = createEntityAdapter<CertificateAuditPlan>();

const initialState = auditPlanAdapter.getInitialState({
  formState: 'listing',
} as State);

const slice = createSlice({
  name: 'auditPlans',
  initialState,
  reducers: {
    setFormState(state, action) {
      const formState = action.payload;
      state.formState = action.payload;
    },
  },
});

export const { actions } = slice;
export default slice.reducer;

const adapterSelectors = auditPlanAdapter.getSelectors(
  (state: any) => state.auditPlans
);

export const selectors = {
  formState: state => state.auditPlans.formState,
};
