import camelCase from 'lodash/camelCase';
import Person from './Person';
import ApplicationQuestion from './ApplicationQuestion';

export default class CertificateAuditReportObservation {
  public readonly id: number;
  public readonly applicationId: number;
  public readonly applicationQuestionId: number;
  public readonly certificateAuditReportObservedPartStatusId: number;
  public readonly strengths: string;
  public readonly developmentAreas: string;
  public readonly score: number;
  public readonly hasDeviation: boolean;
  public readonly reviewerPerson?: Person;
  public readonly createdAt: string;
  public readonly updatedAt: string;
  public readonly deletedAt: string;
  public readonly question?: ApplicationQuestion;

  constructor(data) {
    Object.keys(data).forEach(k => {
      this[camelCase(k)] = data[k];
    });
    this.reviewerPerson = this.reviewerPerson
      ? new Person(this.reviewerPerson)
      : undefined;

    this.question = this.question
      ? new ApplicationQuestion(this.question)
      : undefined;
  }
}
