/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  createSlice,
  PayloadAction,
  EntityState,
  createEntityAdapter,
} from '@reduxjs/toolkit';

import { COMMON } from '../common';

import { RootState } from '../rootReducer';
import { APIError } from '../api';
import * as api from './api';

import ProductAgreement from '~models/ProductAgreement';

const productAgreementsAdapter = createEntityAdapter<ProductAgreement>({
  selectId: ProductAgreement => ProductAgreement.id,
});

const initialState = productAgreementsAdapter.getInitialState({
  loading: {},
  error: {},
});

const slice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    fetchAgreements() {},

    receiveAgreements(state, action) {
      productAgreementsAdapter.setAll(state, action.payload);
    },

    loading(state, action: PayloadAction<string>) {
      const type = action.payload;
      state.loading[type] = true;
      state.error[type] = null;
    },

    success(state, action: PayloadAction<string>) {
      const type = action.payload;
      state.loading[type] = false;
      state.error[type] = null;
    },

    error(state, action: PayloadAction<{ type: string; error: APIError }>) {
      const { type, error } = action.payload;
      state.loading[type] = false;
      state.error[type] = error;
    },

    clearError(state, action: PayloadAction<string>) {
      const type = action.payload;
      state.error[type] = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(COMMON.NAVIGATE, state => {
      Object.keys(state.error).forEach(k => {
        state.error[k] = null;
      });
    })
  },
});

export const { actions } = slice;
export default slice.reducer;

const adapterSelectors = productAgreementsAdapter.getSelectors(
  (state: RootState) => state.products
);

export const selectors = {
  agreements: adapterSelectors.selectAll,
  agreement: adapterSelectors.selectById,
  loading: (state, key?) => {
    if (key !== undefined) {
      return !!state.products.loading[key];
    }
    return state.products.loading;
  },
  error: (state, key?) =>
    key ? state.products.error[key] || null : state.products.error,
};

export function* fetchAgreements(
  action: ReturnType<typeof actions.fetchAgreements>
) {
  const { type, payload } = action;
  try {
    yield put(actions.loading(type));

    const response = yield call(api.fetchProducts);
    const productAgreements = [];
    for (const product of response.data) {
      const agreement = yield call(api.fetchProductAgreement, product.id);
      productAgreements.push(new ProductAgreement(agreement.data[0]));
    }

    yield put(actions.receiveAgreements(productAgreements));

    yield put(actions.success(type));
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* productsSaga() {
  yield all([takeEvery(actions.fetchAgreements.type, fetchAgreements)]);
}
