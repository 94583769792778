import { put, call, all, takeEvery } from 'redux-saga/effects';
import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit';

import * as api from './api';
import { APPLICATION_FILE_TYPES } from '~features/common/enums';
import { RootState } from '~store/rootReducer';

type Id = string;

const applicationFileAdapter =
  createEntityAdapter<{
    applicationId: Id;
    file: File;
    fileType: APPLICATION_FILE_TYPES;
  }>();

const initialState = applicationFileAdapter.getInitialState({
  loading: {},
  error: {},
});

const slice = createSlice({
  name: 'applicationFiles',
  initialState,
  reducers: {
    fetchApplicationFiles(
      state,
      action: PayloadAction<{ companyId: string; applicationId: string }>
    ) {},

    createApplicationFiles(
      state,
      action: PayloadAction<{
        companyId: string;
        applicationId: string;
        files: {
          qualityManualFiles: File[];
          liabilityFiles: File[];
          consultingFiles: File[];
          machineryListFiles: File[];
        };
      }>
    ) {},

    receiveApplicationFiles(state, action: PayloadAction<any>) {
      applicationFileAdapter.setAll(state, action.payload);
    },

    deleteApplicationFiles(
      state,
      action: PayloadAction<{
        companyId: string;
        applicationId: string;
        fileIds: string[];
      }>
    ) {},

    deleteSuccess: applicationFileAdapter.removeOne,

    loading(state, action) {
      const type = action.payload;
      state.loading[type] = true;
      state.error[type] = null;
    },

    success(state, action) {
      const type = action.payload;
      state.loading[type] = false;
      state.error[type] = null;
    },

    error(state, action) {
      const { type, error } = action.payload;
      state.loading[type] = false;
      state.error[type] = error;
    },
  },
});

export const { actions } = slice;
export default slice.reducer;

const adapterSelectors = applicationFileAdapter.getSelectors(
  (state: RootState) => state.applicationFiles
);

export const selectors = {
  applicationFiles: (state, applicationId) =>
    adapterSelectors
      .selectAll(state)
      .filter(f => f.applicationId === applicationId)
      .reduce(
        (
          {
            qualityManualFiles,
            liabilityFiles,
            consultingFiles,
            machineryListFiles,
          },
          { id, filename, ...rest }
        ) => ({
          qualityManualFiles:
            rest.fileType === APPLICATION_FILE_TYPES.QUALITY_MANUAL
              ? [...qualityManualFiles, { id, fileName: filename, ...rest }]
              : qualityManualFiles,
          liabilityFiles:
            rest.fileType === APPLICATION_FILE_TYPES.LIABILITY_INSURANCE
              ? [...liabilityFiles, { id, fileName: filename, ...rest }]
              : liabilityFiles,
          consultingFiles:
            rest.fileType === APPLICATION_FILE_TYPES.CONSULTING_INSURANCE
              ? [...consultingFiles, { id, fileName: filename, ...rest }]
              : consultingFiles,
          machineryListFiles:
            rest.fileType === APPLICATION_FILE_TYPES.MACHINERYLIST
              ? [...machineryListFiles, { id, fileName: filename, ...rest }]
              : machineryListFiles,
        }),
        {
          qualityManualFiles: [],
          liabilityFiles: [],
          consultingFiles: [],
          machineryListFiles: [],
        }
      ),
  loading: (state: RootState, key?) =>
    key
      ? !!state.applicationFiles.loading[key]
      : Object.values(state.applicationFiles.loading).some(v => Boolean(v)),
  error: (state: RootState, key?) =>
    key
      ? state.applicationFiles.error[key] || null
      : state.applicationFiles.error,
};

export function* fetchApplicationFiles(action) {
  const { type } = actions.fetchApplicationFiles;
  const { companyId, applicationId } = action.payload;

  try {
    yield put(actions.loading(type));

    const files = yield call(
      api.fetchApplicationFiles,
      companyId,
      applicationId
    );
    yield put(
      actions.receiveApplicationFiles(
        files.data.map(d => ({
          ...d,
          applicationId,
          fileType: d.file_types[0].id,
          fileName: d.filename,
        }))
      )
    );

    yield put(actions.success(type));
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* createApplicationFiles(action) {
  const { type } = actions.createApplicationFiles;
  const { companyId, applicationId, files } = action.payload;
  const {
    qualityManualFiles,
    liabilityFiles,
    consultingFiles,
    machineryListFiles,
  } = files;
  try {
    yield put(actions.loading(type));

    const newFiles: any[] = [];
    for (const file of qualityManualFiles ?? []) {
      if (!file.id) {
        const res = yield call(
          api.createApplicationFile,
          companyId,
          applicationId,
          file,
          APPLICATION_FILE_TYPES.QUALITY_MANUAL
        );
        newFiles.push(res);
      }
    }

    for (const file of liabilityFiles ?? []) {
      if (!file.id) {
        const res = yield call(
          api.createApplicationFile,
          companyId,
          applicationId,
          file,
          APPLICATION_FILE_TYPES.LIABILITY_INSURANCE
        );
        newFiles.push(res);
      }
    }

    for (const file of consultingFiles ?? []) {
      if (!file.id) {
        const res = yield call(
          api.createApplicationFile,
          companyId,
          applicationId,
          file,
          APPLICATION_FILE_TYPES.CONSULTING_INSURANCE
        );
        newFiles.push(res);
      }
    }

    for (const file of machineryListFiles ?? []) {
      if (file.fileable_type !== 'application' && file.id) {
        const res = yield call(
          api.createDuplicateForApplicationFile,
          companyId,
          applicationId,
          file.id,
          APPLICATION_FILE_TYPES.MACHINERYLIST
        );
        newFiles.push(res);
      } else if (!file.id) {
        const res = yield call(
          api.createApplicationFile,
          companyId,
          applicationId,
          file,
          APPLICATION_FILE_TYPES.MACHINERYLIST
        );
        newFiles.push(res);
      }
    }

    yield put(
      actions.receiveApplicationFiles(
        newFiles.map(d => ({
          ...d,
          applicationId,
          fileType: d.file_types[0].id,
          fileName: d.filename,
        }))
      )
    );

    yield put(actions.success(type));
    return newFiles;
  } catch (error) {
    yield put(actions.error({ type, error }));
    return error;
  }
}

export function* deleteApplicationFiles(action) {
  const { type } = actions.deleteApplicationFiles;
  const { companyId, applicationId, fileIds } = action.payload;

  try {
    yield put(actions.loading(type));

    for (const fileId of fileIds) {
      yield call(api.deleteApplicationFile, companyId, applicationId, fileId);
      yield put(actions.deleteSuccess(fileId));
    }

    yield put(actions.success(type));
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* applicationFileSaga() {
  yield all([
    yield takeEvery(actions.fetchApplicationFiles.type, fetchApplicationFiles),
    yield takeEvery(
      actions.createApplicationFiles.type,
      createApplicationFiles
    ),
    yield takeEvery(
      actions.deleteApplicationFiles.type,
      deleteApplicationFiles
    ),
  ]);
}
