import { defineMessages } from 'react-intl';

export enum ADDRESS_TYPES {
  POSTAL_STREET = 1,
  POSTAL_POBOX = 2,
  VISITING_FINNISH = 3,
  VISITING_FOREIGN = 4,
  POSTAL_FOREIGN = 5,
  WEB = 6,
  EMAIL = 7,
  LINKEDIN = 8,
  TWITTER = 9,
  BILLING_FINNISH = 10,
  BILLING_FOREIGN = 11,
  BILLING_ELECTRONIC_FINNISH = 12,
  COORDINATES = 13,
  FACEBOOK = 14,
  INSTAGRAM = 15,
}

export const ADDRESS_POSTAL_TYPES = [
  ADDRESS_TYPES.POSTAL_STREET,
  ADDRESS_TYPES.POSTAL_POBOX,
  ADDRESS_TYPES.POSTAL_FOREIGN,
];

export const ADDRESS_BILLING_TYPES = [
  ADDRESS_TYPES.BILLING_FINNISH,
  ADDRESS_TYPES.BILLING_FOREIGN,
  ADDRESS_TYPES.BILLING_ELECTRONIC_FINNISH,
];

export const ELECTRONIC_ADDRESS_TYPES = [
  ADDRESS_TYPES.WEB,
  ADDRESS_TYPES.EMAIL,
  ADDRESS_TYPES.LINKEDIN,
  ADDRESS_TYPES.TWITTER,
];

export const ADDRESS_FIELD_TEXTS = defineMessages({
  poBox: {
    id: 'address-field-po-box',
    defaultMessage: 'Postilokero',
  },
  street: {
    id: 'address-field-street',
    defaultMessage: 'Katuosoite',
  },
  zipcode: {
    id: 'address-field-zipcode',
    defaultMessage: 'Postinumero',
  },
  city: {
    id: 'address-field-city',
    defaultMessage: 'Kaupunki',
  },
  country: {
    id: 'address-field-country',
    defaultMessage: 'Valtio',
  },
  electronicBillingAddress: {
    id: 'address-field-electronic-billing-address',
    defaultMessage: 'Sähköinen laskutusosoite',
  },
  electronicBillingOperatorId: {
    id: 'address-field-electronic-billing-operator-id',
    defaultMessage: 'Laskutusoperaattorin tunnus',
  },
  electronicBillingOperator: {
    id: 'address-field-electronic-billing-operator',
    defaultMessage: 'Laskutusoperaattorin nimi',
  },
  electronicAddress: {
    id: 'address-field-electronic-address',
    defaultMessage: 'Sähköinen osoite',
  },
  addressLine1: {
    id: 'address-field-address-line-1',
    defaultMessage: 'Osoiterivi 1',
  },
  addressLine2: {
    id: 'address-field-address-line-2',
    defaultMessage: 'Osoiterivi 2',
  },
  addressLine3: {
    id: 'address-field-address-line-3',
    defaultMessage: 'Osoiterivi 3',
  },
  addressLine4: {
    id: 'address-field-address-line-4',
    defaultMessage: 'Osoiterivi 4',
  },
});

export enum FINANCE_FILE_TYPES {
  BALANCE_SHEET = 1,
  PROFITS_AND_LOSSES = 2,
  AUDIT_REPORT = 3,
  INCOME_STATEMENT = 5,
  PROFITS_AND_LOSSES_AND_BALANCE_SHEET = 9,
}

export enum FixedProductId {
  RALA_QUALIFICATION = 1,
  RALA_QUALITY_CERTIFICATE = 2,
  RALA_FEEDBACK = 3,
  RALA_ENVIRONMENT_CERTIFICATE = 6,
}

export enum APPLICATION_FILE_TYPES {
  QUALITY_MANUAL = 4,
  MACHINERYLIST = 6,
  LIABILITY_INSURANCE = 10,
  CONSULTING_INSURANCE = 11,
  OTHER_QUALITY_MANUAL = 13,
  APPLICATION = 14,
  CERTIFICATION_AUDIT_PROGRAM = 16,
}

export enum FILE_TYPES {
  PDF = 'application/pdf',
  JPEG = 'image/jpeg',
  PNG = 'image/png',
}

export enum USER_PERMISSIONS {
  SYSADMIN = 1,
  RALA_OFFICE = 2,
  RALA_BOARD = 3,
  RALA_AUDIT = 4,
  RALA_AUDIT_CERTIFICATE_MASTER = 5,
  RALA_AUDIT_CERTIFICATE = 6,
}

export const APPLICATION_TABS = defineMessages({
  basicInfo: {
    id: 'application-basic-info',
    defaultMessage: 'Aloitus',
  },
  finances: {
    id: 'application-finances',
    defaultMessage: 'Taloustiedot',
  },
  attachments: {
    id: 'application-attachments',
    defaultMessage: 'Liitteet',
  },
  references: {
    id: 'application-references',
    defaultMessage: 'Toimialat ja referenssit',
  },
  send: {
    id: 'application-send',
    defaultMessage: 'Lähetys',
  },
});

export const COMPANY_PEOPLE_TABS = defineMessages({
  basicInfo: {
    id: 'company-people-person-basic',
    defaultMessage: 'Perustiedot',
  },
  educationInfo: {
    id: 'company-people-person-education',
    defaultMessage: 'Koulutus',
  },
  qualificationInfo: {
    id: 'company-people-person-qualification',
    defaultMessage: 'Pätevyydet',
  },
  role: {
    id: 'company-people-person-roles',
    defaultMessage: 'Käyttäjäroolit',
  },
  rights: {
    id: 'company-people-person-rights',
    defaultMessage: 'Käyttöoikeudet',
  },
});

export const CERTIFICATORS: ReadonlyArray<string> = [
  'EUROFINS Expert Services',
  'FISE',
  'INSPECTA',
  'SETI',
  'TUKES',
];

export enum COMPANY_RIGHTS {
  USER_RIGHTS = 1,
  CONTRACTS = 2,
  BASIC_INFO = 3,
  PEOPLE = 4,
  APPLICATIONS = 5,
  REFERENCES = 6,
  PROJECTS = 7,
  REFERENCE_BASKETS = 8,
}

export enum COMPANY_PERSON_TYPES {
  COMPANY_ADMIN = 1,
  USER = 2,
}

export const BILLING_UNITS: ReadonlyArray<string> = [
  '€',
  '€/v',
  '€/m²',
  '€/m³',
  '€/jm',
  '€/m',
];

export enum PRODUCTS {
  RALA_QUALIFICATION = 1,
  RALA_QUALITY_CERTIFICATE = 2,
  RALA_FEEDBACK = 3,
  RALA_FEEDBACK_TEST = 4,
  RALA_FEEDBACK_INQUIRY = 5,
  RALA_ENVIRONMENT_CERTIFICATE = 6,
  RALA_SAFETY_CERTIFICATE = 7,
  VASTUU_GROUP = 8,
  RALA_MOISTURE_CERTIFICATE = 15,
}

export const CERTIFICATE_ISSUERS = [
  'Bureau Veritas (BVC)',
  'Det Norske Veritas (DNV)',
  'DQS GmbH',
  'Eurofins',
  'Inspecta',
  "Lloyd's Register Quality Assurance (LRQA)",
  'SETI Oy',
  'SGS Fimko Oy Systems & Services',
];

export enum REFERENCE_CONFIRMATION_TYPES {
  NO_CONFIRMATION = 1,
  CONFIRMATION = 2,
  CONFIRMATION_AND_FEEDBACK = 3,
}

export enum REFERENCE_STATUS {
  NOT_READY = 1,
  READY = 2,
}

export enum REFERENCE_VISIBILITY {
  PUBLIC = 1,
  PRIVATE = 2,
  INTERNAL = 3,
}

export const COMPANY_PERSON_ROLES = defineMessages({
  user: {
    id: 'company-person-role-user',
    defaultMessage: 'Käyttäjä',
  },
  companyAdmin: {
    id: 'company-person-role-company_admin',
    defaultMessage: 'Pääkäyttäjä',
  },
  companyManagingDirector: {
    id: 'company-person-role-company_managing_director',
    defaultMessage: 'Toimitusjohtaja',
  },
  companyContactPerson: {
    id: 'company-person-role-company_contact_person',
    defaultMessage: 'Yhteyshenkilö',
  },
  companyProductionManagement: {
    id: 'company-person-role-company_production_management',
    defaultMessage: 'Tuotantojohtaja',
  },
  person: {
    id: 'company-person-role-company_person',
    defaultMessage: 'Ammattilainen',
  },
});

export enum COMPANY_ROLE {
  EXPERT = 3,
}

export enum APPLICATION_TARGET {
  RALA_QUALIFICATION = 1,
  RALA_QUALIFICATION_RENEWAL = 2,
  RALA_QUALIFICATION_EXTENSION = 3,
  RALA_QUALITY_CERTIFICATE = 4,
  RALA_QUALITY_CERTIFICATE_RENEWAL = 5,
  RALA_ENVIRONMENTAL_CERTIFICATE = 6,
  RALA_ENVIRONMENTAL_CERTIFICATE_RENEWAL = 7,
  RALA_OCCUPATIONAL_SAFETY = 8,
  RALA_OCCUPATIONAL_SAFETY_RENEWAL = 9,
  RALA_MOISTURE_CERTIFICATE = 10,
  RALA_MOISTURE_CERTIFICATE_RENEWAL = 11,
}

export const QUALIFICATION_TYPES = [
  APPLICATION_TARGET.RALA_QUALIFICATION,
  APPLICATION_TARGET.RALA_QUALIFICATION_RENEWAL,
  APPLICATION_TARGET.RALA_QUALIFICATION_EXTENSION,
];

export const CERTIFICATION_TYPES: number[] = [
  APPLICATION_TARGET.RALA_QUALITY_CERTIFICATE,
  APPLICATION_TARGET.RALA_QUALITY_CERTIFICATE_RENEWAL,
  APPLICATION_TARGET.RALA_ENVIRONMENTAL_CERTIFICATE,
  APPLICATION_TARGET.RALA_ENVIRONMENTAL_CERTIFICATE_RENEWAL,
  APPLICATION_TARGET.RALA_OCCUPATIONAL_SAFETY,
  APPLICATION_TARGET.RALA_OCCUPATIONAL_SAFETY_RENEWAL,
  APPLICATION_TARGET.RALA_MOISTURE_CERTIFICATE,
  APPLICATION_TARGET.RALA_MOISTURE_CERTIFICATE_RENEWAL,
];

// these are the ids of granted certificates, they are not applications anymore
export const PRODUCT_TYPES = {
  CERTIFICATE: 2,
  QUALIFICATION: 3,
  ENVIRONMENTAL_CERTIFICATE: 5,
  SAFETY_CERTIFICATE: 20,
  MOISTURE_CERTIFICATE: 23,
};

export const APPLICATION_TARGET_MAPPING = {
  QUA: APPLICATION_TARGET.RALA_QUALIFICATION,
  QUA_RE: APPLICATION_TARGET.RALA_QUALIFICATION_RENEWAL,
  QUA_EXT: APPLICATION_TARGET.RALA_QUALIFICATION_EXTENSION,
  CERT: APPLICATION_TARGET.RALA_QUALITY_CERTIFICATE,
  CERT_RE: APPLICATION_TARGET.RALA_QUALITY_CERTIFICATE_RENEWAL,
  ENV: APPLICATION_TARGET.RALA_ENVIRONMENTAL_CERTIFICATE,
  ENV_RE: APPLICATION_TARGET.RALA_ENVIRONMENTAL_CERTIFICATE_RENEWAL,
  SAF: APPLICATION_TARGET.RALA_OCCUPATIONAL_SAFETY,
  SAF_RE: APPLICATION_TARGET.RALA_OCCUPATIONAL_SAFETY_RENEWAL,
  MOI: APPLICATION_TARGET.RALA_MOISTURE_CERTIFICATE,
  MOI_RE: APPLICATION_TARGET.RALA_MOISTURE_CERTIFICATE_RENEWAL,
};

export const APPLICATION_TARGET_TRANSLATIONS = defineMessages({
  QUA: {
    id: 'application-type-quality',
    defaultMessage: 'RALA-pätevyys',
  },
  QUA_RE: {
    id: 'application-type-quality-renewal',
    defaultMessage: 'RALA-pätevyys - uusinta',
  },
  QUA_EXT: {
    id: 'application-type-quality-extension',
    defaultMessage: 'RALA-pätevyys - laajennos',
  },
  CERT: {
    id: 'application-type-certification',
    defaultMessage: 'RALA-laatusertifikaatti',
  },
  CERT_RE: {
    id: 'application-type-certification-renewal',
    defaultMessage: 'RALA-laatusertifikaatti - uusinta',
  },
  ENV: {
    id: 'application-type-environmental',
    defaultMessage: 'RALA-ympäristösertifikaatti',
  },
  ENV_RE: {
    id: 'application-type-environmental-renewal',
    defaultMessage: 'RALA-ympäristösertifikaatti - uusinta',
  },
  SAF: {
    id: 'application-type-safety',
    defaultMessage: 'RALA-turvallisuussertifikaatti',
  },
  SAF_RE: {
    id: 'application-type-safety-renewal',
    defaultMessage: 'RALA-turvallisuussertifikaatti - uusinta',
  },
  MOI: {
    id: 'application-type-moisture',
    defaultMessage: 'RALA-kosteudenhallintasertifikaatti',
  },
  MOI_RE: {
    id: 'application-type-moisture-renewal',
    defaultMessage: 'RALA-kosteudenhallintasertifikaatti - uusinta',
  },
});

export enum APPLICATION_STATUS {
  IN_PROGRESS = 1,
  SENT = 2,
  IN_EVALUATION = 3,
  RETURNED = 4,
  AUDIT_DONE = 5,
  ATTACHED_TO_BOARD_MEETING = 6,
  AUDIT_POSTPONED = 7,
  DISCUSSED_BY_THE_BOARD = 8,
  NOTIFIED = 9,
  ARCHIVED = 10,
  CANCELED = 11,
}

export type APPLICATION_STATUS_KEY = keyof typeof APPLICATION_STATUS;

export const API_MESSAGE_KEYS = new Map<
  APPLICATION_STATUS,
  APPLICATION_STATUS_KEY
>(
  Object.entries(APPLICATION_STATUS).map(
    ([key, value]: [APPLICATION_STATUS_KEY, APPLICATION_STATUS]) => [value, key]
  )
);

export enum APPLICATION_STEPS_CODES {
  VASTUUGROUP_MEMBER = 'vastuugroup',
  FINANCIAL = 'financial',
  PERSONNEL = 'personnel',
  INDUSTRIES = 'industries',
  REFERENCES = 'references',
  ASSETS = 'assets',
  ATTACHMENTS = 'attachments',
  STATEMENT = 'statement',
  COMMENTS = 'comment',
}

export enum LOCATION_CHECK_RESULT {
  NONE = 'NONE',
  OK = 'OK',
  FAIL = 'FAIL',
  WARNING = 'WARNING',
}

export enum APPLICATION_ANSWER_STATUS {
  EOS = 1,
  DEFICIENCY,
  OK,
  NOT_OK,
}

export enum APPLICATION_SOURCE {
  UNKNOWN = 1,
  CUSTOMER,
  RALA_PERSON,
  RALA_SYSTEM,
  MIGRATION,
}

export enum CERTIFICATE_TYPES {
  RALA_QUALITY_CERTIFICATE = 2,
  RALA_PROFICIENCY_CERTIFICATE = 3,
  RALA_ENVIRONMENT_CERTIFICATE = 5,
  RALA_OCCUPATIONAL_SAFETY = 20,
  RALA_MOISTURE_CERTIFICATE = 23,
}

export const CERTIFICATE_TYPES_LIST: number[] = [
  CERTIFICATE_TYPES.RALA_ENVIRONMENT_CERTIFICATE,
  CERTIFICATE_TYPES.RALA_OCCUPATIONAL_SAFETY,
  CERTIFICATE_TYPES.RALA_QUALITY_CERTIFICATE,
  CERTIFICATE_TYPES.RALA_MOISTURE_CERTIFICATE,
];

export const CERTIFICATE_PRODUCTS = {
  [CERTIFICATE_TYPES.RALA_QUALITY_CERTIFICATE]:
    PRODUCTS.RALA_QUALITY_CERTIFICATE,
  [CERTIFICATE_TYPES.RALA_PROFICIENCY_CERTIFICATE]: PRODUCTS.RALA_QUALIFICATION,
  [CERTIFICATE_TYPES.RALA_ENVIRONMENT_CERTIFICATE]:
    PRODUCTS.RALA_ENVIRONMENT_CERTIFICATE,
  [CERTIFICATE_TYPES.RALA_OCCUPATIONAL_SAFETY]:
    PRODUCTS.RALA_SAFETY_CERTIFICATE,
  [CERTIFICATE_TYPES.RALA_MOISTURE_CERTIFICATE]:
    PRODUCTS.RALA_MOISTURE_CERTIFICATE,
};

export enum LIABILITY_INTERPRETATIONS {
  OK = 'Ok',
  STOP = 'Seis',
  RESOLUTION_NEEDED = 'Selvitä',
  WAITING_FOR_INFORMATION = 'Tietoja odotetaan',
  OBSERVE = 'Huomioi',
}

export enum CERTIFICATE_AUDIT_PLAN_ITEM_STATUS {
  AUTOMATICALLY_GENERATED_SUGGESTION = 1,
  PROPOSED,
  PLANNED,
  ONGOING,
  READY,
}

export enum CERTIFICATE_AUDIT_PROGRAM_STATUS {
  FRESH = 1,
  SUGGESTED,
  PLANNED,
  IN_PROGRESS,
  FINISHED,
  CANCELLED,
}

export enum INSPECTABLE_TYPES {
  APPLICATION_INDUSTRY = 'application_industry',
  REFERENCE_RELATED_INDUSTRY = 'application_reference_related_industry',
}

export enum INSPECTION_RESOLUTION_CODES {
  OK = 'ok',
  CHECK = 'check',
  NOT_OK = 'not-ok',
  PENDING = 'pending',
  NOT_REQUIRED = 'not-required',
  PROPOSE_ACCEPTANCE = 'propose_acceptance',
  PROPOSE_CONDITIONAL_ACCEPTANCE = 'propose_conditional_acceptance',
  PROPOSE_REJECTION = 'propose_rejection',
}

export enum MeetingMemberRole {
  CHAIRMAN = 1,
  ASSISTANT = 2,
  PARTICIPANT = 3,
}

export enum AttendanceType {
  ATTENDED = 1,
  ATTENDED_REMOTE = 2,
  ABSENT = 3,
}

export enum APPLICATION_STATE {
  UNFINISHED_NOT_INVITED = 1,
  UNFINISHED_NOT_UPDATED_DETAILS = 2,
  UNFINISHED_LOGGED_IN = 3,
  UNFINISHED_DETAILS_GIVEN_PARTLY = 4,
  READY = 5,
  READY_PUBLISHED = 6,
}

export enum MEMBER_RESOLUTION {
  PROPOSE_ACCEPTANCE = 1,
  PROPOSE_CONDITIONAL_ACCEPTANCE = 2,
  PROPOSE_REJECTION = 3,
  PROPOSE_POSTPONEMENT = 4,
  OK = 5,
  CHECK = 6,
  NOT_OK = 7,
  PENDING = 8,
  NOT_REQUIRED = 9,
}

export enum APPLICATION_STEPS_IDS {
  VASTUUGROUP_MEMBER = 1,
  FINANCIAL,
  PERSONNEL,
  INDUSTRIES,
  REFERENCES,
  ASSETS,
  ATTACHMENTS,
  STATEMENT,
  COMMENTS,
}

export enum INSPECTION_RESOLUTION_CODE_IDS {
  OK = 5,
  CHECK,
  NOT_OK,
  PENDING,
  NOT_REQUIRED,
}

export enum BOARD_MEETING_APPLICATION_RESOLUTION_IDS {
  ACCEPTED = 1,
  ACCEPTED_CONDITIONAL,
  DECLINED,
  POSTPONED,
}

export enum VALUE_TYPES {
  STRING = 'string',
  OBJECT = 'object',
  ARRAY = 'array',
  NUMBER = 'number',
}

export enum PROJECT_SECTOR_IDS {
  UNKNOWN = 1,
  INFRA,
  CONSTRUCTION,
  CONSTRUCTION_INFRA,
  INFRA_CONSTRUCTION_TECHNICAL,
}

export enum CERTIFICATE_AUDIT_REPORT_PART {
  THE_COMPANYS_TURNOVER_NUMBER_OF_EMPLOYEES_AND_OWNERSHIP_BASE = 1,
  CUSTOMERS,
  SERVICES,
  RESOURCES_PART_2,
  SUBCONTRACTORS,
  REVIEWED_LOCATIONS,
  REVIEWED_INDUSTRIES_AND_PROJECTS,
  REVIEW_PARTICIPANTS,
  MANAGEMENT_AND_DEVELOPMENT,
  RESOURCES_CRITERIA,
  TENDERING_CONTRACTIONG_AND_PROCUREMENT,
  PROJECT_ACTIVITIES,
  SUMMARY,
  EFFECTIVENESS_OF_PREVIOUS_EVALUATION,
  DEVELOPMENT_OF_THE_COMPANY_SINCE_PREVIOUS_EVALUATION,
  SPECIAL_ATTENTION_IN_THE_FOLLOWING_EVALUATION,
}

export enum CERTIFICATE_AUDIT_REPORT_OBSERVED_PART_STATUSES {
  UNFINISHED = 1,
  NOT_VALID,
  READY,
}

export enum CERTIFICATE_AUDIT_REPORT_PART_TYPES {
  BASIC_INFO_AND_SUMMARY = 2,
  EVALUATION_ACCORDING_TO_CRITERIA = 3,
}

export enum CERTIFICATE_AUDIT_REPORT_STATUSES {
  IN_PROGRESS = 1,
  DRAFT,
  ACCEPTED,
  READY,
}

export enum CERTIFICATE_AUDIT_REPORT_DEVIATION_SEVERITIES {
  LIGHT = 1,
  SERIOUS,
}

export enum CERTIFICATE_AUDIT_REPORT_DEVIATION_STATUSES {
  IN_PROGRESS = 1,
  READY,
  ACCEPTED_FIXED,
}

export enum MAX_FINANCIAL_YEARS {
  DEFAULT = 3,
}

export enum CERTIFICATE_DEVIATION_TAKEN_ACTION_STATUSES {
  UNFINISHED = 1,
  FINISHED,
}

export enum CERTIFICATE_AUDIT_REPORT_STATEMENT_STATUSES {
  RECOMMENDATION = 1,
  RECOMMENDATION_WITH_CHANGES,
}

export enum ECONOMY_AUDIT_STATUS {
  NOT_DONE = 1,
  DONE = 2,
  NOT_REQUIRED = 3,
}

export enum EXTERNAL_CERTIFICATE_TYPES {
  OTHER = 18,
}

export enum INDUSTRY_RESOLUTIONS {
  ACCEPT = 1,
  DECLINE = 3,
}

export enum HELP_MODAL_PAGE {
  COMMON,
  RALA_QUALIFICATION,
  RALA_CERTIFICATION,
  REFERENCES,
  PROJECTS,
  OFFER_PACKS,
  BASIC,
  OFFICES_SUBSIDIARIES,
  FINANCES,
  CONTRACTS,
  PEOPLE,
  TECHNICAL_RESOURCES,
  MEMBERSHIPS,
  EXTERNAL_CERTIFICATES,
  ATTACHMENTS,
}

export enum REFERENCE_VERIFIED_BY {
  BOARD = 'board',
  CUSTOMER = 'customer',
  FEEDBACK = 'feedback',
}

export enum FILEABLE_TYPE {
  COMPANY = 'company',
  COMPANY_CERTIFICATE = 'company_certificate',
  APPLICATION = 'application',
  FINANCIAL_YEAR = 'company_financial_year',
}

export enum APPLICATION_SCOPES {
  construction = 1,
  designManagementAndControl,
  implementationManagementAndControl,
  control,
  design,
  designAndSupervisionDuringConstruction,
  building,
  KVRST,
  constructionNew = 101,
  designManagementAndControlNew,
  implementationManagementAndControlNew,
  controlNew,
  designNew,
  designAndSupervisionDuringConstructionNew,
  buildingNew,
  KVRSTNew,
}

export enum APPLICATION_CRITERIA_GROUPS {
  oldCriteriaGroup = 1,
  newCriteriaGroup,
}
