/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { omit } from 'lodash';

import { COMMON } from '../common';
import * as api from './api';
import { RootState } from '~store/index';
import CompanyPerson from '~models/CompanyPerson';
import { COMPANY_PERSON_TYPES } from '~common/enums';

type PersonId = number;
type PageState =
  | 'listing'
  | 'showing'
  | 'creating_pro'
  | 'creating_user'
  | 'editing_basic'
  | 'editing_permissions'
  | 'editing_education'
  | 'editing_certs'
  | 'deleting';

type PageTransition =
  | { state: Extract<PageState, 'listing' | 'creating_pro' | 'creating_user'> }
  | {
      state: Exclude<PageState, 'listing' | 'creating_pro' | 'creating_user'>;
      personId: PersonId;
    };

interface SliceState {
  pageState: PageState;
  personId: PersonId | null;
  personRole: COMPANY_PERSON_TYPES;
  permissionData: number[];
  companyPeopleSearch: {
    data: CompanyPerson[];
    meta: any;
  };
  pending: any[];
  loading: { [type: string]: boolean };
  error: { [type: string]: any };
}

const peopleAdapter = createEntityAdapter<CompanyPerson>();

const initialState = peopleAdapter.getInitialState({
  pageState: 'listing',
  personId: null,
  personRole: COMPANY_PERSON_TYPES.USER,
  permissionData: [],
  companyPeopleSearch: {
    data: [] as CompanyPerson[],
    meta: null,
  },
  pending: [],
  loading: {},
  error: {},
} as SliceState);

const slice = createSlice({
  name: 'companyPeople',
  initialState,
  reducers: {
    setPageState(state, action: PayloadAction<PageTransition>) {
      switch (action.payload.state) {
        case 'editing_basic':
        case 'editing_certs':
        case 'editing_education':
        case 'editing_permissions':
        case 'deleting':
        case 'showing':
          state.pageState = action.payload.state;
          state.personId = action.payload.personId;
          break;
        case 'listing':
          state.pageState = action.payload.state;
          state.personRole = COMPANY_PERSON_TYPES.USER;
          state.permissionData = [];
          break;
        default:
          state.pageState = action.payload.state;
          state.personId = null;
      }
    },

    setPersonRole(state, action: PayloadAction<COMPANY_PERSON_TYPES>) {
      state.personRole = action.payload;
    },

    setPermissions(state, action: PayloadAction<number[]>) {
      state.permissionData = action.payload;
    },

    fetchCompanyPerson(state, action: PayloadAction<CompanyPerson>) {},

    receiveCompanyPerson(state, action) {
      const { personId } = action.payload;
      const search = state.companyPeopleSearch.data;
      const companyPersonIndex = search.findIndex(
        (p: CompanyPerson) => p.person.id === personId
      );
      if (companyPersonIndex > -1) {
        search[companyPersonIndex] = new CompanyPerson(action.payload);
        state.companyPeopleSearch.data = search;
        peopleAdapter.upsertOne(state, action.payload);
      }
    },

    receivePerson(state, action) {
      const person = action.payload;
      const search = state.companyPeopleSearch.data;
      const companyPersonIndex = search.findIndex(
        (p: CompanyPerson) => p.person.id === person.id
      );

      if (companyPersonIndex > -1) {
        const companyPerson: CompanyPerson = search[companyPersonIndex];

        const updatedCompanyPerson = new CompanyPerson({
          ...companyPerson,
          person: omit(person, ['user']),
        });

        search[companyPersonIndex] = updatedCompanyPerson;

        state.companyPeopleSearch.data = search;

        peopleAdapter.upsertOne(state, updatedCompanyPerson);
      }
    },

    receivePersonEducation(state, action) {
      const { personId, data } = action.payload;
      const search = state.companyPeopleSearch.data;
      const companyPersonIndex = search.findIndex(
        (p: CompanyPerson) => p.person.id === personId
      );

      if (companyPersonIndex > -1) {
        const companyPerson: CompanyPerson = search[companyPersonIndex];

        const person = { ...companyPerson.person };

        person.personEducations = data;

        const updatedCompanyPerson = new CompanyPerson({
          ...companyPerson,
          person,
        });

        search[companyPersonIndex] = updatedCompanyPerson;

        state.companyPeopleSearch.data = search;

        peopleAdapter.upsertOne(state, updatedCompanyPerson);
      }
    },

    fetchPending(state, action: PayloadAction<number>) {},
    receivePending(state, action: PayloadAction<any>) {
      state.pending = action.payload;
    },

    approve(state, action: PayloadAction<any>) {},
    reject(state, action: PayloadAction<any>) {},

    loading(state, action) {
      const type = action.payload;
      state.loading[type] = true;
      state.error[type] = null;
    },

    success(state, action) {
      const type = action.payload;
      state.loading[type] = false;
      state.error[type] = null;
    },

    error(state, action) {
      const { type, error } = action.payload;
      state.loading[type] = false;
      state.error[type] = error;
    },
  },
  extraReducers: builder => {
    builder.addCase(COMMON.NAVIGATE, state => {
      Object.keys(state.error).forEach(k => {
        state.error[k] = null;
      });
    })
  },
});

export const { actions } = slice;
export default slice.reducer;

export const selectors = {
  pageState: (state: RootState) => state.companyPeople.pageState,
  personId: (state: RootState) => state.companyPeople.personId,
  personRole: (state: RootState) => state.companyPeople.personRole,
  permissionData: (state: RootState) => state.companyPeople.permissionData,
  pending: state => state.companyPeople.pending,
  loading: (state, key?) =>
    key ? !!state.companyPeople.loading[key] : state.companyPeople.loading,
  error: (state, key?) =>
    key ? state.companyPeople.error[key] || null : state.companyPeople.error,
};

export function* fetchCompanyPerson(action) {
  const { type } = actions.fetchCompanyPerson;

  try {
    yield put(actions.loading(type));

    const response = yield call(api.getCompanyPerson, action.payload);

    yield put(actions.receiveCompanyPerson(new CompanyPerson(response.data)));

    yield put(actions.success(type));
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* fetchPending(action) {
  const { type } = actions.fetchPending;
  try {
    yield put(actions.loading(type));
    const { data: pendingRequests } = yield call(
      api.getPending,
      action.payload
    );

    if (pendingRequests) {
      yield put(actions.receivePending(pendingRequests));
    }
    yield put(actions.success(type));
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* approve(action) {
  const { type } = actions.approve;
  try {
    yield put(actions.loading(type));
    const { companyId, personId, request } = action.payload;

    yield call(api.approve, companyId, personId, request);
    yield put(actions.fetchPending(parseInt(companyId, 10)));
    yield put(actions.success(type));
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* reject(action) {
  const { type } = actions.reject;
  try {
    yield put(actions.loading(type));
    const { companyId, personId } = action.payload;

    yield call(api.reject, companyId, personId);
    yield put(actions.fetchPending(parseInt(companyId, 10)));
    yield put(actions.success(type));
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* companyPeopleSaga() {
  yield all([
    yield takeEvery(actions.fetchCompanyPerson.type, fetchCompanyPerson),
    yield takeLatest(actions.fetchPending.type, fetchPending),
    yield takeLatest(actions.approve.type, approve),
    yield takeLatest(actions.reject.type, reject),
  ]);
}
