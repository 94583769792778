/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  createSlice,
  PayloadAction,
  createEntityAdapter,
} from '@reduxjs/toolkit';
type SearchState = 'valid' | 'expired';
interface State {
  certificateSearchParams: {
    search?: string;
    productId?: number;
    valid?: boolean;
    certificateTypeIds?: {
      id: number;
      label: string;
    }[];
    page?: number;
    sort?: string;
    order?: 'asc' | 'desc';
    paginate?: boolean;
  };
  expiredCertificateSearchParams: {
    search?: string;
    productId?: number;
    valid?: boolean;
    certificateTypeId?: number;
    page?: number;
    sort?: string;
    order?: 'asc' | 'desc';
    paginate?: boolean;
  };
  searchState: SearchState;
}

const officeCertificatesAdapter = createEntityAdapter<void>();

const initialState = officeCertificatesAdapter.getInitialState({
  certificateSearchParams: {
    sort: 'valid_to',
    order: 'desc',
    valid: true,
    paginate: true,
    page: 1,
    certificateTypeIds: [{ id: 5, label: 'RALA-ympäristösertifikaatti' }],
  },
  expiredCertificateSearchParams: {
    sort: 'company_name',
    order: 'desc',
    paginate: true,
    page: 1,
    certificateTypeId: 5,
  },
  searchState: 'valid',
} as State);

const slice = createSlice({
  name: 'officeCertificates',
  initialState,
  reducers: {
    setCertificateSearchParams(state, action: PayloadAction<any>) {
      state.certificateSearchParams = {
        ...state.certificateSearchParams,
        ...action.payload,
      };
    },
    setExpiredCertificateSearchParams(state, action: PayloadAction<any>) {
      state.expiredCertificateSearchParams = {
        ...state.expiredCertificateSearchParams,
        ...action.payload,
      };
    },
    setSearchState(state, action: PayloadAction<SearchState>) {
      state.searchState = action.payload;
    },
    clearSearchParams(state) {
      state.certificateSearchParams = initialState.certificateSearchParams;
      state.expiredCertificateSearchParams =
        initialState.expiredCertificateSearchParams;
      state.searchState = initialState.searchState;
    },
  },
});

export const { actions } = slice;
export default slice.reducer;

const adapterSelectors = officeCertificatesAdapter.getSelectors(
  (state: any) => state.officeCertificates
);

export const selectors = {
  certificateSearchParams: state =>
    state.officeCertificates.certificateSearchParams,
  expiredCertificateSearchParams: state =>
    state.officeCertificates.expiredCertificateSearchParams,
  searchState: state => state.officeCertificates.searchState,
};
