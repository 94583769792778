/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  createSlice,
  PayloadAction,
  EntityState,
  createEntityAdapter,
} from '@reduxjs/toolkit';

import { COMMON } from '../common';

import { RootState } from '../rootReducer';
import { APIError } from '../api';
import * as api from './api';
import Agreement from '~models/Agreement';

const agreementsAdapter = createEntityAdapter<Agreement>({
  selectId: Agreement => Agreement.productAgreementId,
});

const initialState = agreementsAdapter.getInitialState({
  loading: {},
  error: {},
});

const slice = createSlice({
  name: 'agreements',
  initialState,
  reducers: {
    fetchAgreements(state, action: PayloadAction<{ companyId: string }>) {},

    receiveAgreements(state, action: PayloadAction<any>) {
      agreementsAdapter.setAll(state, action.payload);
    },

    createAgreement(
      state,
      action: PayloadAction<{
        companyId: string;
        data: { product_id: string };
      }>
    ) {},

    receiveCreatedAgreement: agreementsAdapter.addOne,

    loading(state, action: PayloadAction<string>) {
      const type = action.payload;
      state.loading[type] = true;
      state.error[type] = null;
    },

    success(state, action: PayloadAction<string>) {
      const type = action.payload;
      state.loading[type] = false;
      state.error[type] = null;
    },

    error(state, action: PayloadAction<{ type: string; error: APIError }>) {
      const { type, error } = action.payload;
      state.loading[type] = false;
      state.error[type] = error;
    },

    clearError(state, action: PayloadAction<string>) {
      const type = action.payload;
      state.error[type] = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(COMMON.NAVIGATE, state => {
      Object.keys(state.error).forEach(k => {
        state.error[k] = null;
      });
    })
  },
});

export const { actions } = slice;
export default slice.reducer;

const adapterSelectors = agreementsAdapter.getSelectors(
  (state: RootState) => state.agreements
);

export const selectors = {
  agreements: adapterSelectors.selectAll,
  agreement: adapterSelectors.selectById,
  loading: (state, key?) => {
    if (key !== undefined) {
      return !!state.agreements.loading[key];
    }
    return state.agreements.loading;
  },
  error: (state, key?) =>
    key ? state.agreements.error[key] || null : state.agreements.error,
};

export function* fetchAgreements(
  action: ReturnType<typeof actions.fetchAgreements>
) {
  const { type, payload } = action;
  const { companyId } = payload;
  try {
    yield put(actions.loading(type));

    const response = yield call(api.fetchAgreements, companyId);
    yield put(
      actions.receiveAgreements(
        response.data
          .filter(a => a.terminated_at === null)
          .map(a => new Agreement(a))
      )
    );

    yield put(actions.success(type));
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* createAgreement(
  action: ReturnType<typeof actions.createAgreement>
) {
  const { type, payload } = action;
  const { companyId, data } = payload;
  try {
    yield put(actions.loading(type));

    const response = yield call(api.createAgreement, companyId, data);
    yield put(actions.receiveCreatedAgreement(new Agreement(response.data)));
    yield put(actions.success(type));
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* agreementsSaga() {
  yield all([
    takeEvery(actions.fetchAgreements.type, fetchAgreements),
    takeEvery(actions.createAgreement.type, createAgreement),
  ]);
}
