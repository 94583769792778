import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { HELP_MODAL_PAGE } from '~/features/common/enums';

import { RootState } from '~store/index';

type Modal = 'listing' | 'helpModal';
interface State {
  modalState: Modal;
  selectedPage: HELP_MODAL_PAGE;
}

const helpModalAdapter = createEntityAdapter<void>();

const initialState = helpModalAdapter.getInitialState({
  modalState: 'listing',
  selectedPage: HELP_MODAL_PAGE.COMMON,
} as State);

const slice = createSlice({
  name: 'helpModal',
  initialState,
  reducers: {
    selectPage(state, action: PayloadAction<HELP_MODAL_PAGE>) {
      state.selectedPage = action.payload;
    },
    setModalState(state, action: PayloadAction<Modal>) {
      state.modalState = action.payload;
    },
    closeModal(state) {
      state.modalState = 'listing';
      state.selectedPage = HELP_MODAL_PAGE.COMMON;
    },
  },
});

export const { actions } = slice;
export default slice.reducer;

export const selectors = {
  selectedPage: (state: RootState) => state.helpModal.selectedPage,
  modalState: (state: RootState) => state.helpModal.modalState,
};
