/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { all, put, call, takeEvery } from 'redux-saga/effects';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { COMMON } from '../common';

import * as api from './api';

const slice = createSlice({
  name: 'suggestions',
  initialState: {
    suggestions: {},
    loading: {},
    error: {},
  },
  reducers: {
    fetch(state, action: PayloadAction<any>) {},

    receive(state, action) {
      const { type, response } = action.payload;
      state.suggestions[type] = response;
    },

    loading(state, action) {
      const type = action.payload;
      state.loading[type] = true;
      state.error[type] = null;
    },

    success(state, action) {
      const type = action.payload;
      state.loading[type] = false;
      state.error[type] = null;
    },

    error(state, action) {
      const { type, error } = action.payload;
      state.loading[type] = false;
      state.error[type] = error;
    },
  },
  extraReducers: builder => {
    builder.addCase(COMMON.NAVIGATE, state => {
      Object.keys(state.error).forEach(k => {
        state.error[k] = null;
      });
    })
  },
});

export const { actions } = slice;
export default slice.reducer;

export const selectors = {
  suggestions: (state, key?) =>
    key ? state.suggestions.suggestions[key] : state.suggestions.suggestions,
  loading: (state, key?) =>
    key ? !!state.suggestions.loading[key] : state.suggestions.loading,
  error: (state, key?) =>
    key ? state.suggestions.error[key] || null : state.error,
};

export function* fetch(action) {
  const { type, url } = action.payload;

  try {
    yield put(actions.loading(type));

    const response = yield call(api.fetch, url);
    yield put(actions.receive({ type, response }));

    yield put(actions.success(type));
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* suggestionsSaga() {
  yield all([yield takeEvery(actions.fetch.type, fetch)]);
}
