import camelCase from 'lodash/camelCase';

export default class Description {
  public readonly id: number;
  public readonly isInfra: number;
  public readonly shortDescription: string;
  public readonly longDescription: string;
  public readonly applicationQuestionaireId: number;
  public readonly helpText: string | null;
  public readonly languageCode: string;
  public readonly orderNumber: number;

  constructor(data) {
    Object.keys(data).forEach(k => {
      this[camelCase(k)] = data[k];
    });
  }
}

export class CertificateTypeDescription extends Description {
  public readonly isSelfIssuable: boolean;
  public readonly isPersonCertificate: boolean;

  constructor(data) {
    super(data);

    Object.keys(data).forEach(k => {
      this[camelCase(k)] = data[k];
    });
  }
}

export class ClassificationIndustriesDescription extends Description {
  public readonly parentId: number;
  public readonly symbolicNumber: string;
  public readonly availableProficiency: boolean;
  public readonly availableCertification: boolean;

  constructor(data) {
    super(data);

    Object.keys(data).forEach(k => {
      this[camelCase(k)] = data[k];
    });

    this.availableCertification = Boolean(data.available_certification);
    this.availableProficiency = Boolean(data.available_proficiency);
  }
}

export class ApplicationScopesDescription extends Description {
  public readonly parentId: number;

  constructor(data) {
    super(data);

    Object.keys(data).forEach(k => {
      this[camelCase(k)] = data[k];
    });
  }
}

export class CommunicationMethodDescription extends Description {
  public readonly code: number | string;
  public readonly describableId: number;
  public readonly describableType: string;

  constructor(data) {
    super(data);

    Object.keys(data).forEach(k => {
      this[camelCase(k)] = data[k];
    });
  }
}
export class CertificateAuditReportPartDescription extends Description {
  public readonly certificateAuditReportPartTypeId: number;
  public readonly descriptions?: Description[];

  constructor(data) {
    super(data);

    Object.keys(data).forEach(k => {
      this[camelCase(k)] = data[k];
    });
    this.descriptions = this?.descriptions?.map(a => new Description(a)) ?? [];
  }
}

export type ApiDescribable =
  | 'address-types'
  | 'agreement-terminate-reasons'
  | 'application-answer-grades'
  | 'application-answer-statuses'
  | 'application-area-codes'
  | 'application-questions'
  | 'application-questionaires'
  | 'application-scopes'
  | 'application-sources'
  | 'application-statuses'
  | 'application-targets'
  | 'board-member-roles'
  | 'board-meeting-application-resolutions'
  | 'certificate-audit-criterias'
  | 'certificate-audit-plan-item-statuses'
  | 'certificate-audit-program-statuses'
  | 'certificate-audit-report-statuses'
  | 'certificate-deviation-person-roles'
  | 'certificate-deviation-severities'
  | 'certificate-process-periods'
  | 'certificate-roles'
  | 'certificate-types'
  | 'certificate-type-discounts'
  | 'classification-industries'
  | 'company-membership-unions'
  | 'company-personright-types'
  | 'company-relationship-types'
  | 'company-statuses'
  | 'company-types'
  | 'company-unit-types'
  | 'construction2008-levels'
  | 'contract-types'
  | 'customer-types'
  | 'distribution-lists'
  | 'education-degrees'
  | 'education-levels'
  | 'expertises'
  | 'file-types'
  | 'permissions'
  | 'planning-types'
  | 'product-agreements'
  | 'product-bundles'
  | 'reference-confirmation-types'
  | 'reference-statuses'
  | 'reference-types'
  | 'work-queue-statuses'
  | 'company-roles'
  | 'lifecycle-phases'
  | 'sectors'
  | 'project-classification'
  | 'project-size-unit'
  | 'reference-evaluation-statuses'
  | 'visibility-types'
  | 'reference-billing-units'
  | 'communication-methods'
  | 'project-classifications';

export type Describable =
  | 'addressTypes'
  | 'agreementTerminateReasons'
  | 'applicationAnswerGrades'
  | 'applicationAnswerStatuses'
  | 'applicationAreaCodes'
  | 'applicationQuestions'
  | 'applicationQuestionaires'
  | 'applicationScopes'
  | 'applicationSources'
  | 'applicationStatuses'
  | 'applicationTargets'
  | 'boardMemberRoles'
  | 'boardMeetingApplicationResolutions'
  | 'certificateAuditCriterias'
  | 'certificateAuditPlanItemStatuses'
  | 'certificateAuditProgramStatuses'
  | 'certificateAuditReportStatuses'
  | 'certificateDeviationPersonRoles'
  | 'certificateDeviationSeverities'
  | 'certificateProcessPeriods'
  | 'certificateRoles'
  | 'certificateTypes'
  | 'certificateTypeDiscounts'
  | 'classificationIndustries'
  | 'companyMembershipUnions'
  | 'companyPersonrightTypes'
  | 'companyRelationshipTypes'
  | 'companyStatuses'
  | 'companyTypes'
  | 'companyUnitTypes'
  | 'construction2008Levels'
  | 'contractTypes'
  | 'customerTypes'
  | 'distributionLists'
  | 'educationDegrees'
  | 'educationLevels'
  | 'expertises'
  | 'fileTypes'
  | 'permissions'
  | 'personTitles'
  | 'planningTypes'
  | 'productAgreements'
  | 'productBundles'
  | 'referenceConfirmationTypes'
  | 'referenceEvaluationStatuses'
  | 'referenceStatuses'
  | 'referenceTypes'
  | 'workQueueStatuses'
  | 'companyRoles'
  | 'lifecyclePhases'
  | 'sectors'
  | 'projectClassification'
  | 'projectSizeUnit'
  | 'visibilityTypes'
  | 'referenceBillingUnits'
  | 'communicationMethods'
  | 'boardMeetingApplicationResolution'
  | 'inspectionResolution'
  | 'inspectionResolutions'
  | 'certificateAuditReportObservedPartStatuses'
  | 'certificateDeviationStatuses'
  | 'companyHistoryEntryTypes'
  | 'projectClassifications'
  | 'projectSizeUnits'
  | 'products';

export type Descriptions = Partial<
  Record<Exclude<Describable, 'certificateTypes'>, Description[]> &
    Record<'certificateTypes', CertificateTypeDescription[]>
>;
