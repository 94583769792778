/*
 * Combine all reducers in this file and export the combined reducers.
 */

import addresses from './addresses';
import applications from './applications';
import companies from './companies';
import companyOffices from './companyOffices';
import companyPeople from './companyPeople';
import companyAgreements from './companyAgreements';
import companyMemberships from './companyMemberships';
import relations from './relations';
import init from './init';
import invites from './invites';
import messages from './messages';
import people from './people';
import suggestions from './suggestions';
import toaster from './toaster';
import user from './user';
import certificates from './certificates';
import unions from './unions';
import finances from './finances';
import financeFiles from './financeFiles';
import references from './references';
import descriptions from './descriptions';
import boards from './board';
import agreements from './agreements';
import projects from './projects';
import products from './products';
import applicationFiles from './applicationFiles';
import applicationIndustries from './applicationIndustries';
import modal from './modal';
import applicationQuestionaries from './applicationQuestionaries';
import auditPlans from './auditPlans';
import evaluationCalendar from './evaluationCalendar';
import certificatePlan from './certificatePlan';
import certificateAuditPrograms from './certificateAuditPrograms';
import errors from './errors';
import communicationLog from './communicationLog';
import certificateApplications from './certificateApplications';
import qualificationApplications from './qualificationApplications';
import searchOptions from './searchOptions';
import imageCarousel from './imageCarousel';
import frontPageNews from './frontPageNews';
import companyOfferPacks from './companyOfferPacks';
import helpModal from './helpModal';
import feedback from './feedback';
import companyHistory from './companyHistory';
import companyRestructuring from './companyRestructuring';
import productOrders from './productOrders';
import auditorContacts from './auditorContacts';
import classificationIndustries from './classificationIndustries';
import officeCertificates from './officeCertificates';
const rootReducer = {
  addresses,
  applications,
  companies,
  companyOffices,
  companyPeople,
  companyAgreements,
  companyMemberships,
  init,
  invites,
  messages,
  people,
  relations,
  suggestions,
  toaster,
  user,
  certificates,
  unions,
  finances,
  financeFiles,
  references,
  descriptions,
  boards,
  agreements,
  projects,
  products,
  applicationFiles,
  applicationIndustries,
  modal,
  applicationQuestionaries,
  auditPlans,
  evaluationCalendar,
  certificatePlan,
  certificateAuditPrograms,
  errors,
  communicationLog,
  certificateApplications,
  qualificationApplications,
  searchOptions,
  imageCarousel,
  frontPageNews,
  companyOfferPacks,
  helpModal,
  feedback,
  companyHistory,
  companyRestructuring,
  productOrders,
  auditorContacts,
  classificationIndustries,
  officeCertificates,
};

export type RootState = typeof rootReducer;
export default rootReducer;
