import camelCase from 'lodash/camelCase';

export interface Descriptions {
  id: number;
  describableType: string;
  describableId: number;
  languageCode: string;
  longDescription: string;
  shortDescription: string | null;
  helpText: string | null;
  code: string | null;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  orderNumber: number;
}

export default class Permissions {
  public readonly createdAt: string;
  public readonly deletedAt: string;
  public readonly descriptions?: Descriptions[];
  public readonly hasDescriptions: number;
  public readonly updatedAt: number;
  public readonly helpText: string | null;
  public readonly id: number;
  public readonly longDescription: string;
  public readonly shortDescription: string;

  constructor(data) {
    Object.keys(data).forEach(k => {
      this[camelCase(k)] = data[k];
    });
  }
}
