export default class Union {
  public readonly id: string;
  public readonly name: string;
  public readonly removable?: boolean;

  constructor(data) {
    this.id =
      data?.company_membership_union_id?.toString() || data?.id?.toString();
    this.removable = true;

    // name comes from all unions endpoint
    if (data.short_description) {
      this.name = data.short_description;

      // self entered union name
    } else if (data.description) {
      this.name = data.description;

      // union name found in database
    } else if (data.company_membership_union) {
      this.name = data.company_membership_union.short_description;
    }
  }
}
