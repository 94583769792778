import camelCase from 'lodash/camelCase';
import Address from './Address';

export default class Project {
  public readonly id: number;
  public readonly companyId: number;
  public readonly planningTypeId: number;
  public readonly contractTypeId: number;
  public readonly projectClassificationId: number;
  public readonly projectSize: number;
  public readonly projectSizeUnitId: number;
  public readonly name: string;
  public readonly locationDescription: string;
  public readonly buildingCode: string;
  public readonly propertyCode: string;
  public readonly siteKey: string;
  public readonly district: string;
  public readonly sectorId: number;
  public readonly extent: string;
  public readonly locationInfraIdentifier: string;
  public readonly hasOtherCompanyReferences?: boolean;
  public readonly addressVisitingFinnish?: Address;
  public readonly addressCoordinates?: Address;

  constructor(data) {
    Object.keys(data).forEach(k => {
      this[camelCase(k)] = data[k];
    });

    this.addressVisitingFinnish = data.address_visiting_finnish
      ? new Address(data.address_visiting_finnish)
      : undefined;
    this.addressCoordinates = data.address_coordinates
      ? new Address(data.address_coordinates)
      : undefined;
  }

  get address() {
    return this.addressVisitingFinnish?.toString();
  }
}

export interface ProjectModel {
  id: number;
  companyId: number;
  planningTypeId: number;
  contractTypeId: number;
  name: string;
  description: string;
  building_code: string;
  property_code: string;
  siteKey: string;
  district: string;
  sectorId: number;
  extent: string;
  address?: string;
  address_visiting_finnish?: Address;
  address_coordinates?: Address;
}
