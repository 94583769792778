import camelCase from 'lodash/camelCase';

export default class CertificateAuditReport {
  public readonly id: number;
  public readonly companyId: number;
  public readonly certificateProcessPeriodId: number;
  public readonly certificateAuditProgramId: number;
  public readonly certificateAuditReportStatusId: number;
  public readonly legacyAuditedOffices: string;
  public readonly legacyAuditedProjects: string;
  public readonly legacyReportId: number;
  public readonly sentAt: string;
  public readonly auditorComment: string;
  public readonly companyComment: string;
  public readonly createdAt: string;
  public readonly updatedAt: string;
  public readonly deletedAt: string;
  public readonly finishedAt: string | null;

  constructor(data) {
    Object.keys(data).forEach(k => {
      this[camelCase(k)] = data[k];
    });
  }
}
