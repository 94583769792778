import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import RalaNewsFeedItem from '~/models/RalaNewsFeedItem';

export const ralaNewsFeedApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://www.rala.fi',
  }),
  tagTypes: ['ralaNewsFeed'],
  endpoints: builder => ({
    fetchNewsFeed: builder.query<
      RalaNewsFeedItem[],
      {
        category?: string;
        maxitems?: number;
      }
    >({
      query: params => ({
        url: `api/feed`,
        params: params,
      }),
      transformResponse: (res: any[]) =>
        res.map(news => new RalaNewsFeedItem(news)),
      providesTags: ['ralaNewsFeed'],
    }),
    fetchNewsFeedById: builder.query<
      HTMLElement,
      {
        id: number;
      }
    >({
      query: params => ({
        url: `/api/getarticle`,
        params: params,
        responseHandler: response => response.text(), // https://redux-toolkit.js.org/rtk-query/api/fetchBaseQuery#parsing-a-response
      }),
      providesTags: ['ralaNewsFeed'],
    }),
  }),
});

export const { useFetchNewsFeedQuery, useFetchNewsFeedByIdQuery } =
  ralaNewsFeedApi;
