/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit';
import CompanyMerge from '~/models/CompanyMerge';

import { RootState } from '~store/index';
interface State {
  selectCompanyData: {
    searchCompanyToMoveFrom: string;
    companyIdToMoveFrom: number | null;
    companyIdToBeMoved: number | null;
    searchCompanyToBeMoved: string;
    companyNameToMoveFrom: string;
    companyNameToBeMoved: string;
    selectedIndustries: number[];
    mergeData: CompanyMerge | null;
  };
  formState: 'selectCompany' | 'selectIndustries' | 'preview' | 'success';
}
const companyRestructuringAdapter = createEntityAdapter<any>();
const initialState = companyRestructuringAdapter.getInitialState({
  selectCompanyData: {
    searchCompanyToMoveFrom: '',
    companyIdToMoveFrom: null,
    companyIdToBeMoved: null,
    searchCompanyToBeMoved: '',
    companyNameToMoveFrom: '',
    companyNameToBeMoved: '',
    selectedIndustries: [],
    mergeData: null,
  },
  formState: 'selectCompany',
} as State);

const slice = createSlice({
  name: 'companyRestructuring',
  initialState,
  reducers: {
    setSelectCompanyDataField(state, action: PayloadAction<any>) {
      state.selectCompanyData = {
        ...state.selectCompanyData,
        ...action.payload,
      };
    },
    setFormState(state, action: PayloadAction<any>) {
      state.formState = action.payload;
      if (action.payload === 'success') {
        state.selectCompanyData = initialState.selectCompanyData;
      }
    },
    clearSelectCompanyData(state) {
      state.selectCompanyData = initialState.selectCompanyData;
      state.formState = initialState.formState;
    },
  },
});

export const { actions } = slice;
export default slice.reducer;

export const selectors = {
  selectCompanyData: (state: RootState) =>
    state.companyRestructuring.selectCompanyData,
  formState: (state: RootState) => state.companyRestructuring.formState,
};
