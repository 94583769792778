import camelCase from 'lodash/camelCase';

export default class ProductAgreement {
  public readonly id: string;
  public readonly version: string;
  public readonly productId: string;
  public readonly validFrom: string;
  public readonly validTo: string;
  public readonly signedAt: string;
  public readonly longDescription: string;
  public readonly shortDescription: string;
  public readonly helpText: string;
  public readonly createdAt: string;
  public readonly updatedAt: string;
  public readonly deletedAt: string;
  public readonly isLatest: boolean;

  constructor(data) {
    Object.keys(data).forEach(k => {
      this[camelCase(k)] = data[k];
    });
  }
}
