import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Message {
  text: string;
  type: 'info' | 'success' | 'warning' | 'error';
  code?: string;
  param?: string;
}

interface ModalState {
  visible: boolean;
  messages: Message[];
  dirty: boolean;
  activeModalId: string;
}

const slice = createSlice({
  name: 'modal',
  initialState: {
    visible: false,
    messages: [],
    dirty: false,
    activeModalId: '',
  } as ModalState,
  reducers: {
    setVisible(
      state,
      action: PayloadAction<{ visible: boolean; modalId: string }>
    ) {
      const { visible, modalId } = action.payload;

      /*
        When modal.ts visible is set true, the id of Modal.tsx which made the action is set to activeModalId
        If a modal is opened from another modal, there was a chance that the redux actions went in order that
        the opened modal set visible to true and the closed modal set visible to false immediately.
        Dirtying modal requires modal.ts to have visible = true.
        Now we have a check for when a modal tries to set visible to false, if it doesn't
        match the active id, visible will remain true
      */
      if (visible) {
        state.activeModalId = modalId;
        state.visible = visible;
      } else if (state.activeModalId === modalId) {
        state.activeModalId = '';
        state.visible = visible;
      }
    },

    setMessages(state, action: PayloadAction<Message[]>) {
      state.messages = action.payload;
    },

    setModalDirty(state, action: PayloadAction<boolean>) {
      state.dirty = action.payload;
    },
  },
});

export const { actions } = slice;
export default slice.reducer;

export const selectors = {
  visible: state => state.modal.visible,
  messages: state => state.modal.messages,
  dirty: state => state.modal.dirty,
};
