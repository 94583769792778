import camelCase from 'lodash/camelCase';
import Description from './Description';
import Person from './Person';

export default class ApplicationAnswer {
  public readonly id: number;
  public readonly applicationId: number;
  public readonly applicationQuestionId: number;
  public readonly answer: string;
  public readonly finished: boolean;
  public readonly reviewerComment: string;
  public readonly applicationAnswerGradeId: number | null;
  public readonly applicationAnswerGrade?: Description;
  public readonly reviewerApplicationAnswerGradeId: number | null;
  public readonly reviewerApplicationAnswerGrade?: Description;
  public readonly reviewerPersonId: number;
  public readonly reviewedAt: string;
  public readonly creatorPersonId: number;
  public readonly creatorPerson?: Person;
  public readonly reviewerPerson?: Person;
  public readonly createdAt: string;
  public readonly updatedAt: string;
  public readonly deletedAt: string;
  public readonly public: boolean;

  constructor(data) {
    Object.keys(data).forEach(k => {
      this[camelCase(k)] = data[k];
    });
    this.applicationAnswerGrade = this.applicationAnswerGrade
      ? new Description(this.applicationAnswerGrade)
      : undefined;
    this.reviewerApplicationAnswerGrade = this.reviewerApplicationAnswerGrade
      ? new Description(this.reviewerApplicationAnswerGrade)
      : undefined;
    this.creatorPerson = this.creatorPerson
      ? new Person(this.creatorPerson)
      : undefined;
    this.reviewerPerson = this.reviewerPerson
      ? new Person(this.reviewerPerson)
      : undefined;
  }
}
