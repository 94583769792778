import camelCase from 'lodash/camelCase';
import Address from './Address';

export default class CompanyOffice {
  public readonly id: string;
  public readonly companyId: string;
  public readonly description: string;
  public readonly companyOfficeId: number;
  public readonly audited: boolean;
  public readonly address: Address | undefined;
  public readonly isGranted?: boolean; // This does not come from backend. Added this field to identify whether the company office in question is verified in certificate plan.

  constructor(data) {
    Object.keys(data).forEach(k => {
      this[camelCase(k)] = data[k];
    });
    this.address =
      this.address !== undefined ? new Address(this.address) : new Address({});
  }
}
