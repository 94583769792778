import api from '../api';

export function fetchCompanyFinances(companyId) {
  return api.get(`/companies/${companyId}/financial-years`);
}

export function createCompanyAccountingPeriod(companyId, newPeriod) {
  return api.post(`/companies/${companyId}/financial-years`, newPeriod);
}

export function updateCompanyAccountingPeriod(companyId, updatedPeriod) {
  return api.put(
    `/companies/${companyId}/financial-years/${updatedPeriod.id}`,
    updatedPeriod
  );
}

export function deleteCompanyAccountingPeriod(companyId, periodId) {
  return api.delete(`/companies/${companyId}/financial-years/${periodId}`);
}

export function fetchFinancesValidation(companyId) {
  return api.get(`/companies/${companyId}/financial-years/validate`);
}
