import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { Recipient } from '~/models/RalaMessage';

import { RootState } from '~store/index';

type Modal = 'listing' | 'feedback' | 'errorMessage';
export enum MESSAGE_TYPE {
  ERROR_MESSAGE = 1,
  FEEDBACK,
}
type ValidationErrors = Partial<{ [key in keyof FormData]: string[] }>;
interface State {
  modalState: Modal;
  feedback: {
    messageType: MESSAGE_TYPE;
    recipient: Recipient | null;
    title: string;
    message: string;
    companyId: number | null;
  };
  validationErrors: ValidationErrors | null;
}

const feedbackAdapter = createEntityAdapter<void>();

const initialState = feedbackAdapter.getInitialState({
  modalState: 'listing',
  feedback: {
    messageType: MESSAGE_TYPE.FEEDBACK,
    recipient: {
      person: {
        id: 0,
        firstName: '',
        lastName: '',
      },
    },
    title: 'Palaute järjestelmän toimivuudesta',
    message: '',
    companyId: null,
  },
  validationErrors: null,
} as State);

const slice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    setFeedbackField(state, action: PayloadAction<any>) {
      state.feedback = { ...state.feedback, ...action.payload };
    },
    setModalState(state, action: PayloadAction<Modal>) {
      state.modalState = action.payload;
      if (action.payload === 'listing') {
        state.feedback = initialState.feedback;
        state.validationErrors = initialState.validationErrors;
      }
    },
    setValidationErrors(state, action: PayloadAction<ValidationErrors | null>) {
      state.validationErrors = action.payload;
    },
  },
});

export const { actions } = slice;
export default slice.reducer;

export const selectors = {
  feedback: (state: RootState) => state.feedback.feedback,
  modalState: (state: RootState) => state.feedback.modalState,
  validationErrors: state => state.feedback.validationErrors,
};
