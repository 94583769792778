/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  createSlice,
  PayloadAction,
  createEntityAdapter,
} from '@reduxjs/toolkit';

interface State {
  searchParams: {
    search?: string;
    productId?: number;
    billed?: boolean;
    product_name?: number[];
    company_name?: number[];
    period_ends_at?: number[];
    billed_at?: number[];
    order_date?: number[];
    page?: number;
    sort?: string;
    order?: 'asc' | 'desc';
    paginate?: boolean;
  };
}

const productOrdersAdapter = createEntityAdapter<void>();

const initialState = productOrdersAdapter.getInitialState({
  searchParams: {
    paginate: true,
    page: 1,
  },
} as State);

const slice = createSlice({
  name: 'productOrders',
  initialState,
  reducers: {
    setSearchParams(state, action: PayloadAction<any>) {
      state.searchParams = { ...state.searchParams, ...action.payload };
    },
    clearSearchParams(state) {
      state.searchParams = initialState.searchParams;
    },
  },
});

export const { actions } = slice;
export default slice.reducer;

const adapterSelectors = productOrdersAdapter.getSelectors(
  (state: any) => state.productOrders
);

export const selectors = {
  searchParams: state => state.productOrders.searchParams,
};
