import camelCase from 'lodash/camelCase';
import CompanyReference from './CompanyReference';

export default class ApplicationReferenceRelatedIndustry {
  public readonly id: number;
  public readonly referenceId: number;
  public readonly granted: boolean;
  public readonly applicationIndustryId: number;
  public readonly new: number;
  public readonly reference: CompanyReference;
  public readonly createdAt: string;
  public readonly updatedAt: string;
  public readonly deletedAt: string;

  constructor(data) {
    Object.keys(data).forEach(k => {
      this[camelCase(k)] = data[k];
    });
    this.reference = new CompanyReference(this.reference);
  }
}
