import api from '../api';

export function createCompanyMembership(companyId, unionId, description) {
  return api.post(`/companies/${companyId}/memberships`, {
    company_membership_union_id: unionId,
    description,
  });
}

export function fetchCompanyMemberships(companyId) {
  return api.get(`/companies/${companyId}/memberships`);
}

export function fetchUnions() {
  return api.get('/descriptions/company_membership_union');
}
