/* eslint-disable import/prefer-default-export */
import api from '../api';

export function fetchCompanies(params = {}) {
  const { registrationNumber } = params;

  return api.get('/companies', {
    params: { registration_number: registrationNumber },
  });
}

export function fetchExternalCompany({ registrationNumber }) {
  return api.get(`/external/companies/${registrationNumber}`);
}

export function create({ registrationNumber, name }) {
  return api.post('/companies', {
    registration_number: registrationNumber,
    name,
  });
}

export function update(company) {
  return api.put(`/companies/${company.id}`, company);
}

export function joinRequest(companyId) {
  return api.post(`/companies/${companyId}/join-request`);
}
