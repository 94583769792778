/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  createSlice,
  PayloadAction,
  EntityState,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import { defineMessages } from 'react-intl';

import { COMMON } from '../common';

import { RootState } from '../rootReducer';
import { actions as toastActions, Toast, ToastType } from '../toaster';
import { APIError } from '../api';
import * as api from './api';

import Agreement from '~models/Agreement';
import AgreementListItem from '~models/AgreementListItem';

const messages = defineMessages({
  agreementTerminatedSuccess: {
    id: 'agreement-terminated-success',
    defaultMessage: 'Sopimus on irtisanottu',
  },
  agreementTerminatedFailure: {
    id: 'agreement-terminated-failure',
    defaultMessage: 'Odottamaton virhe sopimuksen irtisanomisessa',
  },
});

const companyAgreementsAdapter = createEntityAdapter<Agreement>({
  selectId: Agreement => Agreement.productAgreementId,
});

const initialState = companyAgreementsAdapter.getInitialState({
  companyAgreements: [],
  loading: {},
  error: {},
});

const slice = createSlice({
  name: 'companyAgreements',
  initialState,
  reducers: {
    fetchCompanyAgreements(
      state,
      action: PayloadAction<{
        companyId: string;
      }>
    ) {},

    receiveCompanyAgreements(state, action) {
      companyAgreementsAdapter.setAll(state, action.payload);
    },

    terminateAgreement(
      state,
      action: PayloadAction<{
        companyId: string;
        agreementId: string;
        terminateReason: string;
      }>
    ) {},

    receiveTerminateAgreement(state, action) {
      companyAgreementsAdapter.updateOne(state, {
        id: action.payload.product_agreement_id,
        changes: {
          terminatedAt: action.payload.terminated_at,
          terminatedReason: action.payload.terminated_reason,
        },
      });
    },

    loading(state, action: PayloadAction<string>) {
      const type = action.payload;
      state.loading[type] = true;
      state.error[type] = null;
    },

    success(state, action: PayloadAction<string>) {
      const type = action.payload;
      state.loading[type] = false;
      state.error[type] = null;
    },

    error(state, action: PayloadAction<{ type: string; error: APIError }>) {
      const { type, error } = action.payload;
      state.loading[type] = false;
      state.error[type] = error;
    },

    clearError(state, action: PayloadAction<string>) {
      const type = action.payload;
      state.error[type] = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(COMMON.NAVIGATE, state => {
      Object.keys(state.error).forEach(k => {
        state.error[k] = null;
      });
    })
  },
});

export const { actions } = slice;
export default slice.reducer;

const adapterSelectors = companyAgreementsAdapter.getSelectors(
  (state: RootState) => state.companyAgreements
);

export const selectors = {
  companyAgreements: state => adapterSelectors.selectAll(state),
  companyAgreement: adapterSelectors.selectById,
  companyAgreementsByProductId: (state, key?) => {
    if (
      key !== undefined &&
      state.companyAgreements.companyAgreements &&
      state.companyAgreements.companyAgreements.length
    ) {
      const match = state.companyAgreements.companyAgreements.find(
        entry => entry.product_agreement.product_id === key
      );
      return match;
    }
  },
  loading: (state: RootState, key?) =>
    key
      ? !!state.companyAgreements.loading[key]
      : Object.values(state.companyAgreements.loading).some(v => Boolean(v)),
  error: (state, key?) =>
    key
      ? state.companyAgreements.error[key] || null
      : state.companyAgreements.error,
};

export function* fetchCompanyAgreements(
  action: ReturnType<typeof actions.fetchCompanyAgreements>
) {
  const { type, payload } = action;
  const { companyId } = payload;
  try {
    yield put(actions.loading(type));
    const response = yield call(api.fetchCompanyAgreements, companyId);
    const agreements = response.data.map(a => new Agreement(a));
    yield put(actions.receiveCompanyAgreements(agreements));

    yield put(actions.success(type));
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* terminateAgreement(
  action: ReturnType<typeof actions.terminateAgreement>
) {
  const { type, payload } = action;
  const { companyId, agreementId, terminateReason } = payload;

  try {
    const response = yield call(api.terminateAgreement, {
      companyId,
      agreementId,
      terminateReason,
    });
    yield put(actions.receiveTerminateAgreement(response.data));
    yield put(
      toastActions.toast(
        new Toast({
          message: messages.agreementTerminatedSuccess,
          type: ToastType.Success,
          duration: 1500,
        })
      )
    );
    yield put(actions.success(type));
  } catch (error) {
    toastActions.toast(
      new Toast({
        message: messages.agreementTerminatedFailure,
        type: ToastType.Warning,
        duration: 1500,
      })
    );
    yield put(actions.error({ type, error }));
  }
}

export function* companyAgreementsSaga() {
  yield all([
    takeEvery(actions.fetchCompanyAgreements.type, fetchCompanyAgreements),
    takeEvery(actions.terminateAgreement.type, terminateAgreement),
  ]);
}
