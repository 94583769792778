import camelCase from 'lodash/camelCase';
import Person from './Person';

export default class CompanyPerson {
  public readonly id: number;
  public readonly companyId: number;
  public readonly personId: number;
  public readonly userId: number | null;
  public readonly person: Person;
  public readonly email: string;
  public readonly phone: string;
  public readonly companyAdmin: boolean;
  public readonly companyContactPerson: boolean;
  public readonly companyManagingDirector: boolean;
  public readonly companyProductionManagement: boolean;
  public readonly title: string | null;

  constructor(data) {
    Object.keys(data).forEach(k => {
      this[camelCase(k)] = data[k];
    });

    this.person = this.person && new Person(this.person);
  }

  get displayName() {
    if (this.email && this.person && this.person.name) {
      return `${this.person.name} (${this.email})`;
    }

    if ((!this.person || !this.person.name) && !this.email) return null;
    return this.email ? this.email : this.person.name;
  }

  get displayTitle() {
    if (this.title) {
      return this.title;
    }

    if (this.person) {
      return this.person.title;
    }

    return '-';
  }

  get role() {
    if (this.companyAdmin) {
      return {
        id: 'company-person-role-company_admin',
        defaultMessage: 'Pääkäyttäjä',
      };
    } else if (this.userId !== null) {
      return {
        id: 'company-person-role-user',
        defaultMessage: 'Käyttäjä',
      };
    } else {
      return {
        id: 'company-person-role-company_person',
        defaultMessage: 'Ammattilainen',
      };
    }
  }

  get isProfessional() {
    return this.userId === null;
  }

  get displayNameAndTitle() {
    const personTitle = this.title ?? this.person.title;
    const personTitleString = `${personTitle ? ', ' + personTitle : ''}`;
    const fullNameString = `${this.person.firstName ?? ''} ${
      this.person.lastName ?? ''
    }`;
    return `${fullNameString}${personTitleString}`;
  }
}

export interface ICompanyPerson {
  id: number;
  company_id: number;
  person_id: number;
  email: string;
  phone: string;
  title: string;
  company_admin: boolean;
  company_managing_director: boolean;
  company_contact_person: boolean;
  company_production_management: boolean;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
  identity_verified: boolean;
  identity_service_url: string;
  person: {
    id: number;
    first_name: string;
    last_name: string;
    title: string;
    birth_year: number;
    phone: string;
    contact_language: string;
    created_at: Date;
    updated_at: Date;
    deleted_at: Date;
    certificate_roles: any[];
  };
}
