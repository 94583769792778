import camelCase from 'lodash/camelCase';
import Description from '~models/Description';

export interface CertificateTypeDescription extends Description {
  code: boolean;
  createdAt: string;
  deletedAt: string;
  describableId: number;
  describableType: string;
  helpText: string | null;
  id: number;
  languageCode: string;
  longDescription: string;
  orderNumber: number;
  shortDescription: string;
  updatedAt: string;
}

export default class CertificateType {
  public readonly createdAt: string;
  public readonly deletedAt: string;
  public readonly descriptions: CertificateTypeDescription[];
  public readonly id: number;
  public readonly shortDescription: string;
  public readonly longDescription: string;
  public readonly isSelfIssuable: boolean;
  public readonly isPersonCertificate: boolean;
  public readonly issuer: string | null;
  public readonly issuerId: number;
  public readonly updatedAt: string;
  public readonly helpText: string | null;
  public readonly active?: number;

  constructor(data) {
    Object.keys(data).forEach(k => {
      this[camelCase(k)] = data[k];
    });
  }
}
