import Person from './Person';
import Board from './Board';
import { USER_PERMISSIONS } from '~features/common/enums';

export interface UserPermission {
  id: number;
  long_description: string;
  short_description: string;
}

export default class User {
  public readonly id: string;
  public readonly email: string;
  public readonly permissions: Array<UserPermission>;
  public person?: Person;
  public readonly boards: Board[];
  public readonly personId?: number;

  constructor(data) {
    Object.keys(data).forEach(k => {
      this[k] = data[k];
    });

    this.person = this.person ? new Person(this.person) : undefined;
    this.boards = this.boards?.map(board => new Board(board)) ?? [];
    this.personId = this.personId ?? undefined;
  }

  hasOfficePermission(): boolean {
    return this.hasOneOfPermissions([
      USER_PERMISSIONS.SYSADMIN,
      USER_PERMISSIONS.RALA_OFFICE,
    ]);
  }

  hasAnyPermission(): boolean {
    return this.hasOneOfPermissions([
      USER_PERMISSIONS.SYSADMIN,
      USER_PERMISSIONS.RALA_OFFICE,
      USER_PERMISSIONS.RALA_BOARD,
      USER_PERMISSIONS.RALA_AUDIT_CERTIFICATE_MASTER,
      USER_PERMISSIONS.RALA_AUDIT_CERTIFICATE,
      USER_PERMISSIONS.RALA_AUDIT,
    ]);
  }

  hasBoardPermission(): boolean {
    return this.hasOneOfPermissions([
      USER_PERMISSIONS.SYSADMIN,
      USER_PERMISSIONS.RALA_BOARD,
    ]);
  }

  hasSysAdminPermission(): boolean {
    return this.hasPermission(USER_PERMISSIONS.SYSADMIN);
  }

  hasOneOfPermissions(permissionIds: Array<number>): boolean {
    if (!this.permissions) {
      return false;
    }

    return (
      this.permissions.filter(permission =>
        permissionIds.includes(permission.id)
      ).length > 0
    );
  }

  hasPermission(permissionId: number): boolean {
    if (!this.permissions) {
      return false;
    }

    return (
      this.permissions.filter(permission => permission.id === permissionId)
        .length > 0
    );
  }

  // These permission groups are defined in a separate document (RALA intranet navigation structure)
  belongsToRala(): boolean {
    return this.hasOneOfPermissions([
      USER_PERMISSIONS.RALA_OFFICE,
      USER_PERMISSIONS.RALA_AUDIT,
    ]);
  }

  belongsToBoard(): boolean {
    return this.hasPermission(USER_PERMISSIONS.RALA_BOARD);
  }

  belongsToAudit(): boolean {
    return this.hasPermission(USER_PERMISSIONS.RALA_AUDIT_CERTIFICATE);
  }

  belongsToAuditCertificateMaster(): boolean {
    return this.hasPermission(USER_PERMISSIONS.RALA_AUDIT_CERTIFICATE_MASTER);
  }

  hasOnlySelectedPermission(permissionId: number): boolean {
    return this.permissions.length === 1 && this.hasPermission(permissionId);
  }
}
