/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as api from './api';
import { COMMON } from '../common';
import {
  actions as companyPeopleActions,
  selectors as companyPeopleSelectors,
} from '../companyPeople';
import Person from '~models/Person';
import Education from '~models/Education';
import CompanyPerson from '~models/CompanyPerson';
import { asSnakeCase } from '~common/helpers';

interface State {
  people: Record<number, Person>;
  loading: { [k: string]: boolean };
  error: { [k: string]: Error | null };
  educations: any;
  educationLevels: any;
}

const initialState: State = {
  people: {},
  loading: {},
  error: {},
  educations: [],
  educationLevels: [],
};

const slice = createSlice({
  name: 'people',
  initialState,
  reducers: {
    fetch() {},

    receive(state, action) {
      ([] as Person[]).concat(action.payload).forEach(p => {
        state.people[p.id] = new Person(p);
      });
    },

    loading(state, action) {
      const type = action.payload;
      state.loading[type] = true;
      state.error[type] = null;
    },

    success(state, action) {
      const type = action.payload;
      state.loading[type] = false;
      state.error[type] = null;
    },

    error(state, action) {
      const { type, error } = action.payload;
      state.loading[type] = false;
      state.error[type] = error;
    },
    fetchEducation() {},
    fetchEducationLevels() {},

    receiveEducationData(state, action: PayloadAction<any[]>) {
      state.educations = action.payload.map(e => new Education(e));
    },
    receiveEducationLevelData(state, action: PayloadAction<any>) {
      state.educationLevels = action.payload;
    },

    upsertPerson(state, action: PayloadAction<any[]>) {},

    getPersonEducation(state, action: PayloadAction<any>) {},
    setPersonEducation(state, action: PayloadAction<any>) {},
    savePersonEducation(state, action: PayloadAction<any>) {},
    deletePersonEducation(state, action: PayloadAction<any>) {},
  },
  extraReducers: builder => {
    builder.addCase(COMMON.NAVIGATE, state => {
      Object.keys(state.error).forEach(k => {
        state.error[k] = null;
      });
    })
  },
});

export const { actions } = slice;
export default slice.reducer;

export const selectors = {
  people: state => state.people.people,
  loading: (state, key?) =>
    key ? !!state.people.loading[key] : state.people.loading,
  error: (state, key?) => (key ? state.people.error[key] || null : state.error),
  educations: state => state.people.educations,
  educationLevels: state => state.people.educationLevels,
};

export function* fetchEducation() {
  const { type } = actions.fetchEducation;

  try {
    yield put(actions.loading(type));

    let educations = [];

    if (localStorage.getItem('authenticated')) {
      ({ data: educations } = yield call(api.fetchEducation));
    }
    yield put(actions.receiveEducationData(educations));
    yield put(actions.success(type));
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* fetchEducationLevels() {
  const { type } = actions.fetchEducationLevels;

  try {
    yield put(actions.loading(type));

    let educationLevels = null;

    if (localStorage.getItem('authenticated')) {
      ({ data: educationLevels } = yield call(api.fetchEducationLevels));
    }
    yield put(actions.receiveEducationLevelData(educationLevels));
    yield put(actions.success(type));
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* getPersonEducation(action) {
  const { type } = actions.getPersonEducation;

  try {
    yield put(actions.loading(type));

    const personId = action.payload;

    const { data } = yield call(api.getPersonEducation, personId);

    yield put(
      companyPeopleActions.receivePersonEducation({
        personId,
        data,
      })
    );
    yield put(actions.success(type));
    return data;
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* savePersonEducation(action) {
  const { type } = actions.savePersonEducation;

  try {
    yield put(actions.loading(type));
    yield call(api.savePersonEducation, action.payload);
    yield put(actions.success(type));
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* deletePersonEducation(action) {
  const { type } = actions.deletePersonEducation;

  try {
    yield put(actions.loading(type));
    yield call(api.deletePersonEducation, action.payload);
    yield put(actions.success(type));
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* setPersonEducation(action) {
  const { type } = actions.setPersonEducation;

  try {
    yield put(actions.loading(type));
    const { personId, companyId, educations } = action.payload;

    const companyPerson: CompanyPerson = yield select(state =>
      companyPeopleSelectors.companyPerson(state, personId)
    );

    const addEducations =
      educations &&
      educations.filter(
        education =>
          !companyPerson.person.personEducations.some(
            personEducation =>
              personEducation.educationDegreeId === education.id ||
              (personEducation.description !== null &&
                education.name === personEducation.description)
          )
      );

    const removeEducations =
      companyPerson.person.personEducations &&
      companyPerson.person.personEducations.filter(
        personEducation =>
          !educations.some(
            education =>
              education.id === personEducation.educationDegreeId ||
              (personEducation.description !== null &&
                education.name === personEducation.description)
          )
      );

    yield all(
      removeEducations.map(education =>
        call(
          deletePersonEducation,
          actions.deletePersonEducation({
            educationId: education.id,
            personId: companyPerson.personId,
          })
        )
      )
    );

    yield all(
      addEducations.map(education => {
        if (!education.isCustomEntry) {
          return call(
            savePersonEducation,
            actions.savePersonEducation({
              data: { education_degree_id: education.id },
              personId: companyPerson.personId,
            })
          );
        }
        if (education.isCustomEntry) {
          return call(
            savePersonEducation,
            actions.savePersonEducation({
              data: { description: education.name },
              personId: companyPerson.personId,
            })
          );
        }
        return null;
      })
    );

    yield put(actions.getPersonEducation(companyPerson.personId));

    yield put(actions.success(type));
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* upsertPerson(action) {
  const { type } = actions.upsertPerson;
  const companyPersonId = action.payload.id;

  try {
    yield put(actions.loading(type));

    // There is only update, no creation atm
    if (companyPersonId) {
      const companyPerson = yield select(state =>
        companyPeopleSelectors.companyPerson(state, companyPersonId)
      );

      const personInStore = { ...companyPerson.person };

      // These are required from API and have certain format, but
      // are not given (or cannot be given) in UI or forced (in correct format).
      // Might be missing and/or bad format, so updates will fail to these.
      const phone = personInStore.phone
        ? /^\+[0-9]{1}/gi.test(personInStore.phone)
          ? personInStore.phone.replace(/\s/gi, '')
          : `+358${personInStore.phone.replace(/\s|^0/gi, '')}`
        : '+358';

      const birthYear = personInStore.birthYear ?? 1901;
      const contactLanguage = personInStore.contactLanguage ?? 'FI';

      const person = asSnakeCase({
        ...personInStore,
        ...action.payload,
        phone,
        title,
        birthYear,
        contactLanguage,
      });

      const { data: newPerson = null } = yield call(
        api.updatePersonData,
        person
      );

      if (newPerson) {
        yield put(companyPeopleActions.receivePerson(newPerson));
      }
    }

    yield put(actions.success(type));
  } catch (error) {
    yield put(actions.error({ type, error }));
  }
}

export function* peopleSaga() {
  yield all([
    yield takeLatest(actions.getPersonEducation.type, getPersonEducation),
    yield takeLatest(actions.setPersonEducation.type, setPersonEducation),
    yield takeLatest(actions.fetchEducation.type, fetchEducation),
    yield takeLatest(actions.fetchEducationLevels.type, fetchEducationLevels),
    yield takeLatest(actions.savePersonEducation.type, savePersonEducation),
    yield takeLatest(actions.deletePersonEducation.type, deletePersonEducation),
    yield takeLatest(actions.upsertPerson.type, upsertPerson),
  ]);
}
