/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  createSlice,
  PayloadAction,
  createEntityAdapter,
} from '@reduxjs/toolkit';

type SearchOptionsState = Record<string, unknown>;
interface State {
  searchOptionsState: SearchOptionsState;
}

const searchOptionsAdapter =
  createEntityAdapter<{
    searchOptions: { [formName: string]: Record<string, unknown> };
  }>();

const initialState = searchOptionsAdapter.getInitialState({
  searchOptionsState: {},
} as State);

const slice = createSlice({
  name: 'searchOptions',
  initialState,
  reducers: {
    setSearchOptions(
      state,
      action: PayloadAction<{ name: string; value: any }>
    ) {
      const { name, value } = action.payload;
      if (name !== undefined && value !== undefined) {
        state.searchOptionsState[name] = value;
      }
    },
  },
});

export const { actions } = slice;
export default slice.reducer;

const adapterSelectors = searchOptionsAdapter.getSelectors(
  (state: any) => state.searchOptions
);

export const selectors = {
  searchOptions: state => state.searchOptions.searchOptionsState,
};
