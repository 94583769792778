import api from '../api';

export function fetchFileTypes() {
  return api.get(`/descriptions/file-types`);
}

export function fetchAccountingPeriodFiles(companyId, periodId) {
  return api.get(`/companies/${companyId}/financial-years/${periodId}/files`);
}

export function createAccountingPeriodFile(
  companyId,
  periodId,
  file,
  fileType
) {
  const data = new FormData();
  data.append('file', file);
  if (fileType.length > 1) {
    for (let index = 0; index < fileType.length; index++) {
      const element = fileType[index];
      data.append('file_type_id[]', element);
    }
  } else {
    data.append('file_type_id', fileType);
  }
  return api.post(
    `/companies/${companyId}/financial-years/${periodId}/files`,
    data
  );
}

export function deleteAccountingPeriodFile(companyId, periodId, fileId) {
  return api.delete(
    `/companies/${companyId}/financial-years/${periodId}/files/${fileId}`
  );
}
