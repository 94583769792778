import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit';
import ApplicationQuestion from '~/models/ApplicationQuestion';
import CertificateAuditProgram from '~/models/CertificateAuditProgram';
import CertificateAuditReportQuestionaires from '~/models/CertificateAuditReportQuestionaires';
import CertificateAuditReportPart from '~/models/CertificateAuditReportPart';
import { RootState } from '~store/index';
import CertificateAuditReportObservation from '~/models/CertificateAuditReportObservation';
import CertificateAuditDeviation from '~/models/CertificateAuditDeviation';
import { CertificateAuditReportPartDescription } from '~/models/Description';
import CertificateAuditCost from '~/models/CertificateAuditCost';
import { CERTIFICATE_AUDIT_REPORT_DEVIATION_STATUSES } from '~common/enums';

type ValidationErrors = Partial<{ [key in keyof FormData]: string[] }>;
type ModalState =
  | 'massUpdateModal'
  | 'deviationModal'
  | 'criteriaModal'
  | 'listing';
interface State {
  formData: {
    selectedAuditProgram: CertificateAuditProgram | null;
    programId: number | null;
  };
  selectedCriterion: CertificateAuditReportQuestionaires | null;
  selectedCriteriaPart: CertificateAuditReportPart | null;
  selectedQuestion: ApplicationQuestion | null;
  questionairesData: Partial<CertificateAuditReportObservation>;
  questionaireCurrentHasDeviation: boolean | null;
  selectedReportPartId: number | null;
  selectedDeviation: CertificateAuditDeviation | null;
  deviationData: Partial<CertificateAuditDeviation>;
  deviationToDelete: CertificateAuditDeviation | null;
  showDeviationModal: boolean;
  validationErrors: ValidationErrors | null;
  auditorCosts: Partial<CertificateAuditCost>[];
  statementData: {
    statementId: number | null;
    statementAdditionalInfo: string;
  };
  isAutosaveOn: string;
  selectedAuditReportPart: CertificateAuditReportPart | null;
  modalState: ModalState;
}

const certificateAuditProgramsAdapter = createEntityAdapter<any>();

const initialState = certificateAuditProgramsAdapter.getInitialState({
  formData: {
    selectedAuditProgram: null,
    programId: null,
  },
  selectedCriteriaPart: null,
  selectedCriterion: null,
  selectedQuestion: null,
  selectedReportPartId: null,
  questionairesData: {
    strengths: '',
    developmentAreas: '',
    score: 0,
    certificateAuditReportObservedPartStatusId: 1,
    hasDeviation: false,
  },
  questionaireCurrentHasDeviation: null,
  selectedDeviation: null,
  deviationData: {
    id: 0,
    applicationQuestionId: 0,
    certificateDeviationSeverityId: 0,
    certificateDeviationStatusId:
      CERTIFICATE_AUDIT_REPORT_DEVIATION_STATUSES.IN_PROGRESS,
    description: '',
    files: [],
    fixApprovedAt: '',
    fixSentAt: '',
    createdAt: '',
    updatedAt: '',
    deletedAt: '',
    takenAction: '',
    toBeFixedByDate: '',
    certificateAuditReportPart: new CertificateAuditReportPartDescription({}),
    applicationQuestion: new ApplicationQuestion({}),
    filesToDelete: [],
  },
  showDeviationModal: false,
  deviationToDelete: null,
  validationErrors: null,
  auditorCosts: [],
  statementData: {
    statementId: null,
    statementAdditionalInfo: '',
  },
  isAutosaveOn: '1',
  selectedAuditReportPart: null,
  modalState: 'listing',
} as State);

const slice = createSlice({
  name: 'certificateAuditPrograms',
  initialState,
  reducers: {
    setFormField(state, action: PayloadAction<any>) {
      state.formData = { ...state.formData, ...action.payload };
    },
    setQuestionairesField(state, action: PayloadAction<any>) {
      state.questionairesData = {
        ...state.questionairesData,
        ...action.payload,
      };
    },
    setQuestionaireCurrentHasDeviation(state, action: PayloadAction<any>) {
      state.questionaireCurrentHasDeviation = action.payload;
    },
    setCriterion(state, action: PayloadAction<any>) {
      state.selectedCriterion = action.payload;
    },
    setCriteriaPart(state, action: PayloadAction<any>) {
      state.selectedCriteriaPart = action.payload;
    },
    clearCriterionData(state) {
      state.questionairesData = initialState.questionairesData;
      state.selectedQuestion = null;
      state.selectedReportPartId = null;
    },
    setSelectedQuestion(state, action: PayloadAction<ApplicationQuestion>) {
      state.selectedQuestion = action.payload;
    },
    setCriterionToEdit(
      state,
      action: PayloadAction<{
        question: ApplicationQuestion;
        reportPart: CertificateAuditReportPart;
      }>
    ) {
      const { question, reportPart } = action.payload;

      state.selectedQuestion = new ApplicationQuestion(question);
      state.selectedReportPartId = reportPart.certificateAuditReportPartId;
      state.selectedAuditReportPart = reportPart;

      if (question.observation) {
        state.questionairesData = {
          ...state.questionairesData,
          ...new CertificateAuditReportObservation(question.observation),
        };
        state.questionaireCurrentHasDeviation =
          question.observation.hasDeviation;
      }
    },
    setCriterionToMassEdit(
      state,
      action: PayloadAction<{
        question: ApplicationQuestion;
        reportPartId: number;
      }>
    ) {
      const { question, reportPartId } = action.payload;

      state.selectedQuestion = new ApplicationQuestion(question);
      state.selectedReportPartId = reportPartId;

      if (question.observation) {
        state.questionairesData = {
          ...state.questionairesData,
          ...new CertificateAuditReportObservation(question.observation),
        };
        state.questionaireCurrentHasDeviation =
          question.observation.hasDeviation;
      }
    },
    closeCriteriaReportModal(state) {
      state.deviationData = initialState.deviationData;
      state.questionairesData = initialState.questionairesData;
      state.selectedQuestion = null;
      state.selectedReportPartId = null;

      state.questionaireCurrentHasDeviation = null;

      state.selectedAuditReportPart = null;
      state.modalState = initialState.modalState;
    },
    setCriteriaReportModalToInitialStateWithoutClosingModal(state) {
      state.deviationData = initialState.deviationData;
      state.questionairesData = initialState.questionairesData;

      state.questionaireCurrentHasDeviation = null;
    },
    setSelectedReportPartId(state, action: PayloadAction<any>) {
      state.selectedReportPartId = action.payload;
    },
    setSelectedDeviation(state, action: PayloadAction<any>) {
      state.selectedDeviation = action.payload;
    },
    setDeviationField(state, action: PayloadAction<any>) {
      state.deviationData = {
        ...state.deviationData,
        ...action.payload,
      };
    },
    clearDeviationData(state) {
      state.selectedDeviation = null;
      state.deviationData = initialState.deviationData;
      state.showDeviationModal = false;
    },
    setDeviationToDelete(
      state,
      action: PayloadAction<CertificateAuditDeviation | null>
    ) {
      state.deviationToDelete = action.payload;
    },
    setShowDeviationModal(state, action: PayloadAction<any>) {
      state.showDeviationModal = action.payload;
    },
    addAuditCosts(state, action: PayloadAction<any>) {
      state.auditorCosts.push(action.payload);
    },
    setAuditCostField(
      state,
      action: PayloadAction<{
        id: number;
        data: any;
      }>
    ) {
      const selectedCost = state.auditorCosts.find(
        c => c.personId === action.payload.id
      );
      const editedCost = new CertificateAuditCost({
        ...selectedCost,
        ...action.payload.data,
      });
      state.auditorCosts = state.auditorCosts.map(cost =>
        cost.personId === selectedCost?.personId ? editedCost : cost
      );
    },
    clearAuditCostsData(state) {
      state.auditorCosts = [];
    },
    setValidationErrors(state, action: PayloadAction<ValidationErrors | null>) {
      state.validationErrors = action.payload;
    },
    setStatementDataField(state, action: PayloadAction<any>) {
      state.statementData = { ...state.statementData, ...action.payload };
    },
    setIsAutosaveOn(state, action: PayloadAction<any>) {
      state.isAutosaveOn = action.payload;
    },
    setSelectedAuditReportPart(
      state,
      action: PayloadAction<CertificateAuditReportPart | null>
    ) {
      state.selectedAuditReportPart = action.payload;
    },
    setModalState(state, action: PayloadAction<ModalState>) {
      state.modalState = action.payload;
    },
  },
});

export const { actions } = slice;
export default slice.reducer;

export const selectors = {
  formData: (state: RootState) => state.certificateAuditPrograms.formData,
  questionairesData: (state: RootState) =>
    state.certificateAuditPrograms.questionairesData,
  questionaireCurrentHasDeviation: (state: RootState) =>
    state.certificateAuditPrograms.questionaireCurrentHasDeviation,
  selectedCriterion: (state: RootState) =>
    state.certificateAuditPrograms.selectedCriterion,
  selectedCriteriaPart: (state: RootState) =>
    state.certificateAuditPrograms.selectedCriteriaPart,
  selectedQuestion: (state: RootState) =>
    state.certificateAuditPrograms.selectedQuestion,
  selectedReportPartId: (state: RootState) =>
    state.certificateAuditPrograms.selectedReportPartId,
  selectedDeviation: (state: RootState) =>
    state.certificateAuditPrograms.selectedDeviation,
  deviationData: (state: RootState) =>
    state.certificateAuditPrograms.deviationData,
  deviationToDelete: (state: RootState) =>
    state.certificateAuditPrograms.deviationToDelete,
  showDeviationModal: (state: RootState) =>
    state.certificateAuditPrograms.showDeviationModal,
  auditorCosts: (state: RootState) =>
    state.certificateAuditPrograms.auditorCosts,
  statementData: (state: RootState) =>
    state.certificateAuditPrograms.statementData,
  validationErrors: state => state.certificateAuditPrograms.validationErrors,
  isAutosaveOn: (state: RootState) =>
    state.certificateAuditPrograms.isAutosaveOn,
  selectedAuditReportPart: (state: RootState) =>
    state.certificateAuditPrograms.selectedAuditReportPart,
  modalState: (state: RootState) => state.certificateAuditPrograms.modalState,
};
