import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { remove } from 'lodash';

import { RootState } from '~store/index';

type Url = { id: number; url: string };
type FileWithUrl = { file: File; url: string } | Url;

interface State {
  // Images are stored as a record to support multiple ImageCarousels
  // in the same page
  images: Record<string, FileWithUrl[]>;
  active: Record<string, number>;
}

const imageCarouselAdapter = createEntityAdapter<void>();

const initialState = imageCarouselAdapter.getInitialState({
  images: {},
  active: {},
} as State);

const slice = createSlice({
  name: 'imageCarousel',
  initialState,
  reducers: {
    // This sets the initial images, in addition to setting default
    // image active if it is passed
    setImages(
      state,
      action: PayloadAction<{
        id: string;
        images: (File | Url)[];
        defaultImage?: File | Url;
      }>
    ) {
      const { id, images, defaultImage } = action.payload;
      // Convert image files to object urls, these need to be revoked
      // on unmount to prevent memory leaks
      const urls = images.map(img =>
        'url' in img
          ? img
          : {
              file: img,
              url: URL.createObjectURL(img),
            }
      );

      state.images[id] = urls;
    },

    setActive(state, action: PayloadAction<{ id: string; i: number }>) {
      const { id, i } = action.payload;
      state.active[id] = i;
    },

    imageAdded(state, action: PayloadAction<{ id: string }>) {
      const { id } = action.payload;
      state.active[id] =
        state.active[id] === undefined ? 0 : state.active[id]! + 1;
    },

    removeImage(state, action: PayloadAction<{ id: string; image: number }>) {
      const { id, image } = action.payload;
      const [url] = remove(state.images[id] ?? [], (img, i) => i === image);

      // Release object url to prevent memory leak
      if (url) {
        URL.revokeObjectURL(url.url);
      }
      state.active[id] = 0;
    },

    // THIS NEEDS TO BE CALLED ON UNMOUNT TO PREVENT MEMORY LEAKS
    clearImages(state, action: PayloadAction<{ id: string }>) {
      const id = action.payload.id;

      for (const { url } of state.images[id] ?? []) {
        URL.revokeObjectURL(url);
      }

      // Reset active image
      delete state.active[id];
    },

    onNextClick(state, action: PayloadAction<string>) {
      const id = action.payload;

      // Update active
      if (state.active[id] === state.images[id]!.length - 1) {
        state.active[id] = 0;
      } else {
        state.active[id]++;
      }
    },

    onPrevClick(state, action: PayloadAction<string>) {
      const id = action.payload;

      // Update active
      if (state.active[id] === 0) {
        state.active[id] = state.images[id]!.length - 1;
      } else {
        state.active[id]--;
      }
    },
  },
});

export const { actions } = slice;
export default slice.reducer;

export const selectors = {
  images: (state: RootState, id: string) => state.imageCarousel.images[id],
  active: (state: RootState, id: string) => state.imageCarousel.active[id] ?? 0,
};
